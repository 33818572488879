import React, {useEffect, useMemo, useState} from "react";
import CustomTabs from "@components/CustomTabs";
import {offersLanguages} from "@utils/languageList";
import LogsTable from "@pages/AdminStudentList/CreateModal/LogsTable";
import {useVariables} from "@hooks/useVariables";
import {usePagination} from "@hooks/usePagination";
import {ADMIN_STUDENTS} from "@api/endpoints";
import {toast} from "react-toastify";
import EditList from "@pages/AdminDatabasePage/components/EditList";
import ModalCustom from "@components/ModalCustom";
import LoadModal from "@pages/AdminDatabasePage/components/LoadModal";

const Achievements= ({setOpenedList}) => {
    const [activeTab,setActiveTab] = React.useState(0);
    const uploadThead = {
        CREATE_DPO:["Логин","Название","Год","Часы","Тип"],
        UPDATE_ACTIVITY:["Логин","Категория","Оценка"],
        UPDATE_RSV:["Логин","Категория","Оценка"],
        UPDATE_COMPETENCE:["Логин","Уровень 1","Уровень 2","Уровень 3", "Уровень 4"],
    }
    const uploadFields = {
        CREATE_DPO:["login","dpoName","year","hours","dpoType"],
        UPDATE_ACTIVITY:["login","category","mark"],
        UPDATE_RSV:["login","category","rsv"],
        UPDATE_COMPETENCE:["login","level1","level2","level3","level4"]
}
    const tabs = [
        {title:"Компетенции",id:0,filter:"UPDATE_COMPETENCE",URL:ADMIN_STUDENTS.UPLOAD_ACHIEVEMENTS.COMPETENCE},
        {title:"Область деятельности",id:1,filter:"UPDATE_ACTIVITY",URL:ADMIN_STUDENTS.UPLOAD_ACHIEVEMENTS.ACTIVITY},
        {title:"ДПО",id:2,filter:"CREATE_DPO",URL:ADMIN_STUDENTS.UPLOAD_ACHIEVEMENTS.DPO},
        {title:"РСВ",id:3,filter:"UPDATE_RSV",URL:ADMIN_STUDENTS.UPLOAD_ACHIEVEMENTS.RSV},
    ];
    const [activeModal,setActiveModal] = useState(false)
    const requestGet = useVariables().requestOptionsGET
    const [filters,setFilters] = useState({size:100,jobType:"UPDATE_COMPETENCE"})
    const requestPost = useVariables().constRequestOptionsPOST;
    const openCreate = () => {
        setActiveModal(true)
    }
    const [editList,setEditList] = useState(false)
    const [activeUsers,setActiveUsers] = useState(null)
    const statuses = usePagination(ADMIN_STUDENTS.GET_FILES_LOGS,requestGet,"","",filters,true)
    const loadToDB = (data,fileName=null,jobId=null) => {
        const config = requestPost(data)
        fetch(tabs[activeTab].URL(jobId,fileName), config).then(res => res.json()).then((res) => {
            if (res.status === "Success") {
                toast.success("Список обрабатывается", {position: toast.POSITION.TOP_CENTER});
                statuses.refresh();
            } else
                toast.error("Ошибка при загрузке списка", {position: toast.POSITION.TOP_CENTER})

        })
    };
    useMemo(()=>setOpenedList(editList),[editList])
    const doEdit = (users,responseFile) => {
        /*if(responseFile.entry !== users.length){
            toast.error("Ошибка. Кажется это не тот файл. Списки отличаются", {position: toast.POSITION.TOP_CENTER})
            return;
        }*/
        setActiveUsers({users,file:responseFile})
        setEditList(true);
        setActiveModal(false)
    }
    const back = () => {
        setEditList(false);
        setActiveModal(false);
        setActiveUsers({})
    }
    const rewrite = (fileUsers,responseFile) =>{
        doEdit(fileUsers,responseFile)
    }




    useEffect(()=>{
        if(tabs[activeTab].filter !== filters.jobType){
            setFilters({...filters,jobType:tabs[activeTab].filter})
        }
    },[activeTab])


    return (
        <div className={"active"}>
            {!editList &&
            <div style={{marginBottom:20,marginTop:20}} className="create-blk">
                <ul className="tag tags unstyled">
                    {tabs.map((item,index)=>(
                        <li key={index} className={activeTab===index?"active tabs":"tabs"}
                            onClick={()=>setActiveTab(index)}>{item.title}</li>
                    ))}
                </ul>
            </div>
            }

            <div style={{marginTop:20}} className="scroll">
                {!editList &&
                    <LogsTable  rewriteFile={rewrite} openCreate={openCreate} statuses={statuses.data}/>
                }
                {editList &&
                    <EditList uploadThead={uploadThead[tabs[activeTab].filter]} fields={uploadFields[tabs[activeTab].filter]} sendList={loadToDB} back={back} data={activeUsers}/>}
            </div>
            <ModalCustom
                title={`Добавление ${tabs[activeTab].title}`}
                active={activeModal}
                contentId={"logTable"}
                className={"autoWidth crateStudentModal newStyle"}
                setActive={setActiveModal}
            >
                <LoadModal uploadFields={uploadFields[tabs[activeTab].filter]} subTitle={tabs[activeTab].title} editList={false} loadToDB={loadToDB} setActiveModal={setActiveModal} />
            </ModalCustom>

        </div>
    )

}
export default Achievements;