import "../assets/styles/components/bubble_button.scss";

const BubbleButton = ({
  children,
  id,
  name,
  value,
  multiple = false,
  onClick = null,
  onChange = null,
  checked = false,
}) => {
  // const [checkedValue, setCheckedValue] = useState(checked);

  // const onClickHandler = (event) => {
  //     typeof onClick === 'function' && onClick(event)
  // }

  // function onChangeChecked(event) {
  //     typeof onChange === "function" && onChange(event)
  // }

  return (
    <div className="bubble_button">
      <input
        className="bubble_input"
        name={name}
        id={id}
        value={value}
        type={multiple ? "checkbox" : "radio"}
        // onClick={onClickHandler}
        // checked={checked}
        // onChange={onChangeChecked}
      />
      <label className="bubble_label" htmlFor={id}>
        {children}
      </label>
    </div>
  );
};

export default BubbleButton;
