import { useEffect, useState } from "react";
import { useSelector } from "react-redux";

import {
  GET_FEEDBACK_FOR_ME,
  GET_FEEDBACK_FROM_ALL_COMPANIES,
} from "../api/endpoints";

export const useShowFeedback = (student) => {
  const user = useSelector((state) => state.user.user);
  const requestOptions = {
    method: "GET",
    headers: {
      Authorization: `${user.type} ${user.accessToken}`,
    },
  };
  const [feedback, setFeedback] = useState([]);

  const getFeedback = (studentId) => {
    const url = user.roles.includes("COMPANY")
      ? GET_FEEDBACK_FROM_ALL_COMPANIES(studentId)
      : GET_FEEDBACK_FOR_ME;
    fetch(url, requestOptions)
      .then((response) => response.json())
      .then((json) => setFeedback(json))
      .catch(() => console.log("Произошла ошибка"));
  };

  useEffect(() => {
    getFeedback(student.id);
  }, []);

  return {
    feedback,
  };
};
