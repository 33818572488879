import {createStore, combineReducers, applyMiddleware} from "redux";
import {composeWithDevTools} from "@redux-devtools/extension";
import thunk from "redux-thunk";

import {instituteReducer} from "./reducers/instituteReducer";
import {eduProgramReducer} from "./reducers/eduProgramReducer";
import {eduDirectionReducer} from "./reducers/eduDirectionReducer";
import {qualificationReducer} from "./reducers/qualificationReducer";
import {professionReducer} from "./reducers/professionReducer";
import {userReducer} from "./reducers/userReducer";
import {messengerReducer} from "./reducers/messengerReducer";
import {companyReducer} from "@store/reducers/companyReducer";
import {offersReducer} from "@store/reducers/offersReducer";

const rootReducer = combineReducers({
    institutes: instituteReducer,
    eduPrograms: eduProgramReducer,
    eduDirections: eduDirectionReducer,
    qualifications: qualificationReducer,
    professions: professionReducer,
    user: userReducer,
    offers: offersReducer,
    company: companyReducer,
    messenger: messengerReducer,
})

export const store = createStore(rootReducer, composeWithDevTools(applyMiddleware(thunk)))
