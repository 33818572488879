import React from'react';

const Table = ({children,className}) => {
    return (
        <table className={`table table-responses table-student`}>
            <thead>
            <tr>
                <th>Студент</th>
                <th>Поликоины</th>
                <th></th>
            </tr>
            </thead>
            <tbody>
            {children}
            </tbody>
        </table>
    )
}
export default Table;