import "../assets/styles/components/show_feedback.scss";
import React, { useEffect, useState } from "react";

import { useShowFeedback } from "../hooks/useShowFeedback";

import ModalCustom from "./ModalCustom";
import {useSelector} from "react-redux";

const ShowFeedbackForm = ({ student,openFeedbackModal }) => {
  const user = useSelector((state) => state.user.user);
  const feedback = useShowFeedback(student).feedback;
  const isCompany = user.id !== student.id;
  const [fullFeedbackModal, setFullFeedbackModal] = useState(false);
  const [fullFeedbackContent, setFullFeedbackContent] = useState({});

  const openFullFeedback = (feedback) => {
    setFullFeedbackModal(true);
    setFullFeedbackContent(feedback);
  };

  const getMarkClass = (status) => {
    switch (status) {
      case "LIKE":
        return {
          class: "i-smile_joy",
          text: "Соответствует",
        };
      case "MIDDLE":
        return {
          class: "i-smile_ok",
          text: "Недостаточно соответствует",
        };
      case "DISLIKE":
        return {
          class: "i-smile_sad",
          text: "Не соответствует",
        };
      default:
        return "";
    }
  };

  useEffect(() => {
    console.log(fullFeedbackContent);
  }, [fullFeedbackContent]);

  console.log(feedback);
  return (
    <div className="show_feedback">
      {feedback.length > 0 ? (
        feedback.map((el) => (
          <div  onClick={() => openFullFeedback(el)} className="shadow_block" key={el.company.companyId}>
            <h2>{el.company.name}</h2>
            <div className="short_feedback">
              <div className="like mark">
                <span className="i-smile_joy icon_mark"></span>
                {el.feedback.gradesCount.LIKE}
              </div>
              <div className="middle mark">
                <span className="i-smile_ok icon_mark"></span>
                {el.feedback.gradesCount.MIDDLE}
              </div>
              <div className="dislike mark">
                <span className="i-smile_sad icon_mark"></span>
                {el.feedback.gradesCount.DISLIKE}
              </div>
            </div>
            <span
              className="show_full_feedback"

            >
              Подробнее
              <span className="i-up_right"></span>
            </span>

          </div>
        ))
      ) : (
        <div className="empty">
          Обратная связь на компетенции еще не получена
        </div>
      )}
      {isCompany && (
          <button
              className="submit_button feedback_button"

              onClick={()=>openFullFeedback(null)}
          >
            Оставить обратную связь
          </button>
      )}
      {fullFeedbackContent && fullFeedbackContent.company && (
        <ModalCustom
          title={fullFeedbackContent.company.name}
          active={fullFeedbackModal}
          setActive={setFullFeedbackModal}
        >
          <div className="feedback_full">
            <table className="list">
              <tbody>
                <tr>
                  <td></td>
                  <td>Обратная связь</td>
                </tr>
                {fullFeedbackContent.feedback.competenceGrade &&
                  fullFeedbackContent.feedback.competenceGrade.map((el) => (
                    <tr key={el.competenceId} className="line">
                      <td>{el.competenceName}</td>
                      <td>
                        <span
                          className={`${
                            getMarkClass(el.feedbackStatus).class
                          } icon_mark`}
                        >
                          {getMarkClass(el.feedbackStatus).text}
                        </span>
                      </td>
                    </tr>
                  ))}
              </tbody>
            </table>

          </div>

        </ModalCustom>
      )}
    </div>
  );
};
export default ShowFeedbackForm;
