import React from "react";


const StudentTable = ({children,openModal}) => {
    return (
        <table className="tables table-status">
            <thead>
            <tr>
                <th className="add"><a onClick={openModal} className="btn btn-blue modal-link" ></a>
                </th>
                <th>ФИО</th>
                <th>Статус</th>
                <th>Уровень получаемого образования</th>
                <th>Институт</th>
                <th>Направление подготовки</th>
                <th>Почта</th>
                <th>Номер группы</th>
            </tr>
            </thead>
            <tbody>
                {children}
            </tbody>
        </table>
    )

}

export default StudentTable;