import React, {useRef, useState} from 'react';
import {Pagination} from "swiper/modules";
import {Swiper, SwiperSlide} from "swiper/react";
import ava1 from "../../assets/images/review/1.png"
import ava2 from "../../assets/images/review/2.png"
import ava3 from "../../assets/images/review/3.png"
const StudentsReviews = (props) => {
    const [activeSlide,setActiveSlide] = useState(0);
    const bulletsRef = useRef(null);
    const [swiper,setSwiper] = useState();
    return (
        <div className={"swiper reviewsSwiper"} >
            <Swiper
                pagination={{el:bulletsRef.current,clickable:true}}
                slidesPerView={3}
                breakpoints={
                    {
                        1200: {
                            slidesPerView: 3,
                        },
                        768: {
                            slidesPerView: 2,
                        },
                        0: {
                            slidesPerView: 1,
                        }
                    }
                }
                spaceBetween={20}
                modules={[Pagination]}
                onSlideChange={(e) => setActiveSlide(e.activeIndex)}
                onSwiper={setSwiper}
            >
                <SwiperSlide>
                    <div className="review-blk">
                        <div className="review-header">
                            <img src={ava1} alt=""/>
                            <div className="review-title">
                                <span>Банникова Анастасия Васильевна</span>
                                <p>4 курс, 5130203/10101, ИКНК</p>
                            </div>
                        </div>
                        <div className="review-content">
                            <p>Очень нравится идея проекта. Сайт красивый и удобный. Здорово, что можно конвертировать достижения в поликоины и получать подарки.</p>
                        </div>
                    </div>
                </SwiperSlide>
                <SwiperSlide>
                    <div className="review-blk">
                        <div className="review-header">
                            <img src={ava2} alt=""/>
                            <div className="review-title">
                                <span>Рузаков Максим Андреевич</span>
                                <p>2 курс, 5151001/30002, ИКНК
                                </p>
                            </div>
                        </div>
                        <div className="review-content">
                            <p>Платформа удобна в использовании, хорошо, что была модернизирована система поиска работодателей, для меня, как для студента, это очень удобно. Можно всем написать и потом ждать ответ</p>
                        </div>
                    </div>
                </SwiperSlide>
                <SwiperSlide>
                    <div className="review-blk">
                        <div className="review-header">
                            <img src={ava3} alt=""/>
                            <div className="review-title">
                                <span>Егорова Майя Михайловна</span>
                                <p>4 курс, 3733801/10501, ИПМЭиТ</p>
                            </div>
                        </div>
                        <div className="review-content">
                            <p>Поликапитал стал важным элементом взаимодействия работодателей и студентов, инстументом оценки личных достижений и источником полезной информации, доступной в сообществе ВКонтакте.</p>
                        </div>
                    </div>
                </SwiperSlide>

            </Swiper>
            <div className="swiper-btn flex space-between">
                <div onClick={()=>swiper?.slidePrev()} style={{cursor:"pointer",zIndex:2}} className="swiper-button-prev">Назад</div>
                <div style={{bottom:0,zIndex:2,width:'auto'}} className="swiper-pagination" ref={bulletsRef}></div>
                <div onClick={()=>swiper?.slideNext()} style={{cursor:"pointer",zIndex:2}} className="swiper-button-next swiper-button-disabled">Далее</div>
            </div>
        </div>

    )

}
export default StudentsReviews;