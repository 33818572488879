import React from "react";

import FilterSelect from "./FilterSelect";

import { usePagination } from "@hooks/usePagination";
import { useVariables } from "@hooks/useVariables";

import { LOAD_INSTITUTE } from "@api/endpoints";

const SCROLL_ELEMENT_ID = "institutes_list";

const InstitutesFilterSelect = ({ filters, onFilterSelect }) => {
  const requestOptionsGET = useVariables().requestOptionsGET;
  const { data: institutes, loading } = usePagination(
    LOAD_INSTITUTE,
    requestOptionsGET,
    "name",
    SCROLL_ELEMENT_ID,
  );

  return (
    <FilterSelect
      values={institutes}
      loading={loading}
      placeholder="Институт"
      filterName="instituteIds"
      filters={filters}
      scrollId={SCROLL_ELEMENT_ID}
      onFilterSelect={onFilterSelect}
    />
  );
};

export default React.memo(InstitutesFilterSelect);
