import React, {useState} from 'react';


const ButtonWithLoading = ({prop,style={},preventEvent=false,spinnerLoadingStyle,loadingStyle={width:100},className,type,onClick,paramsInOnClick,title}) => {
    const [loading,setLoading] = useState(false)
    const click = async (e) =>{
        if(preventEvent)
            e.preventDefault();
        setLoading(true)
        await onClick(paramsInOnClick);
        setLoading(false)
    }
    const callback = () => {
        setLoading(false)
    }
    return (
        <button onClick={loading ? null : click} disabled={loading} className={`${className} ${loading? 'disabled flex-all-center' : ''}`} style={loading ? loadingStyle : style}>
            {loading ?
                <span style={loading ? spinnerLoadingStyle : {}} className="loader2"></span>
                :title}
        </button>
        )

}

export default ButtonWithLoading;