import React from'react';
import {SwiperSlide} from "swiper/react";
import module3_1 from "@pages/StartPage/assets/images/modul3-1.png";
import module3_2 from "@pages/StartPage/assets/images/modul3-2.png";

    const tabs = [
            <SwiperSlide>
                <div className="modul-blk">
                    <div className="modul-info">
                        <div className="modul-title">
                            <span className="titles">Создавайте карьерные предложения просто</span>
                            <p>Вакансии, стажировки и практики — единое окно со статистикой для всех предложений</p>
                        </div>
                    </div>
                    <div className="modul-image">
                        <img src={module3_1} alt=""/>
                    </div>
                </div>

            </SwiperSlide>,
            <SwiperSlide>
                <div className="modul-blk">
                    <div className="modul-info">
                        <div className="modul-title">
                            <span className="titles">Единое окно лучших студентов страны</span>
                            <p>Поликапитал объединяет в себе студентов разных вузов. Так Вы сможете привлекать более
                                квалифицированные кадры под ваши цели от ведущих университетов страны.</p>
                        </div>
                    </div>
                    <div className="modul-image">
                        <img src={module3_2} alt=""/>
                    </div>
                </div>

            </SwiperSlide>
    ]

export default tabs;