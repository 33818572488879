import React from "react";
import styled from "styled-components";

import { useDownloadFile } from "@hooks/useDownloadFile";

import { DOWNLOAD_FILE } from "@api/endpoints";

const DEFAULT_SIZE = 48;
const MARGIN_SIZE = 5;

const Avatar = ({ first, koef, avatarId }) => {
  const img = useDownloadFile(DOWNLOAD_FILE, avatarId).file;

  const left = MARGIN_SIZE / koef;
  const size = DEFAULT_SIZE / koef - left - (koef > 1 ? left / koef : 0);

  return (
    <Wrapper
      $first={first}
      $left={left}
      $size={size}
      $koef={koef}
      className="avatar"
    >
      <img src={img} alt="avatar" />
    </Wrapper>
  );
};

export default React.memo(Avatar);

const Wrapper = styled.div`
  max-width: ${({ $size }) => $size}px !important;
  max-height: ${({ $size }) => $size}px !important;
  min-width: ${({ $size }) => $size}px !important;
  min-height: ${({ $size }) => $size}px !important;
  border-radius: ${({ $koef }) => 8 / $koef}px !important;

  margin-left: ${({ $first, $left }) => ($first ? 0 : $left)}px;
`;
