import { useCallback, useEffect, useMemo, useRef, useState } from "react";
import { useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";

import { useVariables } from "./useVariables";
import useSocket from "./useSocket";

import {
  CREATE_CHAT_WITH_ADMIN,
  GET_CHAT_MESSAGES,
  GET_OR_CREATE_CHAT,
  GET_PAGED_CHATS,
} from "@api/endpoints";
import {toast} from "react-toastify";

const PAGE_SIZE = 20;
const TYPING_SEND_DELAY_MS = 1000;

const SOCKET_EVENTS = {
  typing: "typing",
  message: "message",
  read: "read",
};

const useMessenger = (props) => {
  const user = useSelector((state) => state.user.user);
  const { initialChatId, onTypingReceive } = props || {};
  const [adminChatId,setAdminChatId] = useState(null);
  const [chats, setChats] = useState([]);
  const [selectedChatId, setSelectedChatId] = useState();

  const selectedChat = useMemo(() => {
    const foundChat = chats?.find(
      (chat) => selectedChatId * 1 === chat.chatId * 1,
    );
    if (foundChat) {
      const getAuthors = () => {
        const authors = foundChat.members.filter(
          (member) => member.userId !== user.id,
        );

        return authors.map((author) => author.name).join(", ");
      };

      return {
        ...foundChat,
        authors: getAuthors(foundChat.chatId),
      };
    }else if(selectedChatId * 1 === adminChatId * 1){
      const chat = {
        chatId: adminChatId,
        messages:[],
        authors: [],
        "members": [
          {
            userId:user.id,
            ...user
          },
          {
            "userId": adminChatId,
            "avatarId": null,
            "name": "Админ"
          }
        ],
      }
      setChats((prevChats) => [...prevChats,chat]);
      return chat

    }

    return null;
  }, [chats, selectedChatId,adminChatId]);

  const onTypingReceived = useCallback(
    (payload) => {
      if (payload.chatId === selectedChat?.chatId) {
        onTypingReceive?.(payload);
      }
    },
    [selectedChat, onTypingReceive],
  );

  const onMessageReceived = useCallback((message) => {
    setChats((prevChats) => {
      const foundChatIndexIndex = prevChats.findIndex(
        (chat) => chat.chatId * 1 === message.chatId * 1,
      );

      if (foundChatIndexIndex !== -1) {
        const foundChat = prevChats[foundChatIndexIndex];
        const clearedChats = prevChats.filter(
          (chat, index) => index !== foundChatIndexIndex,
        );

        foundChat.messages = foundChat.messages || [];
        const updatedChat = {
          ...foundChat,
          lastMessageText: message.text,
          lastMessageId: message.id,
          newMessage: message.authorId !== user.id,
          date: new Date(),
          messages: [{ ...message }, ...foundChat.messages],
        };

        return [updatedChat, ...clearedChats];
      }

      return [...prevChats];
    });
  }, []);

  const onReadReceived = useCallback(({ messageId, readById, chatId }) => {
    // check is it read by user
    if (readById === user.id) {
      setChats((prevChats) => {
        const foundChatIndexIndex = prevChats.findIndex(
          (chat) => chat.chatId * 1 === chatId * 1,
        );

        if (foundChatIndexIndex !== -1) {
          const foundChat = prevChats[foundChatIndexIndex];
          const { lastMessageId } = foundChat;

          const updatedChat = {
            ...foundChat,
            newMessage: lastMessageId !== messageId,
          };

          prevChats[foundChatIndexIndex] = updatedChat;
        }

        return [...prevChats];
      });
    }
  }, []);

  const socket = useSocket();

  useEffect(() => {
    if (socket) {
      socket.on(SOCKET_EVENTS.typing, onTypingReceived);

      return () => socket.off(SOCKET_EVENTS.typing, onTypingReceived);
    }
  }, [socket, onTypingReceived]);
  useEffect(() => {
    if (socket) {
      socket.on(SOCKET_EVENTS.message, onMessageReceived);

      return () => socket.off(SOCKET_EVENTS.message, onMessageReceived);
    }
  }, [socket, onMessageReceived]);

  useEffect(() => {
    if (socket) {
      socket.on(SOCKET_EVENTS.read, onReadReceived);

      return () => socket.off(SOCKET_EVENTS.read, onReadReceived);
    }
  }, [socket, onReadReceived]);

  const requestOptions = useVariables().requestOptionsGET;
  const navigate = useNavigate();
  const createOrGetChat = (otherUserId, withNavigate) => {
    if(!chats.find(chat => chat.members.find(user=>user.userId = otherUserId)))
    return fetch(GET_OR_CREATE_CHAT(otherUserId), requestOptions)
      .then((response) => response.json())
      .then((chat) => {
        if (withNavigate) {
          navigate(`/profile/messenger#${chat.chatId}`);
        } else {
          setChats((prevChatList) => {
            const foundChat = prevChatList.find(
              ({ chatId }) => chatId * 1 === chat.chatId * 1,
            );
            if(chat) {
              setSelectedChatId(chat.chatId)
              return [chat, ...prevChatList];
            }
            /*if (!foundChat) {
              return [chat, ...prevChatList];
            }else {
              console.log("SET CGHAT",chat)
              setSelectedChatId(chat.chatId)
            }*/


            return prevChatList;
          });
        }
      })
      .catch(() => console.log("Произошла ошибка"));
  };

  const lastTypingSentAt = useRef();
  const sendTyping = () => {
    const nowDate = new Date();

    if (
      !lastTypingSentAt.current ||
      nowDate - lastTypingSentAt.current > TYPING_SEND_DELAY_MS
    ) {
      lastTypingSentAt.current = nowDate;
      socket?.emit(SOCKET_EVENTS.typing, {
        chatId: selectedChat.chatId,
      });
    }
  };

  const sendMessage = (message) => {
    console.log("f",message)
    socket?.emit(SOCKET_EVENTS.message, message);
  };

  const sendReadMessage = (messageId) => {
    socket?.emit(SOCKET_EVENTS.read, { messageId });
  };

  const fetchChats = (fio) => {
    const url = GET_PAGED_CHATS(0, PAGE_SIZE, "");

    const requestOptions = {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        Authorization: `${user.type} ${user.accessToken}`,
      },
      body: JSON.stringify(fio ? { name: fio } : { all: true }),
    };

    fetch(url, requestOptions)
      .then((response) => response.json())
      .then((response) => {
        return response.content;
      })
      .then((receivedChats) => {
        setChats(receivedChats);

        if (!selectedChatId) {
          /*const foundInitialChat = receivedChats.find(
            (chat) => initialChatId * 1 === chat.chatId * 1,
          );*/
          const foundChatWithUserId = receivedChats.find(
              chat => chat.members.find(x=>x.userId === (initialChatId * 1))
          )
          if(!foundChatWithUserId && initialChatId) {
            createOrGetChat(initialChatId)
            return;
          }else{
            setSelectedChatId(
                foundChatWithUserId.chatId,
            );
          }

        } else {
          const foundSelectedChat = receivedChats.find(
            (chat) => selectedChatId * 1 === chat.chatId * 1,
          );

          if (!foundSelectedChat) {
            setSelectedChatId(receivedChats[0].chatId);
          }
        }
      })
      .catch((error) => {
        console.log("### error");
        console.log(error);
      });
  };

  const [searchFio, setSearchFio] = useState("");

  const searchTimeoutF = useRef();
  useEffect(() => {
    if (searchTimeoutF.current) {
      clearTimeout(searchTimeoutF.current);
      searchTimeoutF.current = null;
    }

    searchTimeoutF.current = setTimeout(() => {
      if (searchFio?.length >= 3) {
        fetchChats(searchFio);
      } else {
        fetchChats();
      }
    }, 1000);
  }, [searchFio]);
  const openChatWithAdmin = () =>{
    fetch(CREATE_CHAT_WITH_ADMIN,requestOptions).then(r=>r.json()).then((res) => {
      if(res.adminChatId){
        setAdminChatId(res.adminChatId)
        setSelectedChatId(res.adminChatId);
      }else{
        toast.error("Ошибка", {
          position: toast.POSITION.TOP_CENTER,
        });
      }
    }).catch((e)=>{
      console.log(e);
      toast.error("Ошибка", {
        position: toast.POSITION.TOP_CENTER,
      });
    })
  }
  const [messagesPage, setMessagesPage] = useState(0);
  const [messagesLoading, setMessagesLoading] = useState(false);
  const [noMorePages, setNoMorePages] = useState();
  const fetchMessages = (chatId, refresh) => {
    if (noMorePages) {
      if (refresh) {
        setNoMorePages(false);
      } else {
        return;
      }
    }

    const page = refresh ? 0 : messagesPage + 1;

    setNoMorePages(refresh ? false : noMorePages);
    setMessagesLoading(true);
    setMessagesPage(page);

    const url = GET_CHAT_MESSAGES(chatId)(page, PAGE_SIZE, "");

    fetch(url, requestOptions)
      .then((response) => response.json())
      .then((response) => {
        if(response.status && response.status === "Error") {
          toast.error(`Ошибка - ${response.message}`, {
            position: toast.POSITION.TOP_CENTER,
          });
          return;
        }
        const { content: messages, last } = response;
        setNoMorePages(last);

        const foundChatIndex = chats.findIndex(
          (chat) => chatId * 1 === chat.chatId * 1,
        );

        if (foundChatIndex !== -1) {
          setChats((prevChats) => {
            const foundChat = prevChats[foundChatIndex];

            prevChats[foundChatIndex] = {
              ...foundChat,
              lastMessageText: foundChat.lastMessageText ? foundChat.lastMessageText : (messages.length > 0 ? messages[0].text : null),
              messages:
                page > 0
                  ? [...messages, ...(foundChat.messages || [])]
                  : [...messages],
            };

            return [...prevChats];
          });
        }
      })
      .finally(() => {
        setMessagesLoading(false);
      });
  };

  return {
    selectChat: setSelectedChatId,
    createOrGetChat,
    chats,
    selectedChat,
    sendTyping,
    sendMessage,
    sendReadMessage,
    fetchMessages,
    messagesLoading,
    setSearchFio,
    openChatWithAdmin,
  };
};

export default useMessenger;
