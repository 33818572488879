import "../assets/styles/pages/notfound.scss";
import React from "react";

const NotfoundPage = () => (
  <div className="notfound">
    <h1>Страница не найдена</h1>
  </div>
);

export default NotfoundPage;
