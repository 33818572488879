import React, { useState } from "react";
import styled from "styled-components";
import Cropper from "react-easy-crop";

import getCroppedImg from "./cropImage";

const CropModalContent = ({ image, onClose }) => {
  const [crop, setCrop] = useState({ x: 0, y: 0 });
  const [rotation, setRotation] = useState(0);
  const [zoom, setZoom] = useState(1);
  const [croppedAreaPixels, setCroppedAreaPixels] = useState(null);
  const [croppedImage, setCroppedImage] = useState(null);

  const onCropComplete = (croppedArea, croppedAreaPixels) => {
    setCroppedAreaPixels(croppedAreaPixels);
  };

  const showCroppedImage = async () => {
    try {
      const croppedImage = await getCroppedImg(
        image,
        croppedAreaPixels,
        rotation,
      );
      console.log("donee", { croppedImage });
      setCroppedImage(croppedImage);
      onClose(croppedImage);
    } catch (e) {
      console.error(e);
    }
  };

  return (
    <>
      <CropperWrapper>
        <Cropper
          image={image}
          crop={crop}
          rotation={rotation}
          zoom={zoom}
          aspect={1}
          onCropChange={setCrop}
          onRotationChange={setRotation}
          onCropComplete={onCropComplete}
          onZoomChange={setZoom}
        />
      </CropperWrapper>
      <Button
        className="main_button turquoise_button"
        onClick={showCroppedImage}
      >
        Применить
      </Button>
    </>
  );
};

export default CropModalContent;

const CropperWrapper = styled.div`
  position: relative;
  width: 300px;
  height: 300px;
  background-color: white;

  border-radius: 15px;
  overflow: hidden;
`;

const Button = styled.button`
  margin-top: 10px;
`;
