const initialState = {
    "offerStatuses": [
        "ACTUAL",
        "DRAFT",
        "ARCHIVE"
    ],
    "offerTypes": [
        "VACANCY",
        "PRACTICE",
        "INTERNSHIP"
    ],
    "timeJobTypes": [
        "FULL_TIME",
        "PART_TIME",
        "ONE_TIME_ASSIGMENT"
    ],
    "formatJobTypes": [
        "FULL_TIME",
        "CHANGING_GRAPHICS",
        "FLEXIBLE_SCHEDULE",
        "DISTANT_WORK",
        "SHIFT_WORK"
    ],
    userOffers: [],
};

const SET_OFFERS_LIST = "SET_OFFERS_LIST";
const SET_OFFERS_TYPES = "SET_OFFERS_TYPES";
const SET_OFFERS_REFERENCE = "SET_OFFERS_REFERENCE";
const SET_USER_OFFERS = "SET_USER_OFFERS";


export const offersReducer = (state = initialState, action) => {
    switch (action.type) {
        case SET_OFFERS_LIST:
            return {
                ...state,
                offers: action.payload,
            };
        case SET_OFFERS_TYPES:
            return {
                ...state,
                offers_types: action.payload,
            };
        case SET_OFFERS_REFERENCE:
            return {
                ...state,
                ...action.payload,
            }
        case SET_USER_OFFERS:
            return {
                ...state,
                userOffers: action.payload,
            }
        default:
            return state;
    }
};

export const setOffersListAction = (payload) => ({type: SET_OFFERS_LIST, payload});
export const setOffersReferenceAction = (payload) => ({type: SET_OFFERS_REFERENCE, payload});
export const setUserOffersAction = (payload) => ({type: SET_USER_OFFERS, payload});

