import { useEffect, useState } from "react";
import { io } from "socket.io-client";
import { useSelector } from "react-redux";

import { WEBSOCKET_CONNECTION } from "../api/endpoints";

const SOCKET_EVENTS = {
  connect: "connect",
  iam: "iam",
};

const useSocket = () => {
  const user = useSelector((state) => state.user.user);

  const [createdSocket, setCreatedSocket] = useState();
  useEffect(() => {
    const socket = io(WEBSOCKET_CONNECTION, {
      transports: ["websocket"],
    });

    setCreatedSocket(socket);

    socket.on(SOCKET_EVENTS.connect, () => {
      console.log("### SOCKET_EVENTS.connect", user);

      socket.emit(SOCKET_EVENTS.iam, user.accessToken);
    });

    const timeout = setTimeout(() => {
      console.log("### socket.connect");

      socket.connect();
    }, 2000);

    return () => {
      clearTimeout(timeout);
    };
  }, []);

  return createdSocket;
};

export default useSocket;
