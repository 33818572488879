import React from "react";

import Carousel from "@components/Carousel";
import HeaderBlock from "./HeaderBlock";

import shop from "@assets/images/shop.png";
import vk_group from "@assets/images/vk-group.png";

import { privacyPolicy } from "@constants/links";

const PromotionBlock = () => (
  <div
    className={
      "promo_block" /*showFirst ? "promo_block shrink" : "promo_block"*/
    }
  >
    <HeaderBlock />
    <div className="slider_news">
      <Carousel >
        <div className="carousel_item">
          <div className="news_item">
            <h1>Важно все, что ты делаешь</h1>
          </div>
        </div>
        <div className="carousel_item">
          <div className="news_item">
            <h2>
              Присоединяйся к сообществу{" "}
              <a href="http://vk.com/polykapital" target="_blank">
                “ПолиКапитал”
              </a>
            </h2>
            <div className="news_image">
              <img src={vk_group} alt="vk_group" />
            </div>
          </div>
        </div>
        <div className="carousel_item">
          <div className="news_item">
            <h2>Запущено пользовательское тестирование</h2>
            <p>30 студентов и 3 компании уже используют платформу</p>
            <p>
              Если тоже хочешь принять участие в пользовательском тестировании,
              пиши на <a href="mailto: polykp@spbstu.ru">polykp@spbstu.ru</a>
            </p>
          </div>
        </div>
        <div className="carousel_item">
          <div className="news_item">
            <h2>Раздел “Магазин” будет доступен уже в конце 2023 года</h2>
            <div className="news_image">
              <img src={shop} alt="shop" />
            </div>
          </div>
        </div>
      </Carousel>
    </div>
    <div className="links_from_form">
      <p>
        По всем вопросам пишите на почту{" "}
        <a href="mailto: polykp@spbstu.ru">polykp@spbstu.ru</a>
      </p>
      <p>
        <a className="policy" href={privacyPolicy} target={"_blank"}>
          Политика конфиденциальности
        </a>
      </p>
    </div>
  </div>
);

export default React.memo(PromotionBlock);
