import React, {useEffect, useMemo, useRef, useState} from 'react';
import faq from '../../assets/images/faq-bg.png'
import {useVariables} from "@hooks/useVariables";
import {SEND_FEEDBACK} from "@api/endpoints";
import {toast} from "react-toastify";
import styled from "styled-components";

const FeedbackForm = ({close}) => {
    const [name, setName] = useState();
    const [nameTouched, setNameTouched] = useState(false);
    useEffect(() => {
        if (name) {
            setNameTouched(true);
        }
    }, [name]);
    const validName = useMemo(() => !(nameTouched && !name), [name, nameTouched]);
    const formRef = useRef();

    const [email, setEmail] = useState();
    const [emailTouched, setEmailTouched] = useState(false);
    useEffect(() => {
        if (email) {
            setEmailTouched(true);
        }
    }, [email]);
    const validEmail = useMemo(() => {
        return (
            !emailTouched ||
            /^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/.test(email)
        );
    }, [email]);

    const [question, setQuestion] = useState();
    const [questionTouched, setQuestionTouched] = useState(false);
    useEffect(() => {
        if (question) {
            setQuestionTouched(true);
        }
    }, [question]);
    const validQuestion = useMemo(
        () => !(questionTouched && !question),
        [question, questionTouched],
    );

    const valid = validName && nameTouched && validEmail && validQuestion;

    const [params,setParams] = useState({name,email,message:question});
    useEffect(()  =>  {
        setParams({name,email,message:question})
    },[name,email, question])
    const requestPost = useVariables(params).requestOptionsPOST;
    const sendForm = (e) => {
        // TODO implement sending of values
        e.preventDefault();
        fetch(SEND_FEEDBACK, requestPost)
            .then((response) => {
                setEmail("")
                setEmailTouched(false)
                setName("")
                setNameTouched(false)
                setQuestion("")
                setQuestionTouched(false)
                toast.success("Форма отправлена", {
                    position: toast.POSITION.TOP_CENTER,
                });
            })
            .catch((error)  => {
                toast.error("Ошибка, не удалось отправить", {
                    position: toast.POSITION.TOP_CENTER,
                });
            })

        return false;
    };
    return (
                <div className="form-flex">
                    <div className="question-title">
                        <h2 className="mtl">Остались вопросы?</h2>
                        <p>Важно все, что ты делаешь!</p>
                        <p>Теперь профиль студента представляет собой аккуратно структурированную систему компетенций и
                            достижений, в которой цифры превращаются в крафики, а баллы ПГАС в виртуальные токены для
                            всяких призов и подарков!</p>
                        <img src={faq} alt=""/>
                    </div>
                    <form  ref={formRef} className="question-form">
                        <button onClick={close} type="button" className="btn-close"></button>
                        <form action="" className="needs-validation" noValidate="">
                            <div className="invalid-feedback">Заполните выделенные поля</div>
                            <div className="input-group">
                                <label htmlFor="Name">Имя</label>
                                <Input
                                    className="form_field"
                                    type="text"
                                    name="name"
                                    $valid={validName ? "true" : "false"}
                                    required
                                    value={name}
                                    onChange={(e) => setName(e.target.value)}
                                />
                            </div>
                            <div className="input-group">
                                <label htmlFor="Email">Почта</label>
                                <Input
                                    className="form_field"
                                    type="email"
                                    name="email"
                                    $valid={validEmail ? "true" : "false"}
                                    required
                                    value={email}
                                    onChange={(e) => setEmail(e.target.value)}
                                />
                                    <div className="invalid-feedback"></div>
                            </div>
                            <div className="input-group">
                                <label htmlFor="Question">Вопрос</label>
                                <TextArea
                                    className="textarea scroll_element"
                                    required
                                    $valid={validQuestion ? "true" : "false"}
                                    value={question}
                                    onChange={(e) => setQuestion(e.target.value)}
                                ></TextArea>
                            </div>
                            <label className="checks">
                                <input className="checkbox" type="checkbox" checked="" name="checkbox-test" disabled=""/>
                                    <span className="checkbox-custom"></span>
                                    <span className="label">Согласен(на) с <a
                                        href="#">Политикой конфиденциальности</a> и <a href="#">Пользовательским соглашением</a></span>
                            </label>
                            <div className="form-modal-btn">
                                <SubmitButton
                                    className="btn btn-white btn-sent"
                                    type="button"
                                    value="Отправить"
                                    disabled={!valid}
                                    onClick={sendForm}
                                />
                                <button type="button" className="btn-close btn">Отменить</button>
                            </div>
                        </form>
                    </form>
                </div>
    )

}
const Input = styled.input`
  border: 1px solid
    ${({ $valid }) => ($valid === "true" ? "inherit" : "crimson")};
`;

const TextArea = styled.textarea`
  border: 1px solid
    ${({ $valid }) => ($valid === "true" ? "inherit" : "crimson")};
`;
const SubmitButton = styled.input`
  opacity: ${({ disabled }) => (disabled ? 0.2 : 1)};
`;
export default FeedbackForm;