import React, { useEffect, useMemo, useRef, useState } from "react";

import styled from "styled-components";
import { List, ListItem, ListItemButton, ListItemText } from "@mui/material";

import Loader from "@components/Loader";

import { ReactComponent as DownArrowIcon } from "@assets/svg/down-arrow-icon.svg";
import { ReactComponent as CloseIcon } from "@assets/svg/close-icon.svg";

const FilterSelect = ({
  values,
  loading,
  placeholder,
  filterName,
  filters,
  scrollId,
  onFilterSelect,
}) => {
  const [selectedValueIds, setSelectedValueIds] = useState(
    filters[filterName] || [],
  );

  useEffect(() => {
    const newFilters = filters[filterName] || [];
    if (newFilters.join(",") !== selectedValueIds.join(",")) {
      setSelectedValueIds(newFilters);
    }
  }, [filters[filterName]]);

  const selectedValues = useMemo(
    () => values.filter(({ id }) => selectedValueIds.includes(id)),
    [values, selectedValueIds],
  );

  useEffect(() => {
    onFilterSelect(filterName, selectedValueIds);
  }, [selectedValueIds]);

  const inputRef = useRef();

  const [focused, setFocused] = useState(false);
  const onFocus = () => {
    setFocused(true);

    setTimeout(() => {
      inputRef.current?.focus();
    }, 100);
  };
  const onBlur = () => setFocused(false);
  const handleClearPress = () => {
    setSelectedValueIds([]);
    onBlur();
  };

  const handleUnselectValuePress = (id) => {
    setSelectedValueIds((prevValues) =>
      prevValues.filter((prevId) => prevId !== id),
    );
  };

  useEffect(() => {
    if (!focused) {
      setSearchValue("");
    }
  }, [focused]);

  const [searchValue, setSearchValue] = useState("");
  const handleInputChange = (event) => {
    setSearchValue(event.target.value);
  };

  const filteredValues = useMemo(() => {
    return values.filter(
      (value) =>
        value.name.toLowerCase().indexOf(searchValue.toLowerCase()) !== -1,
    );
  }, [values, searchValue]);

  const optionsRef = useRef();
  useEffect(() => {
    const pressHander = (event) => {
      const clickedInside = optionsRef.current?.contains(event.target);

      if (!clickedInside) {
        onBlur();
      }
    };
    document.addEventListener("mousedown", pressHander);

    return () => {
      document.removeEventListener("mousedown", pressHander);
    };
  }, []);

  return (
    <>
      <Wrapper>
        <TopPart>
          <LeftPart>
            {selectedValues.map((selectedValue) => (
              <SelectedItem key={selectedValue.id}>
                <SelectedItemName>{selectedValue.name}</SelectedItemName>
                <Button
                  onClick={() => handleUnselectValuePress(selectedValue.id)}
                >
                  <CloseIcon />
                </Button>
              </SelectedItem>
            ))}
            <Placeholder
              onClick={onFocus}
              style={{ display: !focused ? "block" : "none" }}
            >
              {placeholder}
            </Placeholder>
            <Input
              ref={inputRef}
              style={{ display: focused ? "block" : "none" }}
              type="text"
              value={searchValue}
              onChange={handleInputChange}
              onFocus={onFocus}
            />
          </LeftPart>
          <RightPart>
            {selectedValueIds.length !== 0 ? (
              <Button onClick={handleClearPress}>
                <CloseIcon />
              </Button>
            ) : null}
            <Button onClick={onFocus}>
              <DownIcon fill={focused ? "#666" : "#ccc"} />
            </Button>
          </RightPart>
        </TopPart>
        <OptionsBottom ref={optionsRef}>
          <OptionsWrapper style={{ display: focused ? "block" : "none" }}>
            <List dense className="variants scroll_element" id={scrollId}>
              {filteredValues.map((value) => {
                const { id, name } = value;
                const labelId = `checkbox-list-secondary-label-${id}`;
                const selected = selectedValueIds.indexOf(id) !== -1;

                return (
                  <ListItem key={value.id} disablePadding>
                    <StyledListItemButton
                      selected={selected}
                      onClick={() => {
                        setSelectedValueIds((oldSelectedIds) => {
                          const newIds = oldSelectedIds.includes(value.id)
                            ? oldSelectedIds.filter((v) => v !== value.id)
                            : [...oldSelectedIds, value.id];

                          return newIds;
                        });
                      }}
                    >
                      <StyledListItemText id={labelId} primary={name} />
                      {selected ? (
                        <span style={{ fontSize: 10 }} className="i-close" />
                      ) : null}
                    </StyledListItemButton>
                  </ListItem>
                );
              })}
              {loading ? <Loader /> : null}
            </List>
          </OptionsWrapper>
        </OptionsBottom>
      </Wrapper>
    </>
  );
};

export default React.memo(FilterSelect);

const Wrapper = styled.div`
  width: 100%;
  min-height: 40px;
  background-color: #e9ecef;
  border-radius: 8px;
  padding: 2px 10px;

  position: relative;

  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
`;

const TopPart = styled.div`
  display: flex;
  align-items: center;
  flex: 1;
  width: 100%;
`;

const LeftPart = styled.div`
  display: flex;
  flex: 1;
  flex-direction: column;

  width: 100%;
  overflow: hidden;

  position: relative;
`;

const RightPart = styled.div`
  flex-shrink: 0;
  height: 100%;
`;

const Input = styled.input`
  width: 100%;
  min-height: 20px;
`;

const Placeholder = styled.span`
  color: #adb5bd;

  min-height: 20px;

  font-size: 14px;
`;

const DownIcon = styled(DownArrowIcon)``;

const OptionsBottom = styled.div`
  position: relative;
  width: 100%;
`;

const OptionsWrapper = styled.div`
  position: absolute;
  top: 5px;
  left: 0;
  width: 100%;

  border: 1px solid #ccc;
  border-radius: 8px;
  background-color: white;

  z-index: 1;
`;

const StyledListItemButton = styled(ListItemButton)`
  border-radius: 8px;

  background-color: ${({ selected }) =>
    selected ? "#49A9BF" : "none"} !important;
  color: ${({ selected }) => (selected ? "white" : "none")} !important;

  &:hover {
    background-color: #49a9bf !important;
    color: white;
  }
  padding: 0 !important;
  padding-right: 5px !important;
`;

const StyledListItemText = styled(ListItemText)`
  padding-left: 10px;
`;

const SelectedItem = styled.div`
  background-color: #49a9bf;
  color: white;
  width: 100%;

  margin-bottom: 2px;
  border-radius: 10px;

  display: flex;
  overflow: hidden;
`;

const SelectedItemName = styled.span`
  flex: 1;
  padding: 5px;

  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
`;

const Button = styled.button`
  justify-content: center;
  align-items: center;

  color: #ccc;

  &:hover {
    color: #666;
  }
`;
