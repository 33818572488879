import React, {useState} from 'react';

const ValidateInput = ({error_=false,reason="",defaultValue="",title="",onChange=()=>{},placeholder="",disabled=false}) => {
    const [error,setError] = useState(error_);
    const getError = (text=false) =>{
        return error ?  (text ?
            <label htmlFor="" className="error">{reason}</label>
            :"form-error") : ""
    }
    const focusInput  =  field  =>  {
      setError(false);
    }
    return (
        <div className={`search-blk create-blk ${getError()}`} aria-label={placeholder}>
            {title &&
                <label htmlFor="Name2">{title}</label>
            }
            <input disabled={disabled} onFocus={focusInput} defaultValue={defaultValue} data-field={"secondName"}
                   onChange={onChange} type="text"
                   placeholder={placeholder}/>
            {getError(true)}
        </div>
    )

}
export default ValidateInput;