import "../assets/styles/components/bar_chart.scss";

const BarChart = ({ dataset,maxValue=0, labels=false,barHeight=""}) => {
  dataset?.forEach((el) => {
    if (el.points > maxValue) {
      maxValue = el.points;
    }
  });

  return (
    <div className="bar_chart">
      {dataset.map((el, index) => (
        <div key={index} className={`bar_item`}>
          <p className="bar_label">{`${el.name} `}</p>
          <div className={`${labels ? "bar_item_with_label" : ""}`}>
            <p
                className="bar_body"
                style={{ width: `${(el.points / maxValue) * 100}%`,height: `${barHeight}`,marginTop:labels? 0:""}}
            >
              {labels ? null : el.points}
            </p>
            {labels && ((el.points / maxValue) * 100).toFixed(0) + "%"}
          </div>

        </div>
      ))}
    </div>
  );
};
export default BarChart;
