import React, {useEffect, useState} from 'react';
import {NavLink} from "react-router-dom";
import Reviews from "@pages/StudentInfoPage/Tabs/Reviews";
import Responses from "@pages/StudentInfoPage/Tabs/Responses/Responses";



const InfoPage = ({}) => {
    const tabs = [
        "Отклики на предложения",
    ];
    const [activeTab, setActiveTab] = useState(0);
    const [checkIfOpenByHash, setCheckIfOpenByHash] = useState(false);
    const tabsPages = [
       /* <Reviews/>,*/
        <Responses/>
    ];
    useEffect(()=>{
        if(window.location.hash && !checkIfOpenByHash){
            let tab = parseInt(window.location.hash.substring(1));
            setActiveTab(tab)
            setCheckIfOpenByHash(true);
            return;
        }else {
            if(!checkIfOpenByHash)
                setCheckIfOpenByHash(true);
            window.location.hash = activeTab;
        }
    },[activeTab])
    return(
        <div className="newStyle">
            <NavLink to={"../"} end>
                <a className="btn-back">Профиль</a>
            </NavLink>

            <div className="tab-block tab-edit">
                <ul className="tabs">
                    {tabs.map((tab, index) => (
                        <li onClick={() => setActiveTab(index)} key={index} className={activeTab === index ? "active" : ""}>
                            {tab}
                        </li>
                    ))}
                </ul>
            </div>
            <div className="edit-profile">
                {tabsPages[activeTab]}
            </div>
        </div>
    )
}

export default InfoPage;