import "../assets/styles/components/checkbox.scss";
import { useState } from "react";

const Checkbox = ({
  children,
  name,
  checked = false,
  id,
  value,
  canChangeChecked = true,
  onChange = null,
  additionalData,
}) => {
  const [innerChecked, setInnerChecked] = useState(checked);

  const onChangeHandler = (e) => {
    if (canChangeChecked) {
      setInnerChecked(e.target.checked);
    }
    typeof onChange === "function" && onChange(e.target, additionalData);
  };

  const setChecked = (value) => {
    setInnerChecked(value);
  };

  return (
    <div className="checkbox">
      <input
        className="checkbox_input"
        type="checkbox"
        name={name}
        id={id}
        value={value}
        checked={innerChecked}
        onChange={onChangeHandler}
      />
      <label className="checkbox_label" htmlFor={id}>
        {children}
      </label>
    </div>
  );
};
export default Checkbox;
