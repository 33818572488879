import React from "react";
import styled from "styled-components";

const ProductsListHeader = () => {
  return (
    <thead>
      <tr>
        <th>Фото</th>
        <th>Название</th>
        <th>Описание</th>
        <th>Статус</th>
        <th>Компания</th>
      </tr>
    </thead>
  );
};

export default React.memo(ProductsListHeader);

const ContactPerson = styled.div`
  width: 140px;
  display: flex;
  justify-content: center;
  font-weight: bold;
`;

const PersonFields = styled(ContactPerson)`
  justify-content: space-between;
  margin-top: 5px;
`;
