import "@assets/styles/components/team.scss";
import React, { useState } from "react";
import { useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";

import ModalCustom from "@components/ModalCustom";
import AddFeedbackForm from "@components/AddFeedbackForm";

import TeamMembers from "./components/TeamMembers";
import IncomingRequests from "./components/IncomingRequests";
import OutcomingRequests from "./components/OutcomingRequests";

const Team = ({ tabs }) => {
  const navigate = useNavigate();
  const currentUser = useSelector((state) => state.user.user);
  const [teamChanges, setTeamChanges] = useState(null);

  const isStudent = currentUser.roles.includes("STUDENT");
  const isCompany = currentUser.roles.includes("COMPANY");

  const [selectedTab, setSelectedTab] = useState(0);
  const [feedbackModal, setFeedbackModal] = useState(false);
  const [feedbackModalContent, setFeedbackModalContent] = useState({});

  const redirectToProfile = (id) => {
    if (isStudent) {
      navigate(`profile/${id}`);
    }
    if (isCompany) {
      navigate(`portfolio/${id}`);
    }
  };

  return (
    <div className="team_company">
      <ul className="tab_list">
        {tabs.map((tab, index) => (
          <li
            key={index}
            className={selectedTab === index ? "selected" : ""}
            onClick={() => setSelectedTab(index)}
          >
            <span>{tab}</span>
          </li>
        ))}
      </ul>

      <div className="tab_content">
        {selectedTab === 0 && (
          <TeamMembers
            teamChanges={teamChanges}
            redirectToProfile={redirectToProfile}
            isStudent={isStudent}
            isCompany={isCompany}
            setTeamChanges={setTeamChanges}
            setFeedbackModal={setFeedbackModal}
            setFeedbackModalContent={setFeedbackModalContent}
          />
        )}
        {selectedTab === 1 && (
          <IncomingRequests
            isStudent={isStudent}
            isCompany={isCompany}
            redirectToProfile={redirectToProfile}
            setTeamChanges={setTeamChanges}
          />
        )}
        {selectedTab === 2 && (
          <OutcomingRequests
            isStudent={isStudent}
            isCompany={isCompany}
            redirectToProfile={redirectToProfile}
            setTeamChanges={setTeamChanges}
          />
        )}
      </div>
      {isCompany && (
        <ModalCustom
          title="Обратная связь"
          active={feedbackModal}
          setActive={setFeedbackModal}
        >
          <AddFeedbackForm
            student={feedbackModalContent}
            setFeedbackModal={setFeedbackModal}
          />
        </ModalCustom>
      )}
    </div>
  );
};

export default Team;
