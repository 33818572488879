import React, {useEffect, useRef, useState} from 'react';
import ModalCustom from "@components/ModalCustom";
import CreateModal from "@pages/AdminCreateStudent/Tabs/Indicators/Dpo/CreateModal";
import {NavLink} from "react-router-dom";
import {ADMIN_STUDENTS} from "@api/endpoints";
import {useVariables} from "@hooks/useVariables";


const Dpo = ({data,updateData}) => {
    const [dpos,setDpos] = useState(null);
    const dposref = useRef()
    dposref.current = dpos;
    const [activeModal, setActiveModal] = useState(false);
    const [selectedTab,setSelectedTab] = useState("MAIN");
    const [selectedDpo,setSelectedDpo] = useState(null);
    const requestGet = useVariables().requestOptionsGET
    const [dpoList,setDpoList]= useState([]);
    useEffect(()=>{
        fetch(ADMIN_STUDENTS.GET_DPO,requestGet).then(res => res.json()).then(data => {setDpoList(data.content)});
    },[])
    const newDpo = (res)=>{
        let dp_ = [res];
        if(dposref.current && dposref.current.main) {
            dp_.push(...dposref.current.main);
            dp_.push(...dposref.current.digital);
        }
        setDpos(dp_);
        updateData();
        loadData(dp_)
    }
    const loadData = (data_) => {
        const main = data_.filter(dpo => dpo.dpo.dpoType.type ==='MAIN');
        const digital = data_.filter(dpo => dpo.dpo.dpoType.type ==='DIGITAL');
        setDpos({main,digital});
    }
    useEffect(()=>{
        if(data){
            loadData(data);
        }
    },[data])
    const closeModal = () => {
        setActiveModal(false);
    }
    return(
        <div className="active">
            <div className="table-blk">
                <h3>Основные ДПО</h3>
                <table className="tables tables-dpo">
                    <thead>
                    <tr>
                        <th className="add"><a onClick={() => {
                            setSelectedTab("MAIN")
                            setActiveModal(true)
                        }} className="btn btn-blue modal-link"
                                               data-modal="#modal-add3"></a></th>
                        <th>Название ДПО</th>
                        <th style={{textAlign:"end"}}>Дата</th>
                    </tr>
                    </thead>
                    <tbody>
                        {dpos && dpoList && dpos.main.map(dpo => (
                            <tr>
                                <td>
                                    <ul className="unstyled">
                                        <li><a className="edit"></a></li>
                                        <li>
                                            <a className="delete"></a>
                                        </li>
                                    </ul>
                                </td>
                                <td>{dpo.dpo.name}</td>
                                <td>{dpo.year}</td>
                            </tr>
                        ))}

                    </tbody>
                </table>
            </div>
            <div className="table-blk">
                <h3>Цифровые ДПО</h3>
                <table className="tables tables-dpo">
                    <thead>
                    <tr>
                        <th className="add"><a onClick={() => {
                            setSelectedTab("DIGITAL")
                            setActiveModal(true)
                        }} className="btn btn-blue modal-link"
                                               data-modal="#modal-add3"></a></th>
                        <th>Название ДПО</th>
                        <th style={{textAlign:"end"}}>Дата</th>
                    </tr>
                    </thead>
                    <tbody>
                        {dpos && dpos.digital.map(dpo => (
                            <tr>
                                <td>
                                    <ul className="unstyled">
                                        <li><a className="edit"></a></li>
                                        <li>
                                            <a className="delete"></a>
                                        </li>
                                    </ul>
                                </td>
                                <td>{dpo.dpo.name}</td>
                                <td>{dpo.year}</td>
                            </tr>
                        ))}
                    </tbody>
                </table>
            </div>
            <ModalCustom
                title={"Добавление ДПО"}
                active={activeModal}
                className={"autoWidth"}
                setActive={setActiveModal}
            >
                <CreateModal
                    newDpo={newDpo}
                    dpoList={dpoList}
                    closeModal={closeModal}
                />
            </ModalCustom>
        </div>

    )

}
export default Dpo;