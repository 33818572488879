import "./add_product.scss";

import styled from "styled-components";
import React, { useEffect, useState } from "react";
import { toast } from "react-toastify";
import { useSelector } from "react-redux";

import { usePreviewFile } from "@hooks/usePreviewFile";
import { useVariables } from "@hooks/useVariables";

import InputFile from "@components/InputFile";
import ModalCustom from "@components/ModalCustom";
import CropModalContent from "@components/organisms/ProfileEditor/components/CropModalContent";

import defaultProductImage from "@assets/images/product-default.png";

import { ADD_PRODUCT, UPLOAD_PRODUCT_IMAGE } from "@api/endpoints";

const AddProduct = ({ setModalActive, onAddProduct }) => {
  const user = useSelector((state) => state.user.user);
  const requestOptionsPOST = useVariables().constRequestOptionsPOST;
  const preview = usePreviewFile(defaultProductImage, "image.*");
  const { selectedFile: image, setSelectedFile: setSelectedImage } = preview;

  const handleImageChange = preview.handleFileChange;

  const [name, setName] = useState();
  const [description, setDescription] = useState();
  const valid = name && description;

  const handleAddProduct = (e) => {
    e.preventDefault();
    const form = new FormData(e.target);
    const product = {
      displayName: form.get("displayName"),
      description: form.get("description"),
    };
    addProduct(product, form.get("product_image"));
  };

  const [imageCropModalActive, setImageCropModalActive] = useState(false);
  const [selectedRawImage, setSelectedRawImage] = useState();
  const handleAvatarFileChange = (e) => {
    const file = e.target.files[0];
    if (file && file.type.match("image.*")) {
      const reader = new FileReader();
      reader.onload = (e) => {
        setSelectedRawImage({ url: e.target.result });
      };
      reader.readAsDataURL(file);
    }
  };

  const [croppedImage, setCroppedImage] = useState();
  useEffect(() => {
    if (selectedRawImage) {
      setImageCropModalActive(true);
    }
  }, [selectedRawImage]);

  useEffect(() => {
    if (croppedImage) {
      setSelectedImage(croppedImage);
    }
  }, [croppedImage]);

  const addProduct = (product, productImage) => {
    fetch(ADD_PRODUCT, requestOptionsPOST(product))
      .then((response) => response.json())
      .then((newProduct) => {
        fetch(image)
          .then((response) => response.blob())
          .then((blob) => {
            const formData = new FormData();
            formData.append("file", blob);

            const requestOptionsAvatar = {
              method: "POST",
              headers: {
                Authorization: `${user.type} ${user.accessToken}`,
              },
              body: formData,
            };

            fetch(
              UPLOAD_PRODUCT_IMAGE(newProduct.productId),
              requestOptionsAvatar,
            )
              .then((response) => {
                if (response.ok) {
                  return response.json();
                } else {
                  throw true;
                }
              })
              .then((newImage) => {
                onAddProduct();
                setModalActive(false);
              })
              .catch((error) => {
                toast.error("Ошибка при загрузке картинки", {
                  position: toast.POSITION.TOP_CENTER,
                });
              });
          });
      })
      .catch(() => console.log("Произошла ошибка"));
  };

  return (
    <>
      <form className="add_product" onSubmit={handleAddProduct}>
        <div className="product_block">
          <div className="image">
            <img src={image} alt="product-image" />
          </div>

          <div className="info_block">
            <div className="field">
              <label>Название товара</label>
              <input
                required
                className="form_field"
                name="displayName"
                onChange={(e) => setName(e.target.value)}
              />
            </div>
            <div className="field">
              <label>Описание</label>
              <textarea
                required
                className="textarea"
                name="description"
                onChange={(e) => setDescription(e.target.value)}
              ></textarea>
            </div>
            <InputFile
              label="Загрузите фото товара"
              name="product_image"
              accept="image"
              onChangeHandler={handleAvatarFileChange}
            />
          </div>
        </div>
        <div className="inline_buttons">
          <button
            className="white_button secondary_button"
            onClick={() => setModalActive(false)}
          >
            Отмена
          </button>
          <SubmitButton
            className="turquoise_button secondary_button"
            type="submit"
            value="Отправить на модерацию"
            disabled={!valid}
          />
        </div>
      </form>
      <ModalCustom
        title="Подготовка Изображения"
        active={imageCropModalActive}
        setActive={setImageCropModalActive}
      >
        <CropModalContent
          image={selectedRawImage ? selectedRawImage.url : undefined}
          onClose={(croppedImage) => {
            setCroppedImage(croppedImage);
            setImageCropModalActive(false);
          }}
        />
      </ModalCustom>
    </>
  );
};

export default AddProduct;

const SubmitButton = styled.input`
  opacity: ${({ disabled }) => (disabled ? 0.2 : 1)};
`;
