import React, {useState} from 'react';
import Competences from "@pages/ProfilePage/components/ProfileStudent/Indicators/Competences";
import Achievements from "@pages/ProfilePage/components/ProfileStudent/Indicators/Achievements";
import Progress from "@pages/ProfilePage/components/ProfileStudent/Indicators/Progress";
import Dpos from "@pages/ProfilePage/components/ProfileStudent/Indicators/Dpos";
import Rsvs from "@pages/ProfilePage/components/ProfileStudent/Indicators/Rsvs";
import CustomTabs from "@components/CustomTabs";



const Indicators = ({className,studentData}) => {
    const [activeTab,setActiveTab]= useState(0);
    const tabs = [
        {id:0,title:"Компетенции"},
        {id:1,title:"Достижения"},
        {id:2,title:"Области деятельности"},
        {id:3,title:"ДПО"},
        {id:4,title:"Компетенции в рамках РСВ"}
    ]
    const tabsElements = [
        <Competences studentData={studentData}/>,
        <Achievements studentData={studentData}/>,
        <Progress studentData={studentData}/>,
        <Dpos studentData={studentData}/>,
        <Rsvs studentData={studentData}/>
    ]
    return(
        <div className={`indicator-page ${className}`}>
            <div className="indicator-block">
                <div className="indicator-blk blks">
                    <h1>Показатели <span></span></h1>
                    <div className="tabs">
                        <CustomTabs activeTab={activeTab} setActiveTab={setActiveTab} tabs={tabs} className={"d-flex tabsmenu"}/>

                        <div className="tab-content">
                            {tabsElements[activeTab]}
                        </div>
                    </div>
                </div>
              {/*  <div className="career-block blks">
                    <div className="flex career-title">
                        <h2>Карьера</h2>
                        <a href="#" className="btn btn-arrow">Смотреть все</a>
                    </div>
                    <div className="career-block-offer">
                        <div className="career-offer-blk flex">
                            <div className="career-offer-title">
                                <p>Название предложения</p>
                                <span className="career-icon1">Приглашен</span>
                            </div>
                            <a href="#" className="career-tag">Вакансия</a>
                        </div>
                        <div className="career-offer-blk flex">
                            <div className="career-offer-title">
                                <p>Название предложения</p>
                                <span className="career-icon2">На рассмотрении</span>
                            </div>
                            <a href="#" className="career-tag">Практика</a>
                        </div>
                        <div className="career-offer-blk flex">
                            <div className="career-offer-title">
                                <p>Название предложения</p>
                                <span className="career-icon3">Просмотрено</span>
                            </div>
                            <a href="#" className="career-tag">Стажировка</a>
                        </div>
                        <div className="career-offer-blk flex">
                            <div className="career-offer-title">
                                <p>Название предложения</p>
                                <span className="career-icon6">Переговоры</span>
                            </div>
                            <a href="#" className="career-tag">Стажировка</a>
                        </div>
                    </div>
                </div>*/}
            </div>
        </div>
    )
}
export default Indicators;