import React, {useRef} from 'react';
import {offersLanguages} from "@utils/languageList";
import {NavLink} from "react-router-dom";
import StudentVacancyStatus from "@components/StudentVacancyStatus";
import useClickOutside from "@hooks/useClickOutside";
import {changeOfferResponseStatus} from "@store/asyncActions/offersResponses";
import {toast} from "react-toastify";
import {useSelector} from "react-redux";

const TableChildren = ({item, onRemove,refreshResponses}) => {
    const user = useSelector(state=> state.user.user);

    const [menu, setMenu] = React.useState(false);
    const menuRef = useRef(null);

    menuRef.current = menu;
    const ref = useRef();
    const outSideClick = () => {
        if (menuRef.current) {
            setMenu(false);
        }
    }
    const visibleStyle = {
        display: "flex",
        boxShadow: ["0px 2px 3px 0px rgba(0, 0, 0, 0.1)", "0px 5px 10px 0px rgba(0, 0, 0, 0.1)"],
        border: "1px solid rgba(221, 221, 221, 1)",
        flexDirection: "column",
        position: "absolute",
        background: "white",
        right: 0,
        padding: 15,
        borderRadius: 20,
    }
    useClickOutside(ref, outSideClick)
    const changeStatus = (status) => {
        setMenu(false)
        changeOfferResponseStatus(user, item?.jobResponseId, status).then(r => {

            if (r.status && r.status === "Error") {
                toast.error("Ошибка." + r.message, {
                    position: toast.POSITION.TOP_CENTER,
                });
            } else {
                refreshResponses();

                toast.success("Вы отозвали отклик", {
                    position: toast.POSITION.TOP_CENTER,
                });
            }

        })
    }
    return (
        <tr data-tab="3" className="active">

            <td>
                <div className="vacancy-title vacancy-title__student">
                    <p className="title">  {item?.offerInfo?.companyName}</p>
                    <h4>{item?.offerInfo?.name}</h4>

                </div>


            </td>
            <td className={"td-tag"}>
                <span className=" ">{offersLanguages.type[item?.offerInfo?.offerType]}</span>
            </td>
            <td className="create-status_">{StudentVacancyStatus(item?.jobResponseStatus)}</td>
            <td>
                <NavLink to={`/profile/view-career/${item.offerId}`} end>
                    <a className="btn btn-arrow">Перейти</a>
                </NavLink>

            </td>
            {item.jobResponseStatus === "CONSIDERATION" ?
                <td>
                    <button onClick={() => setMenu(prevMenu => !prevMenu)} className="td-more"></button>

                    <ul ref={ref} style={menu ? visibleStyle : {}} className="unstyled td-more-info">

                        {item?.jobResponseStatus !== "REJECTED_BY_COMPANY" && item.jobResponseStatus !== "APPROVED" &&
                            <li>

                                <NavLink onClick={() => changeStatus("CANCELED_BY_STUDENT")} end>
                                    Отменить отклик
                                </NavLink>
                            </li>
                        }

                        {/* <li>
                        <NavLink to={"#"} end>
                            Отозвать отклик
                        </NavLink>
                    </li>*/}
                    </ul>
                </td>:
                <td></td>
            }

        </tr>
    )

}

export default TableChildren