import React, {useEffect, useState} from 'react';
import OfferType from "@pages/CreateCareerPage/components/offerType";
import Table from "./components/Table";
import TableChildren from "./components/TableChildren";
import {usePagination} from "@hooks/usePagination";
import {GET_MY_RESPONSES, GET_OFFERS_LIST} from "@api/endpoints";
import {useVariables} from "@hooks/useVariables";
import {useSavedUser} from "@hooks/useAuth";


const Responses = ({ reviews }) => {
    const {savedUser} = useSavedUser();

    const [filters,setFilters] = useState({name:"",authorId:savedUser.id,offerType:""});
    const [sort,setSort] =useState({offerId:"desc"});
    const requestOptionsGet = useVariables().requestOptionsGET;
    const responses = usePagination(GET_MY_RESPONSES,requestOptionsGet,`${Object.keys(sort)[0]},${Object.values(sort)[0]}`,null,filters,true)

    useEffect(()=>{
        if(sort)
            responses.refresh();
    },[sort])

    return (
        <>
            <OfferType activeType={filters.offerType } setActiveType={(offerType )=>setFilters({...filters,offerType })}/>
            <div className="search">
                <input onChange={(e)=>{ setFilters({...filters,name:e.target.value})}} value={filters?.name || ""} type="text" placeholder="Поиск по названию"/>
                <button type="submit"></button>
            </div>
            <div
                onClick={()=>{
                    setSort(prevState => {
                        return (prevState.offerId === "desc" ?
                            {offerId: "asc"} : {offerId: "desc"})
                    })
                }}
                className={`sorting ${sort.offerId === "desc"? "sorting-desc" : "sorting-asc"}`}>
                <span>По дате создания</span>
            </div>
            <Table>
                {responses.data.map((offer,index)=>(
                    <TableChildren refreshResponses={()=>responses.refresh()} item={offer} key={index}/>
                ))}
            </Table>
        </>
    )
}

export default Responses;