import React from'react';
import {offersLanguages} from "@utils/languageList";
import {NavLink} from "react-router-dom";

const TableChildren = ({item,onRemove}) => {

    const statusArr = {
        "ACTUAL":<span className="type1 type-green">{offersLanguages.status.ACTUAL}</span>,
        "DRAFT":<span className="type2">{offersLanguages.status.DRAFT}</span>,
        "ARCHIVE":<span className="type3">{offersLanguages.status.ARCHIVE}</span>,

    }
    return (
        <tr data-tab="3" className="active">
            <td>
                <ul className="unstyled">
                    <NavLink to={`/admin/edit-career/${item.offerId}`} end>
                        <li><a className="edit"></a></li>
                    </NavLink>

                    <li><a onClick={onRemove} className="delete"></a></li>
                </ul>
            </td>
           <td className={"vacancy-name-table"}>
                {item.companyName &&
                    <h3>{item.companyName}</h3>
                }
               <span className="name">{item.name}</span>
            </td>
            <td className="status">{statusArr[item.offerStatus]}</td>
            <td data-label="Просмотры:">{item.views}</td>
            <td data-label="Отклики:">{item.jobResponses}</td>
            <td>
                <NavLink to={`/admin/view-career/${item.offerId}`} end>
                    <a className="btn btn-arrow">Перейти</a>
                </NavLink>

            </td>
        </tr>
    )

}

export default TableChildren