import "@assets/styles/pages/shop.scss";

import React, { useEffect, useState } from "react";
import "@assets/newpages/style.scss"
import {useDispatch, useSelector} from "react-redux";
import { toast } from "react-toastify";
import {NavLink, useNavigate} from "react-router-dom";
import {
  loadOffersList
} from "@store/asyncActions/offers"
import CustomTabs from "@components/CustomTabs";
import Loader from "@components/Loader";
import {useSavedUser} from "@hooks/useAuth";
import {usePagination} from "@hooks/usePagination";
import {GET_EDU_DIRECTIONS, GET_OFFERS_LIST, GET_OFFERS_SPECIALIZATIONS} from "@api/endpoints";
import {offersLanguages} from "@utils/languageList";
import {useVariables} from "@hooks/useVariables";
import Filters from "@pages/CareersPage/components/Filters";
import CareerBlock from "@pages/CareersPage/components/CareerBlock";

const CareersPage = () => {
  const user = useSelector(state => state.user);
  const offersStore = useSelector(state => state.offers)
  const initFilters = {offerStatus:"ACTUAL",offerType:"",minSalary:"",maxSalary:""};
  const [filters, setFilters] = useState(initFilters)
  const {savedUser} = useSavedUser()
  const [activeTab, setActiveTab] = useState("");
  const [tabs,setTabs] = useState([]);
  const dispatch = useDispatch();
  const requestOptionsGET = useVariables().requestOptionsGET;
  const offers = usePagination(GET_OFFERS_LIST, requestOptionsGET,"",null,filters,true);
  //const specializations = usePagination(GET_OFFERS_SPECIALIZATIONS, requestOptionsGET, "");
  useEffect(()=>{
    setFilters(prevState=> {
      return {...prevState, offerType: activeTab}
    })
  },[activeTab])
  const selectCities = (cities) =>{
    setFilters({...filters,  cityId:cities});
  }
  const selectFilter = (e) => {
    let type = e.target.dataset.type;
    let value = e.target.dataset.value;
    let arr = Boolean(e.target.dataset.arr);
    if(!arr){
      if(type === "startDate" || type === "endDate"){
        value = new Date(e.target.value).toISOString().split("T")[0];
      }
      if(type === "name")
        value = e.target.value;
      if(type === "minSalary" || type === "maxSalary"){
        value = parseInt(e.target.value);
      }
      if(filters[type]!== value)
        setFilters({...filters,[type]:value});
    }else{
      value = parseInt(value);
      if(!filters[type]) {
        setFilters({...filters, [type]: [value]});
        return;
      }
      if(filters[type].includes(value)) {
        if(filters[type].length === 1){
          let flt = {...filters};
          delete flt[type];
          setFilters({...flt});
        }else
          setFilters({...filters, [type]: filters[type].filter(item => item !== value)});
      }
      else
        setFilters({...filters,[type]:[...filters[type],value]});
    }

  }
  return (
      <div className="newStyle">
        {offersStore.offerTypes &&
            <div className="tab-block">
              <CustomTabs tabs={
                 [{id:"",title:"Все"},...offersStore.offerTypes.map(item=>{
                    return {
                      title:offersLanguages.type[item],
                      id:item
                    }
                  })]
              } activeTab={activeTab} setActiveTab={setActiveTab}/>
            </div>
        }
        <div className="row-block offers-list">
          <div className="offer-list-block">
            <div className="search">
              <input value={filters.name}
                     data-type={"name"}
                     onChange={selectFilter}
                     type="text" placeholder="Поиск по названию"/>
                <button type="submit"></button>
            </div>
            {/*<div className="sorting">
              <span>По дате создания</span>
            </div>*/}
            <div className="row-block wrap-block">
              {(!offers || !offers.data) &&
                  <Loader />
              }
              {offers && offers.data && offers.data.length === 0 &&
                <span className="no-offers">Ничего не найдено</span>
              }
              {offers && offers.data && offers.data.length > 0 && offers.data.map((item) => (
                 <CareerBlock key={item.id} item={item}/>

              ))}


            </div>
          </div>
          <Filters
              showDates={activeTab !== "VACANCY"}
              showSalary={activeTab === "VACANCY"}
              selectCities={selectCities} selectFilter={selectFilter} refershFilters={()=>setFilters(initFilters)} filters={filters} setFilters={setFilters}/>
        </div>
      </div>
  );
};

export default CareersPage;
