import React, {useEffect, useRef, useState} from 'react';
import {useVariables} from "@hooks/useVariables";
import {ADMIN_STUDENTS} from "@api/endpoints";
import ButtonWithLoading from "@components/ButtonWithLoading";
import {toast} from "react-toastify";


const Competences = ({data,updateData}) => {
    const [competences, setCompetences] = useState(null);
    const [changes, setChanges] = useState([]);
    const changesRef = useRef();
    changesRef.current = changes;
    const requestPut = useVariables().constRequestOptionsPUT;
    useEffect(() => {
        if (data) {
            const main = data.filter(competence => competence.competence.competenceType.type === 'MAIN');
            const extra = data.filter(competence => competence.competence.competenceType.type === 'EXTRA');
            setCompetences({main, extra});
        }
    }, [data]);
    const handleChange = (e) => {
        let itemId = parseInt(e.target.dataset.id);
        let changes_ = [...changes];
        let index = changes_.findIndex(item => item.studentCompetenceId === itemId);
        if (index >= 0)
            changes_[index].competenceScore = parseInt(e.target.value);
        else
            changes_.push({studentCompetenceId: itemId, competenceScore: parseInt(e.target.value)});
        setChanges([...changes_]);
    }
    const saveChanges = async () => {
        let errors = 0;

        if (changesRef.current.length > 0){
            let cfg = requestPut(changesRef.current)
            let status = await fetch(ADMIN_STUDENTS.UPDATE_MARKS.COMPETENCE, cfg);
            status = await status.json();
            if (status.status !== "Success")
                errors++;
        }

        if (errors === 0) {
            toast.success(`Изменения успешно сохранены`, {
                position: toast.POSITION.TOP_CENTER,
            });
            updateData();
            setChanges([]);
        } else {
            toast.error(`Произошла ошибка при сохранении. `, {
                position: toast.POSITION.TOP_CENTER,
            });
        }
        return true;
    }
    return (
        <div className="active">
            <div className="table-blk">
                <h3>Основные компетенции</h3>
                <table className="tables tables-student">
                    <thead>
                    <tr>
                        <th>Компетенция</th>
                        <th>Текущие баллы</th>
                        <th>Редактирование</th>
                    </tr>
                    </thead>
                    <tbody>
                    {competences && competences.main.map(competence => (
                        <tr>
                            <td>{competence.competence.name}</td>
                            <td>{competence.competenceScore}</td>
                            <td className="td-edit">
                                <input onChange={handleChange} data-id={competence.studentCompetenceId} type="number"
                                       min={0} defaultValue={competence.competenceScore}/>
                            </td>
                        </tr>
                    ))}
                    </tbody>
                </table>
            </div>
            <div className="table-blk">
                <h3>Дополнительные компетенции</h3>
                <table className="tables tables-student">
                    <thead>
                    <tr>
                        <th>Компетенция</th>
                        <th>Текущие баллы</th>
                        <th>Редактирование</th>
                    </tr>
                    </thead>
                    <tbody>
                    {competences && competences.extra.map((competence, index) => (
                        <tr key={index}>
                            <td>{competence.competence.name}</td>
                            <td>{competence.competenceScore}</td>
                            <td className="td-edit">
                                <input onChange={handleChange} data-id={competence.studentCompetenceId} type="number"
                                       min={0} defaultValue={competence.competenceScore}/>
                            </td>
                        </tr>
                    ))}

                    </tbody>
                </table>
            </div>
            <div className="table-btn student-btn">
                <ButtonWithLoading
                    type={"button"}
                    className={"btn btn-blue modal-link"}
                    title={"Сохранить"}
                    onClick={saveChanges}
                />
                {/* <button type="button" className="btn btn-blue modal-link"
                        data-modal="#avatarModal">Сохранить
                </button>*/}
            </div>
        </div>

    )

}
export default Competences;