import { useVariables } from "./useVariables";

import {
  APPROVE_INVITATION,
  INVITE_TO_COMMAND,
  KICK_FROM_COMMAND,
  KNOCK_TO_COMMAND,
  LEAVE_COMMAND,
  REJECT_INVITATION,
} from "../api/endpoints";

export const useTeamInteraction = (setUser = null) => {
  const requestOptions = useVariables().requestOptionsGET;

  // for students from companies
  const inviteToCommand = (userId) => {
    fetch(INVITE_TO_COMMAND(userId), requestOptions)
      .then(() => {
        if (setUser) {
          setUser((student) => {
            return {
              ...student,
              inviteStatus: "COMPANY_INVITE",
            };
          });
        }
      })
      .catch(() => console.log("Не удается подключиться к серверу"));
  };

  const kickFromCommand = (userId) => {
    fetch(KICK_FROM_COMMAND(userId), requestOptions)
      .then(() => {
        if (setUser) {
          setUser((student) => {
            return {
              ...student,
              inviteStatus: "NO_INVITE",
            };
          });
        }
      })
      .catch(() => console.log("Не удается подключиться к серверу"));
  };

  //for companies from students
  const knockToCommand = (companyId) => {
    fetch(KNOCK_TO_COMMAND(companyId), requestOptions)
      .then(() => {
        if (setUser) {
          setUser((student) => {
            return {
              ...student,
              inviteStatus: "REQUEST_SENT",
            };
          });
        }
      })
      .catch(() => console.log("Не удается подключиться к серверу"));
  };

  const leaveCommand = (companyId) => {
    fetch(LEAVE_COMMAND(companyId), requestOptions)
      .then(() => {
        if (setUser) {
          setUser((student) => {
            return {
              ...student,
              inviteStatus: "REQUEST_SENT",
            };
          });
        }
      })
      .catch(() => console.log("Не удается подключиться к серверу"));
  };

  //general
  const approveInvitation = (invitationId) => {
    fetch(APPROVE_INVITATION(invitationId), requestOptions)
      .then(() => {
        if (setUser) {
          setUser((user) => {
            return {
              ...user,
              inviteStatus: "APPROVED",
            };
          });
        }
      })
      .catch(() => console.log("Не удается подключиться к серверу"));
  };

  const rejectInvitation = (invitationId) => {
    return fetch(REJECT_INVITATION(invitationId), requestOptions).catch(() =>
      console.log("Не удается подключиться к серверу"),
    );
  };

  return {
    inviteToCommand,
    kickFromCommand,
    knockToCommand,
    leaveCommand,
    approveInvitation,
    rejectInvitation,
  };
};
