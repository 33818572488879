import "../assets/styles/components/input_file.scss";
import { useState } from "react";

const InputFile = ({
  name,
  label,
  accept = "*",
  multiple = false,
  onChangeHandler = null,
  chooseFileLabel = "Выберите файл",
}) => {
  const [countFiles, setCountFiles] = useState(0);

  const changeHandler = (e) => {
    setCountFiles(e.target.files.length);
    (typeof onChangeHandler === "function" || typeof onChangeHandler === "object") && onChangeHandler(e);
  };

  return (
    <div className="input_file">
      <label className="input-file">
        <span className="label_block">{label}</span>
        <input
          type="file"
          name={name}
          accept={accept}
          multiple={multiple}
          onChange={changeHandler}
        />
        <span className="load_block">
          <span className="i-download"></span>
          {countFiles === 0
            ? chooseFileLabel
            : `Выбрано: ${countFiles} файл(а)`}
        </span>
      </label>
    </div>
  );
};

export default InputFile;
