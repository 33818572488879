import React, {useEffect, useState} from "react";
import {usePagination} from "@hooks/usePagination";
import {GET_COMPETENCIES, GET_OFFERS_CITIES, GET_OFFERS_SPECIALIZATIONS} from "@api/endpoints";
import {useVariables} from "@hooks/useVariables";
import FilterAccordion from "@pages/CareersPage/components/FilterAccordion";
import {offersLanguages} from "@utils/languageList";
import {useSelector} from "react-redux";
import FiltersCities from "@pages/CareersPage/components/FiltersCities";
import FiltersCompetences from "@pages/CareersPage/components/FiltersCompetences";
import FiltersSpacializations from "@pages/CareersPage/components/FiltersSpecializations";
import {useSavedUser} from "@hooks/useAuth";
import CustomSelect from "@components/CustomSelect";
import {loadAllCompanies, loadCities, loadCompanies} from "@store/asyncActions/offers";
import CitiesListSelect from "@components/CitiesListSelect";

const Filters = ({filters,showDates,selectFilter,showSalary,selectCities,refershFilters,setFilters}) => {
    const user = useSelector(state => state.user.user);
    const {savedUser} = useSavedUser()
    const [isCompany]  = useState(user  && user.roles ? user.roles.includes("COMPANY")  : false);
    const [collapseFilter, setCollapseFilter] = useState(true);

    const offersStore = useSelector(state => state.offers)
    const requestOptionsOutgoings =
        useVariables().requestOptionsGET;

    //const cities = usePagination(GET_OFFERS_CITIES, requestOptionsOutgoings, "").data;
    const specializations = usePagination(GET_OFFERS_SPECIALIZATIONS, requestOptionsOutgoings, "").data;
    const [competencies,setCompetencies] = useState([]);
    const [companies, setCompanies] = useState([]);
    const [authorId,setAuthorId]  = useState(null);




    const checkIfActive = (type,value) =>{
        if(filters[type] && Array.isArray(filters[type]))
            return filters[type].includes(value)? "active" : "";

        return filters.hasOwnProperty(type) && filters[type] === value ? "active" : "";
    }


    useEffect(() => {
        fetch(GET_COMPETENCIES(), requestOptionsOutgoings)
            .then((response) => response.json())
            .then((json) => {
                setCompetencies(json)
            })
            .catch(() => console.log("Произошла ошибка"))
        loadCompanies(savedUser).then((json) => {
            setCompanies(json.content)
        })
    }, [])
    const getDate = (date) =>{
        let now  = new Date(date);
        const isoDate = now.toISOString();
        const formattedDate = isoDate.slice(0, -1);
        return  formattedDate;
    }
    return (
        <div className={`filter-block ${collapseFilter? "" : "active"}`}>
            <h2 className={"filter-title"} onClick={()=>  setCollapseFilter(!collapseFilter)}>Фильтры</h2>
            <div className="filter-content">
                <div className="filter-blk">
                    <span className="title">Статус</span>
                    <ul className="tabs tag tag-active unstyled">
                        {offersStore.offerStatuses.map((status) => {
                            if(status === "DRAFT" && savedUser.roles.includes("STUDENT"))
                                return null;
                            return(
                                <li
                                    data-type={"offerStatus"}
                                    data-value={status}
                                    onClick={selectFilter}
                                    className={checkIfActive("offerStatus", status)}>
                                    {offersLanguages.status[status]}
                                </li>
                            )
                        })}

                    </ul>
                </div>

                {/* <FilterAccordion title={"Направление"}>
                <ul className="tag tag-choice active unstyled">
                    <li>Название первого направления <button className="btn-close"></button></li>
                    <li>Название второго направления <button className="btn-close"></button></li>
                </ul>
            </FilterAccordion>*/}
                <FilterAccordion title={"Организация"}>
                    <CustomSelect
                        parentStyle={{width:  "100%"}}
                        placeholder={"Организация"}
                        refValue={authorId}
                        onlyClear={true}
                        onChange={(e) => {
                            if(!e){
                                let ls = {...filters};
                                delete ls.authorId;
                                setFilters({...ls})
                            }else{
                                setFilters({...filters, authorId: e})
                            }
                            setAuthorId(e ? {label: companies.find((comp) =>  comp.id  === e).name, value: e} : null)
                        }}
                        isMulti={false}
                        options={companies && companies.length > 0 && companies.map((comp) => {
                            return {label: comp.name, value: comp.id}
                        })}
                    />
                    {/* <ul className="tag tag-choice active unstyled">
                    <li>Название организации <button className="btn-close"></button></li>
                    <li>Название организации <button className="btn-close"></button></li>
                </ul>*/}
                </FilterAccordion>

                <FilterAccordion title={"Специализация"} count={filters && filters.specializationId && filters.specializationId.length}>

                    <FiltersSpacializations filters={filters} selectFilter={selectFilter} checkIfActive={checkIfActive} specs={specializations} />
                </FilterAccordion>
                {showDates &&
                    <FilterAccordion title={"Сроки проведения"}>
                        <div className="choise-date">
                            <div className="flex gap-5">
                                <div className="flex gap-2">
                                    <label htmlFor="start" style={{fontSize:13}}>От</label>
                                    <input
                                        onChange={selectFilter}
                                        value={filters.startDate ? getDate(filters.startDate) : ""}
                                        data-type={"startDate"}
                                        className="form_field"
                                        name="startDate"
                                        type="datetime-local"
                                        placeholder="Введите дату начала"
                                        min="2018-01-01"/>
                                </div>
                                <div className="flex gap-2">
                                    <label htmlFor="end" style={{fontSize:13}}>До</label>
                                    <input
                                        onChange={selectFilter}
                                        value={filters.endDate ? getDate(filters.endDate)  :  ""}
                                        data-type={"endDate"}
                                        className="form_field"
                                        name="endDate"
                                        type="datetime-local"
                                        placeholder="Введите дату окончания"/>
                                </div>
                            </div>

                        </div>
                    </FilterAccordion>
                }
                {showSalary &&
                    <FilterAccordion title={"Заработная плата"}>
                        <div className="create-blk">
                            <div className=" flex  gap-5">
                                <div className={"input-block"}>
                                    <label htmlFor="From" style={{fontSize:13}}>От</label>
                                    <input
                                        data-type={"minSalary"}
                                        className="form_field"
                                        type="text" value={filters.minSalary}
                                        onChange={selectFilter} placeholder="0" id="From"/>
                                </div>
                                <div className={"input-block"}>
                                    <label htmlFor="To" style={{fontSize:13}}>До</label>
                                    <input
                                        data-type={"maxSalary"}
                                        className="form_field"
                                        type="text"
                                        value={filters.maxSalary} onChange={selectFilter}
                                        placeholder="0" id="To"/>
                                </div>
                            </div>

                        </div>
                    </FilterAccordion>
                }


                <FilterAccordion title={"Компетенции"}>
                    <FiltersCompetences filters={filters} selectFilter={selectFilter} checkIfActive={checkIfActive} specs={competencies} />
                </FilterAccordion>
                <FilterAccordion title={"Регион"}>
                    <CitiesListSelect refVal={filters.cityId} callback={selectCities}/>
                    {/* <FiltersCities filters={filters} selectFilter={selectFilter} checkIfActive={checkIfActive} cities={cities} />*/}
                </FilterAccordion>

                <div className="filter-btn">
                    {/*<button className="btn btn-blue">Применить</button>*/}
                    <button onClick={()=> {
                        setAuthorId(null)
                        refershFilters()
                    }} className="btn btn-grey">Сбросить всё</button>
                </div>
            </div>

        </div>
    )
}
export default React.memo(Filters)