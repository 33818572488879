const initialState = {
  user: {
    id: null,
    authed: false,
    status: null,
    accessToken: null,
    roles: [],
    type: null,
  },
};

const LOGIN_FAILURE = "LOGIN_FAILURE";
const LOGIN_SUCCESS = "LOGIN_SUCCESS";
const LOGOUT = "LOGOUT";
const RELOAD = "RELOAD";
const LOAD_USER_PROFILE = "LOAD_USER_PROFILE";
const UPDATE_USER_PROFILE = "UPDATE_USER_PROFILE";
const UPDATE_USER_CONTACT = "UPDATE_USER_CONTACT";
const CHANGE_CONTACT_LIST = "CHANGE_CONTACT_LIST";

export const userReducer = (state = initialState, action) => {
  switch (action.type) {
    case CHANGE_CONTACT_LIST:
      return {
        ...state,
        user: {
          ...state.user,
          contactPersonList: action.payload,
        },
      };
    case LOGIN_FAILURE:
      return {
        ...state,
        user: {
          ...state.user,
          id: null,
          authed: false,
          status: "BAD",
          accessToken: null,
          roles: [],
          type: null,
        },
      };
    case LOGIN_SUCCESS:
      return {
        ...state,
        user: {
          ...state.user,
          id: action.payload.id,
          authed: true,
          status: "OK",
          accessToken: action.payload.accessToken,
          roles: action.payload.roles,
          type: action.payload.type,
        },
      };
    case LOGOUT:
      return {
        ...state,
        user: {
          id: null,
          authed: false,
          status: null,
          accessToken: null,
          roles: [],
          type: null,
        },
      };
    case RELOAD:
      return {
        ...state,
        user: {
          ...state.user,
          id: action.payload.id,
          authed: action.payload.authed,
          status: action.payload.status,
          accessToken: action.payload.accessToken,
          roles: action.payload.roles,
          type: action.payload.type,
        },
      };
    case LOAD_USER_PROFILE:
      return {
        ...state,
        user: {
          ...state.user,
          ...action.payload,
        },
      };
    case UPDATE_USER_PROFILE: {
      return {
        ...state,
        user: {
          ...state.user,
          ...action.payload,
        },
      };
    }
    case UPDATE_USER_CONTACT:{
      let contacts = state.user.contactPersonList;
      let index = contacts.findIndex(contact => contact.contactPersonId === action.payload.contactPersonId);
      contacts[index] = {...action.payload.data};

      return  {
        ...state,
        user:{
          ...state.user,
          contactPersonList:contacts
        }
      }
    }
    default:
      return state;
  }
};
export const changeContactListAction= (payload) => ({ type: CHANGE_CONTACT_LIST,payload });
export const loginFailureAction = () => ({ type: LOGIN_FAILURE });
export const loginSuccessAction = (payload) => ({
  type: LOGIN_SUCCESS,
  payload,
});
export const logoutAction = () => ({ type: LOGOUT });
export const reloadAction = (payload) => ({ type: RELOAD, payload });
export const loadUserProfileAction = (payload) => ({
  type: LOAD_USER_PROFILE,
  payload,
});
export const updateUserProfileAction = (payload) => ({
  type: UPDATE_USER_PROFILE,
  payload,
});
export const updateUserContactListAction = (payload) => ({
  type: UPDATE_USER_CONTACT,
  payload,
});

