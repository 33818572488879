import "@assets/styles/components/product_item.scss";

import React, { useMemo, useState } from "react";
import { useSelector } from "react-redux";

import { useVariables } from "@hooks/useVariables";
import { useDownloadFile } from "@hooks/useDownloadFile";

import test_img from "@assets/images/avatar-default.png";
import defaultProduct from "@assets/images/product-default.png";

import { DOWNLOAD_FILE } from "@api/endpoints";

const ProductItem = ({ product }) => {
  // console.log("### ProductItem", product);
  const user = useSelector((state) => state.user.user);
  const isVendor = user.id === product.company.id;
  const dateOptions = useVariables().shortDateOptions;
  const [activeTab, setActiveTab] = useState(0);

  const tabs = useMemo(() => {
    const rawTabs = [
      {
        id: 0,
        name: "Основная информация",
      },
    ];

    // temporary hidden
    /*if (product.productStatus === PRODUCT_STATUS.publication) {
      rawTabs.push({
        id: 1,
        name: "Аукционы",
      });
    }*/

    return rawTabs;
  }, [product.productStatus]);

  const img = useDownloadFile(
    DOWNLOAD_FILE,
    product.imgId,
    defaultProduct,
  ).file;
  // console.log("### img", img);

  const getAuctionStatus = (auction) => {
    switch (auction?.auctionStatus) {
      case "UPCOMING":
        return {
          class: "opened",
          name: "Открыт",
        };
      case "PAST":
        return {
          class: "closed",
          name: "Закрыт",
        };
      default:
        return {
          class: "",
          name: "-",
        };
    }
  };

  return (
    <div className="product_item">
      <ul className="tab_list">
        {tabs.map((tab) => (
          <li
            key={tab.id}
            className={activeTab === tab.id ? "selected" : ""}
            onClick={() => setActiveTab(tab.id)}
          >
            {tab.name}
          </li>
        ))}
      </ul>

      {activeTab === 0 && (
        <>
          <div className="flex_block">
            <div className="image">
              <img src={img} alt="товар" />
            </div>
            <div className="main_info">
              <div className="shadow_block">
                <h2>{product.displayName}</h2>
                <div className="product_info">
                  <div>
                    <p className="info_title">Описание товара</p>
                    <p>{product.description}</p>
                  </div>
                  <div>
                    <p className="info_title">Организация</p>
                    <p>{product.company.name}</p>
                  </div>
                </div>
              </div>
              {product.auctions.map((auction) => (
                <div
                  key={auction.auctionId}
                  className="shadow_block auction_item"
                >
                  <div className="flex_header auction_title">
                    <h3>Информация об аукционе</h3>
                    <div className="bubble">
                      {getAuctionStatus(auction).name}
                    </div>
                  </div>
                  <div className="auction_info">
                    <div>
                      <p className="title">Даты проведения</p>
                      <p>
                        {`${new Date(auction.startDate).toLocaleString(
                          "ru",
                          dateOptions,
                        )} 
                        —
                        ${new Date(auction.endDate).toLocaleString(
                          "ru",
                          dateOptions,
                        )}`}
                      </p>
                    </div>
                    <div>
                      <p className="title">Начальная цена</p>
                      <p>{auction.startBid}</p>
                    </div>
                    <div>
                      <p className="title">Максимальная ставка</p>
                      <p>390</p>
                    </div>
                  </div>
                  <div className="secondary_button turquoise_button">
                    Участвовать
                  </div>
                </div>
              ))}
            </div>
          </div>
          <div className="buttons_block"></div>
        </>
      )}

      {activeTab === 1 && (
        <div className="flex_block">
          <div>
            <div className="image">
              <img src={product.img} alt="товар" />
            </div>
            <h2 className="product_name">{product.displayName}</h2>
          </div>
          <div className="main_info">
            <div className="shadow_block auction_item">
              <div className="flex_header auction_title">
                <h3>Информация об аукционе</h3>
                <div className="bubble">
                  {getAuctionStatus(product.auctions[0]).name}
                </div>
              </div>
              <div className="auction_info">
                <div>
                  <p className="title">Даты проведения</p>
                  <p>
                    {`${new Date(product.auctions[0]?.startDate).toLocaleString(
                      "ru",
                      dateOptions,
                    )} 
                      —
                    ${new Date(product.auctions[0]?.endDate).toLocaleString(
                      "ru",
                      dateOptions,
                    )}`}
                  </p>
                </div>
                <div>
                  <p className="title">Начальная цена</p>
                  <p>{product.auctions[0]?.startBid}</p>
                </div>
                <div>
                  <p className="title">Максимальная ставка</p>
                  <p>390</p>
                </div>
              </div>
            </div>
            <div className="bid_list">
              <div className="flex_header">
                <h3>Список сделанных ставок</h3>
                <span className="i-sort_after icon_text bold icon_large">
                  По размеру ставки
                </span>
              </div>
              <ul className="rating_list scroll_element">
                <li className="rating_item">
                  <div className="student_item">
                    <img className="avatar" src={test_img} alt="студент" />
                    <div className="student_info">
                      <p>Петров Петр Петрович</p>
                      <p>{"Прикладная информатика" || ""}</p>
                    </div>
                    <span className="points">213</span>
                  </div>
                </li>
                <li className="rating_item">
                  <div className="student_item">
                    <img className="avatar" src={test_img} alt="студент" />
                    <div className="student_info">
                      <p>Петров Петр Петрович</p>
                      <p>{"Прикладная информатика" || ""}</p>
                    </div>
                    <span className="points">213</span>
                  </div>
                </li>
                <li className="rating_item">
                  <div className="student_item">
                    <img className="avatar" src={test_img} alt="студент" />
                    <div className="student_info">
                      <p>Петров Петр Петрович</p>
                      <p>{"Прикладная информатика" || ""}</p>
                    </div>
                    <span className="points">213</span>
                  </div>
                </li>
                <li className="rating_item">
                  <div className="student_item">
                    <img className="avatar" src={test_img} alt="студент" />
                    <div className="student_info">
                      <p>Петров Петр Петрович</p>
                      <p>{"Прикладная информатика" || ""}</p>
                    </div>
                    <span className="points">213</span>
                  </div>
                </li>
                <li className="rating_item">
                  <div className="student_item">
                    <img className="avatar" src={test_img} alt="студент" />
                    <div className="student_info">
                      <p>Петров Петр Петрович</p>
                      <p>{"Прикладная информатика" || ""}</p>
                    </div>
                    <span className="points">213</span>
                  </div>
                </li>
              </ul>
            </div>
            <div className="shadow_block bid_place">
              <h3>Сделать ставку</h3>
              <div className="bid_info">
                <div>
                  <p className="icon_text i-up_right_after bold icon_small">
                    Об аукционе
                  </p>
                  <p>Правила и условия участия в аукционе</p>
                </div>
                <div className="field">
                  <input
                    className="form_field"
                    type="number"
                    min={product.auctions[0]?.startBid}
                    defaultValue={product.auctions[0]?.startBid}
                  />
                </div>
              </div>
              <button className="secondary_button turquoise_button">
                Оставить ставку
              </button>
            </div>
          </div>
        </div>
      )}
    </div>
  );
};

export default React.memo(ProductItem);
