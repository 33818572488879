export const SUBSCRIPTION_OPTIONS = [
  {
    value: "simple",
    label: "Простая",
    backgroundColor: "#3511C1",
    color: "#ffffff",
  },
  {
    value: "base",
    label: "Базовая",
    backgroundColor: "#2472FC",
    color: "#ffffff",
  },
  {
    value: "max",
    label: "Максимальная",
    backgroundColor: "#15FFC5",
    color: "#ffffff",
  },
];

// search
export const COMPANY_SEARCH_FIELDS = [
  {
    id: "1",
    label: "Название",
    name: "name",
    type: "text",
    placeholder: "Поиск по названию",
  },
  {
    id: "2",
    label: "БИК",
    name: "bik",
    type: "text",
    placeholder: "Поиск по БИК",
  },
  {
    id: "3",
    label: "ИНН",
    name: "inn",
    type: "text",
    placeholder: "Поиск по ИНН",
  },
  {
    id: "4",
    label: "ОГРН",
    name: "ogrn",
    type: "text",
    placeholder: "Поиск по ОГРН",
  },
  {
    id: "5",
    label: "Подписка",
    name: "subscription",
    type: "customSelect",
    options: SUBSCRIPTION_OPTIONS,
    placeholder: "Поиск по типу подписки",
    isMulti: false,
  },
  {
    id: "6",
    label: "ФИО",
    name: "personName",
    type: "text",
    placeholder: "Поиск по ФИО",
  },
  {
    id: "7",
    label: "Телефон",
    name: "phone",
    type: "phone",
    placeholder: "Поиск по телефону",
  },
  {
    id: "8",
    label: "E-mail",
    name: "email",
    type: "email",
    placeholder: "Поиск по E-mail",
  },
];
