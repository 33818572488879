import React, {useEffect, useState} from 'react';
import {useParams} from "react-router-dom";
import {loadOfferById, loadResponses, updateOffer} from "@store/asyncActions/offers";
import {toast} from "react-toastify";
import {useSavedUser} from "@hooks/useAuth";
import Table from "@pages/AdminCreateCareerPage/Tabs/Responses/Table";
import TableRow from "@pages/AdminCreateCareerPage/Tabs/Responses/TableRow";
import ModalCustom from "@components/ModalCustom";
import {useSelector} from "react-redux";
import {displayDateDDMMYYYY} from "@utils/dateFormat";
import {usePagination} from "@hooks/usePagination";
import {GET_ALL_EDU_DIRECTIONS_NEW, GET_EDU_DIRECTIONS, GET_RESPONSES_BY_OFFER_ID} from "@api/endpoints";
import {useVariables} from "@hooks/useVariables";
import {getAllEduDirections} from "@store/asyncActions/eduDirection";

const Responses = ({offer,updateOfferState}) => {
    const params = useParams();
    const savedUser = useSavedUser().savedUser;
    const {offerStatuses} = useSelector(state => state.offers);
    const [sort,setSort] = useState({offerId:"desc"});
    const requestOptionsGet = useVariables().requestOptionsGET;
    const [edus,setEdus] = useState([]);


    const reqGet = useVariables().requestOptionsGET
    //const [responses, setResponses] = React.useState([]);
    const responses = usePagination(GET_RESPONSES_BY_OFFER_ID,reqGet,`${Object.keys(sort)[0]},${Object.values(sort)[0]}`,null,offer.offerId)

    const [deleteModalActive, setDeleteModalActive] = useState(false);
    // operations modal content
    const [deleteContent, setDeleteContent] = useState({});
    useEffect(()=>{
           getAllEduDirections(savedUser).then(r=>{
               setEdus(r)
           })
    },[])
   /* useEffect(() => {
        if (params.careerId >= 0) {
            loadResponses(savedUser,params.careerId).then((data) => {
                setResponses(data.content)
            }).catch((e) => {
                console.log(e)
                toast.error("Ошибка при загрузке данных")
            })
        }
    }, [params.careerId])*/

    const archiveOffer = () => {
        setDeleteModalActive(true)
        setDeleteContent({name:offer.name})
    }
    const yesArchive = () => {
        setDeleteModalActive(false)
        let data = {
            ...offer,
            "offerStatus": "ARCHIVE",
        };
        if (params.careerId >= 0) {
            updateOffer(savedUser, data, params.careerId)
                .then((json) => {
                    updateOfferState("offerStatus",json.offerStatus)
                }).catch(() => {
                toast.error("Произошла ошибка", {
                    position: toast.POSITION.TOP_CENTER,
                });
            }).finally(() => {

            })
        }
    }
    return (
        <>

            <div className="edit-profile">
                <div className="tariff flex responses-blk">
                    <div className="tariff-title">
                        <h1>{offer && offer.name}</h1>
                        <span>Дата публикации {offer && offer.createdAt && displayDateDDMMYYYY(offer.createdAt)}</span>
                    </div>
                    <button disabled={!offer || offer.offerStatus === "ARCHIVE"}
                            onClick={offer && offer.offerStatus !== "ARCHIVE" ? archiveOffer : null}
                            className={`btn btn-blue ${offer && offer.offerStatus === "ARCHIVE"? "btn_disabled" : ""}`}>Архивировать</button>
                </div>
                <div
                    onClick={()=>{
                        setSort(prevState => {
                            return (prevState.offerId === "desc" ?
                                {offerId: "asc"} : {offerId: "desc"})
                        })
                    }}
                    className={`sorting ${sort.offerId === "desc"? "sorting-desc" : "sorting-asc"}`}>
                    <span>По дате отклика</span>
                </div>
                <Table>
                    {responses && responses.data && responses.data.map((response,index) =>
                        <TableRow index={index} refreshResponses={()=>responses.refresh()} edus={edus} key={response.id} item={response}/>)}
                </Table>
            </div>
            <ModalCustom
                title="Архивирование предложения"
                active={deleteModalActive}
                className={"delete_career"}
                setActive={setDeleteModalActive}
            >
                {deleteContent && (
                    <div className="delete_modal">
                        <p>Вы уверены, что хотите архивировать предложение "{deleteContent.name}"?</p>
                        <div className="button_block">
                            <button
                                className="reset_button"
                                onClick={() => setDeleteModalActive(false)}
                            >
                                Отмена
                            </button>
                            <button
                                className="submit_button"
                                onClick={() => yesArchive()}
                            >
                                Да
                            </button>
                        </div>
                    </div>
                )}
            </ModalCustom>
        </>
    )
}

export default React.memo(Responses);
