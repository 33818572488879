import { useEffect, useState } from "react";
import { create } from "zustand";
import { persist, createJSONStorage } from "zustand/middleware";

import { useVariables } from "./useVariables";

import stub from "../assets/images/avatar-default.png";

const useDownloadedFilesStore = create(
  persist(
    (set) => ({
      files: {},
      saveFile: (key, fileUrl) =>
        set((state) => {
          const now = new Date();

          return {
            ...state,
            files: { ...state.files, [key]: { url: fileUrl, createdAt: now } },
          };
        }),
    }),
    {
      name: "downloaded-files-storage",
      storage: createJSONStorage(() => localStorage),
    },
  ),
);

export const useDownloadFile = (
  fileUrl,
  fileId,
  defaultFile = stub,
  ignoreCache = false,
) => {
  const requestOptionsGET = useVariables().requestOptionsGET;
  const filePath = fileUrl + fileId;
  const { files, saveFile } = useDownloadedFilesStore();

  const [file, setFile] = useState(files[filePath]?.url || defaultFile);

  const downloadFile = () => {
    // console.log("### downloadFile", fileUrl + fileId);
    if (fileId) {
      fetch(fileUrl + fileId, requestOptionsGET)
        .then((response) => {
          // console.log("### response", response);
          if (!response.ok) {
            throw new Error("Ошибка при загрузке файла");
          }
          return response.blob();
        })
        .then((fileBlob) => {
          const fileUrl = URL.createObjectURL(fileBlob);
          // console.log("### fileUrl", fileUrl);

          setFile(fileUrl);
          saveFile(filePath, fileUrl);
        })
        .catch((error) => {
          console.error("Ошибка при загрузке файла:", error);
        });
    }
  };

  useEffect(() => {
    const previousFile = files[filePath];

    if (previousFile) {
      const { url, createdAt } = previousFile;
      const now = new Date();
      const timeDiff = now - createdAt;

      setFile(url);
    }

    downloadFile();
  }, [fileUrl, fileId, requestOptionsGET]);

  return {
    file,
  };
};
