import React, {useEffect, useState} from "react";
import styled from "styled-components";

import {useVariables} from "@hooks/useVariables";
import {useCompaniesStore} from "@pages/CompanyFromAdminPage/CompanyFromAdminPage";
import {useCreateCompanyModalStore} from "@pages/CompanyFromAdminPage/components/CompanyCreateEditModal";

import {GET_COMPANY_STATUSES, UPDATE_COMPANY_STATUS} from "@api/endpoints";
import {NavLink} from "react-router-dom";

const NEED_REVIEW = "NEED_REVIEW";
const APPROVED = "APPROVED";
const ARCHIVED = "ARCHIVED";

const mapStatusToLabel = {
    [NEED_REVIEW]: "Ожидает ревью",
    [APPROVED]: "Одобрено",
    [ARCHIVED]: "В архиве",
};

const CompaniesList = ({companies}) => {
    const statuses = {
        ACTUAL: <span className="type1">Опубликовано</span>,
        DRAFT: <span className="type4">Черновик</span>,
        ARCHIVE: <span className="type4">Архив</span>,
    };
    const subscribeTypes = {
        FREE: <span>Free</span>,
    }

    return (
        <tbody>
        {(!companies || !companies.length) &&
            <span>Нет компаний</span>
        }
        {companies && companies.map((company) => (
            <tr>
                <td>
                    <ul className="unstyled">
                        <li>
                            <NavLink  className="edit" to={`/admin/org-create/${company.companyId}`}></NavLink>
                        </li>
                       {/* <li><a className="delete"></a></li>*/}
                    </ul>
                </td>
                <td>{company.longName}</td>
                <td className="td-info">
                  <span>{(company.contactPersons && company.contactPersons.length) && (
                      `${company.contactPersons[0].secondName} ${company.contactPersons[0].firstName} ${company.contactPersons[0].middleName}`
                  )}</span>
                    <a href={(company.contactPersons && company.contactPersons.length && company.contactPersons[0].phoneNumber) && (company.contactPersons[0].phoneNumber)}>
                        {(company.contactPersons && company.contactPersons.length && company.contactPersons[0].phoneNumber) && (company.contactPersons[0].phoneNumber)}
                    </a>
                    <a href={(company.contactPersons && company.contactPersons.length) && (company.contactPersons[0].email)}>
                        {(company.contactPersons && company.contactPersons.length) && (company.contactPersons[0].email)}
                    </a>
                </td>
                <td data-label="ИНН:">{company.inn}</td>
                <td className="stat status1"><span>{company.subscriptionType}</span></td>
                <td className="status">{statuses[company.registrationStatus]}</td>
            </tr>
        ))}
        </tbody>
    );
};

export default React.memo(CompaniesList);