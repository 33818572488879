import "@assets/styles/pages/messenger.scss";
import React from "react";
import {useLocation, useNavigate} from "react-router-dom";

import useTyping from "./hooks/useTyping";
import useMessenger from "@hooks/useMessenger";

import Messages from "./components/Messages";
import MessageInput from "./components/MessageInput";
import ChatsPanel from "./components/ChatsPanel";
import {useSelector} from "react-redux";
import {useProfileStudent} from "@hooks/useProfileStudent";
import {CREATE_CHAT_WITH_ADMIN} from "@api/endpoints";
import {toast} from "react-toastify";
import {useVariables} from "@hooks/useVariables";
import {IsAdmin, useSavedUser} from "@hooks/useAuth";

const MessengerPage = () => {
    const isAdmin_ = IsAdmin();
    const {displayTyping, onTypingReceive} = useTyping();
    const user = useSelector((state) => state.user.user);
    const {studentData} = useProfileStudent(user.id);
    const requestGet = useVariables().requestOptionsGET;
    const {hash} = useLocation();
    const preselectedChat = hash ? hash.slice(1) : undefined;
    const navigate = useNavigate();
    const {
        selectChat,
        chats,
        selectedChat,
        sendTyping,
        sendMessage,
        sendReadMessage,
        fetchMessages,
        openChatWithAdmin,
        messagesLoading,
        setSearchFio,
    } = useMessenger({initialChatId: preselectedChat, onTypingReceive});
    const isCompanyContent = user.id !== studentData.student.id;
    const isStudentContent = user.id === studentData.student.id;

    return (
        <div className="messenger">
            <div className="container">
                <div className="left_block">
                    <div className="filter_block">
                        <input
                            className="form_field"
                            type="text"
                            name="name"
                            onChange={(e) => setSearchFio(e.target.value)}
                            placeholder="Поиск"
                        />
                        <button className="button gray_button">
                            <span className="i-messenger"/>
                        </button>
                    </div>

                    <ChatsPanel
                        chats={chats}
                        selectedChat={selectedChat}
                        selectChat={selectChat}
                    />
                    {!isAdmin_ &&
                        <div className="admin_message message" onClick={openChatWithAdmin}>
                            <div className="avatar"/>
                            <div className="message_info">
                                <p className="author">Администратор Полик</p>
                                <p className="message_content">
                                    Какой-то текст собщения от администратора платформы поликапитал,
                                    админа можно пригласить
                                </p>
                            </div>
                        </div>
                    }
                </div>

                <div className={selectedChat ? "right_block" : "right_block hidden"}>
                    <div className="header_dialog">
                        <p className="name_to">
                            {selectedChat?.chatId && selectedChat?.authors}
                        </p>
                        <p className="hidden_menu">
                            <span className="i-hidden_menu"/>
                        </p>
                        {displayTyping ? (
                            <div className="loading">{displayTyping.name} печатает...</div>
                        ) : null}
                    </div>

                    <Messages
                        selectedChat={selectedChat}
                        fetchMessages={fetchMessages}
                        messagesLoading={messagesLoading}
                        sendReadMessage={sendReadMessage}
                    />

                    <MessageInput
                        selectedChat={selectedChat}
                        sendTyping={sendTyping}
                        sendMessage={sendMessage}
                    />
                </div>
            </div>
        </div>
    );
};

export default MessengerPage;
