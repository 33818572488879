import "../assets/styles/components/custom_select.scss";
import React, {useEffect, useState} from "react";
import Select from "react-select";
import {toast, ToastContainer} from "react-toastify";

function CustomSelect({
                          scroll_id = "",
                          disabled = false,
                          parentStyle = {},
                          defaultVal = null,
                          autoFocus = false,
                          refValue = null,
                          loadDefault = null,
                          onlyClear = false,
                          parentClassName = "",
                          initialValue = "",
                          options,
                          name,
                          isMulti = false,
                          menuIsOpen,
                          onChange = null,
                          maxElements = null,
                          ...props
                      }) {
    const [value, setValue] = useState(initialValue);
    const [loaded,setLoaded] = useState(false);
    useEffect(() => {
        if (onlyClear) {
            if (!refValue || refValue.length === 0) {
                setValue([])
                return;
            }
        } else {
            if (refValue && refValue !== value) {
                setValue(refValue);
            }
        }


    }, [refValue]);

    useEffect(() => {
        if (loadDefault) {
            loadDefault().then(r => {
                setValue([r])
            })
        }
    }, [loadDefault])

    useEffect(() => {
        let initialValueIds = (initialValue || [])
            .map(({value}) => value)
            .sort()
            .join(",");

        let valueIds = value ? value : [];
        if (valueIds && Array.isArray(valueIds))
            valueIds.map(({value}) => value)
                .sort()
                .join(",");
        if (initialValue)
            if (initialValueIds !== valueIds && !loaded) {
                setValue(initialValue);
                setLoaded(true)
            }

    }, [initialValue]);


    const noOptionsMessage = () => "Не найдено";

    const handleChange = (newValue) => {
        if (isMulti) {
            if (
                (maxElements && value.length < maxElements) ||
                value.length > newValue.length ||
                maxElements === null
            ) {
                setValue(newValue);
                onChange?.(newValue ? newValue.map((el) => el.value) : "");
            } else {
                toast.error(`Возможен выбор не более чем ${maxElements} элемента`, {
                    position: toast.POSITION.TOP_CENTER,
                });
            }
        } else {
            setValue(newValue);
            onChange?.(newValue ? newValue.value : "");
        }
    };

    return (
        <div
            style={{...parentStyle}}
            className={props.isDisabled ? `custom_select disabled ${parentClassName}` : `custom_select ${parentClassName}`}
        >
            <Select
                id={scroll_id}
                autoFocus={autoFocus}
                value={value}
                options={options}
                classNamePrefix="select"
                isClearable
                isMulti={isMulti}
                closeMenuOnSelect={!isMulti}
                styles={styles}

                noOptionsMessage={noOptionsMessage}
                onChange={handleChange}
                menuIsOpen={menuIsOpen}
                {...props}
            />
            <input type="hidden" name={name} value={JSON.stringify(value)}/>
            <ToastContainer/>
        </div>
    );
}

export default CustomSelect;

const styles = {
    option: (styles, {data, isSelected}) => {
        return {
            ...styles,
            color: data.backgroundColor || "#333333",
            backgroundColor: isSelected ? "#CCE3E8" : undefined,
            ":active": {
                backgroundColor: "#CCE3E8",
            },
        };
    },
    multiValue: (styles, {data}) => {
        return {
            ...styles,
            backgroundColor: data.backgroundColor || "#49A9BF",
            color: data.color || "#ffffff",
        };
    },
    singleValue: (styles, {data}) => {
        return {
            ...styles,
            backgroundColor: data.backgroundColor || "#49A9BF",
            color: data.color || "#ffffff",
            width: "max-content",
            padding: "3px 7px",
            borderRadius: 10,
        };
    },
};
