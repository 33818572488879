import React, { useState } from "react";
import { toast, ToastContainer } from "react-toastify";
import styled from "styled-components";
import { CircularProgress } from "@mui/material";

import { useTeamInteraction } from "@hooks/useTeamInteraction";
import { useDownloadFile } from "@hooks/useDownloadFile";

import { DOWNLOAD_FILE } from "@api/endpoints";

const IncomingRequest = ({
  application,
  isStudent,
  isCompany,
  redirectToProfile,
  setTeamChanges,
  setApplicationsChanges,
}) => {
  const { rejectInvitation, approveInvitation } = useTeamInteraction();

  const applicationUser = isCompany ? application.student : application.company;

  const img = useDownloadFile(DOWNLOAD_FILE, applicationUser.avatarId).file;

  const [rejectClicked, setRejectClicked] = useState(false);
  const handleRejectClick = () => {
    setRejectClicked(true);

    rejectInvitation(application.id)
      .then((response) => {
        if (response.ok) {
          setApplicationsChanges(application.id);
          toast.success("Отклонено", {
            position: toast.POSITION.TOP_CENTER,
          });
        } else {
          throw response;
        }
      })
      .catch((error) => {
        toast.error("Ошибка отклонения", {
          containerId: "feedbackSaveError",
          position: toast.POSITION.TOP_CENTER,
        });
      })
      .finally(() => setRejectClicked(false));
  };

  return (
    <div className="student shadow_block" key={application.id}>
      <div className="student_info">
        <div className="user_picture">
          <img src={img} alt="student" />
        </div>
        <div className="name">
          {isCompany && (
            <>
              <p>{applicationUser.secondName}</p>
              <p>{applicationUser.firstName}</p>
              <p>{applicationUser.middleName}</p>
              <p className="additional">{applicationUser.course} курс</p>
            </>
          )}
          {isStudent && (
            <>
              <p>{application.company.name}</p>
              <p className="additional">{applicationUser.desc}</p>
            </>
          )}
        </div>
        <div className="menu_actions">
          <span className="i-hidden_menu menu_button">
            <div className="hidden_menu">
              <ul className="hidden_menu_content">
                <li onClick={() => redirectToProfile(applicationUser.id)}>
                  Перейти в цифровой профиль
                </li>
                <li>Связаться</li>
              </ul>
            </div>
          </span>
        </div>
      </div>
      <div className="inline_buttons">
        <button
          className="submit_button turquoise_button"
          onClick={() => {
            approveInvitation(application.id);
            setApplicationsChanges(application.id);
            setTeamChanges(application.id);
          }}
        >
          Принять
        </button>
        <button
          className="submit_button gray_button"
          disabled={rejectClicked}
          onClick={handleRejectClick}
        >
          {rejectClicked ? (
            <ButtonLabel>
              <StyledCircularProgress size={16} color={"primary"} />
              Отклонение
            </ButtonLabel>
          ) : (
            "Отклонить!"
          )}
        </button>
        {rejectClicked ? <ToastContainer /> : null}
      </div>
    </div>
  );
};

export default React.memo(IncomingRequest);

const StyledCircularProgress = styled(CircularProgress)`
  margin-right: 10px;
`;

const ButtonLabel = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
`;
