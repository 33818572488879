const initialState = {
    eduDirections: [
        {
            id: 0,
            name: "aaa",
            institute: {
                abbr: 'bbb'
            }
        }
    ]
};

const LOAD_DIRECTIONS = "LOAD_DIRECTIONS";
const ADD_DIRECTION = "ADD_DIRECTION";
const REMOVE_DIRECTION = "REMOVE_DIRECTION";
const EDIT_DIRECTION = "EDIT_DIRECTION";

export const eduDirectionReducer = (state = initialState, action) => {
    switch (action.type) {
        case LOAD_DIRECTIONS:
            return {...state, eduDirections: action.payload}
        case ADD_DIRECTION:
            return {...state, eduDirections: [...state.eduDirections, action.payload]}
        case REMOVE_DIRECTION:
            return {...state, eduDirections: state.eduDirections.filter(direction => direction.id !== action.payload)}
        case EDIT_DIRECTION:
            return {...state, eduDirections: state.eduDirections}
        default:
            return state
    }
}

export const loadEduDirectionsAction = (payload) => ({type: LOAD_DIRECTIONS, payload});
export const addEduDirectionAction = (payload) => ({type: ADD_DIRECTION, payload});
export const removeEduDirectionAction = (payload) => ({type: REMOVE_DIRECTION, payload});
export const editEduDirectionAction = (payload) => ({type: EDIT_DIRECTION, payload});
