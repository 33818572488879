import "../assets/styles/components/login_form.scss";

import React, {useEffect, useRef, useState} from "react";

import {Tooltip} from "react-tooltip";
import Checkbox from "./Checkbox";

import {politic, privacyPolicy} from "../constants/links";
import {useVariables} from "@hooks/useVariables";
import {RESET_PASSWORD_COMPANY, RESET_PASSWORD_STUDENT, SEND_FEEDBACK} from "@api/endpoints";
import {toast} from "react-toastify";
import {NavLink} from "react-router-dom";
import ButtonWithLoading from "@components/ButtonWithLoading";

const ResetPassword = ({
                           activeTab,back
                       }) => {
    const [acceptedPolicy, setAcceptedPolicy] = useState(false);
    const [sent, setSent] = useState(false)
    const [email, setEmail] = useState("");
    const requestPost = useVariables(email).requestOptionsPOST;
    const [error, setError] = useState("")
    const formRef = useRef();

    const config = params => {
        return {
            method: "POST",
            headers: {
                "Content-Type": "application/json",
            },
            body: JSON.stringify(params),
        };
    }
    const handlePolicyToggle = (target) => {
        setAcceptedPolicy(target.currentTarget.checked);
    };

    const sendReset = async (e) => {
        if(!acceptedPolicy)
        {
            toast.error("Для входа необходимо принять соглашения.", {
                position: toast.POSITION.TOP_CENTER,
            });
            return;
        }
        if (acceptedPolicy && email) {
            if (activeTab === 0) //STUDENT
            {
                let response = await fetch(RESET_PASSWORD_STUDENT, config({email}))
                    .catch((error) => {
                        setError("Ваш email не найден в системе, проверьте корректность введенных данных.")
                    })
                if (response.status === 400) {
                    setError("Ваш email не найден в системе, проверьте корректность введенных данных.")
                } else
                    setSent(true)
            }else{
                let response = await fetch(RESET_PASSWORD_COMPANY, config({login:email}))
                    .catch((error) => {
                        setError("Ваша компания не найдена в системе, проверьте корректность введенных данных.")
                    })
                if (response.status === 400) {
                    setError("Ваша компания не найден в системе, проверьте корректность введенных данных.")
                } else
                    setSent(true)
            }
            setEmail("")
        }else if (!email){
            setError(activeTab === 0 ? "Введите email" : "Введите ИНН")
        }

    }
    if (sent)
        return (
            <div className="login-content">
                <div className="login-content-block">
                    <div className="login-content-title popover-top">
                        <div className={"flex flex-start"}>
                            <a onClick={back} className="btn-back  btn-back__white"></a>
                            <h1>Восстановление пароля</h1>
                        </div>
                    </div>
                    <div className="sent-message">
                        <p>Данные для входа были успешно отправлены на {activeTab === 1 ? "корпоративную почту." : email}</p>
                    </div>
                    <div className="help-block">
                        <p>По всем вопросам пишите на почту <a
                            href="mailto:polykp@spbstu.ru">polykp@spbstu.ru</a></p>
                    </div>
                </div>
            </div>
        )
    return (
        <div className="login-content">
            <div className="login-content-block">
                <div className="login-content-title popover-top">
                    <div className={"flex flex-start"}>
                        <a onClick={back} className="btn-back  btn-back__white"></a>
                        <h1>Восстановление пароля</h1>
                    </div>

                    <div className="login-popover">
                        <div className="popover-blk">
                            <p>Данные для входа придут на вашу корпоративную почту.</p>
                        </div>
                    </div>
                </div>
                <form ref={formRef} onSubmit={(e)=>e.preventDefault()} className="login-form">
                    <div className={`login-form-blk ${error ? "form-error" : ""}`}>
                        <div className="form-blk">
                            <label
                                htmlFor="CorpMail">{activeTab === 1 ? "ИНН Компании" : "Корпоративная почта"}</label>
                            <input onFocus={()=>setError(false)} value={email} onChange={(e) => setEmail(e.target.value)} type="text"
                                   placeholder={activeTab ===  1?  "12345678910"  : "example@corpmail.com"} id="CorpMail"/>
                            {error &&
                                <label htmlFor="" className="error">{error}</label>
                            }
                        </div>
                    </div>
                    <label className="checks">
                        <input
                            checked={acceptedPolicy}
                            onChange={handlePolicyToggle}
                            className="checkbox" type="checkbox" name="checkbox-test"/>
                        <span className="checkbox-custom"></span>
                        <span className="label">Согласен(на) с <a href={privacyPolicy} target={"_blank"}>
                        Политикой конфиденциальности</a> и
                        <a href={politic} target={"_blank"}> Пользовательским соглашением</a></span>
                    </label>
                    <ButtonWithLoading
                        onClick={sendReset}
                        className={"btn btn-white"}
                        loadingStyle={{width:"100%"}}
                        spinnerLoadingStyle={{borderBottomColor:"black",height:30}}
                        type="button"
                        title={"Зарегистрироваться"}
                    />
                </form>
                <div className="help-block">
                    <p>По всем вопросам пишите на почту <a href="mailto:polykp@spbstu.ru">polykp@spbstu.ru</a>
                    </p>
                </div>
            </div>
        </div>
    );
};

export default ResetPassword;
