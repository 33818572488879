import "@assets/styles/pages/shop.scss";

import React, {useEffect, useMemo, useState} from "react";
import "@assets/newpages/style.scss"
import OfferType from "@pages/CreateCareerPage/components/offerType";
import {useSelector} from "react-redux";
import {GET_OFFERS_BY_USER_ID, GET_OFFERS_LIST} from "@api/endpoints";
import {setUserOffersAction} from "@store/reducers/offersReducer";
import {toast} from "react-toastify";
import {useSavedUser} from "@hooks/useAuth";
import Table from "@pages/EditProfilePage/sub-pages/EditCareer/components/Table";
import TableChildren from "@pages/EditProfilePage/sub-pages/EditCareer/components/TableChildren";
import ModalCustom from "@components/ModalCustom";
import {removeOffer} from "@store/asyncActions/offers";
import {usePagination} from "@hooks/usePagination";
import {useVariables} from "@hooks/useVariables";


const EditCareer = () => {

    const offers = useSelector(state => state.offers);
    const {savedUser} = useSavedUser();
    const [activeType, setActiveType] = useState();
    const [offersList, setOffersList] = useState([]);
    const [deleteModalActive, setDeleteModalActive] = useState(false);
    // operations modal content
    const [deleteContent, setDeleteContent] = useState({});
    const [filters,setFilters] = useState({name:"",authorId:savedUser.id,offerType:""});
    const [sort,setSort] =useState({offerId:"desc"});
    const requestOptionsGet = useVariables().requestOptionsGET;
    const offers_ = usePagination(GET_OFFERS_LIST,requestOptionsGet,`${Object.keys(sort)[0]},${Object.values(sort)[0]}`,null,filters,true)

    useEffect(()=>{
        if(sort && offers_.data)
            offers_.refresh();
    },[sort])

    const deleteCatalogItem = () =>{
        removeOffer(savedUser,deleteContent?.id).then(r=>{
            setDeleteModalActive(false);
            offers_.refresh();
        })
        /*setDeleteModalActive(false);
        let ofs = [...offersList];
        let index = ofs.findIndex(offer => offer.offerId === deleteContent?.id);
        ofs.splice(index, 1);
        setOffersList([...ofs]);*/
    }
    const removeItem = (offer) =>{
        setDeleteContent({name: offer.name, id: offer.offerId})
        setDeleteModalActive(true)
    }
    return (
        <>
            <OfferType activeType={filters.offerType } setActiveType={(offerType )=>setFilters({...filters,offerType })}/>

            <div className="search">
                <input onChange={(e)=>{ setFilters({...filters,name:e.target.value})}} value={filters?.name || ""} type="text" placeholder="Поиск по названию"/>
                <button type="submit"></button>
            </div>
            <div
                onClick={()=>{
                    setSort(prevState => {
                        return (prevState.offerId === "desc" ?
                             {offerId: "asc"} : {offerId: "desc"})
                    })
                }}
                className={`sorting ${sort.offerId === "desc"? "sorting-desc" : "sorting-asc"}`}>
                <span>По дате создания</span>
            </div>
            <Table>
                {offers_.data.map((offer,index)=>(
                    <TableChildren edit={true} onRemove={()=>removeItem(offer)} item={offer} key={index}/>
                ))}
            </Table>
            <ModalCustom
                title="Удаление элемента"
                className={"delete_career"}
                active={deleteModalActive}
                setActive={setDeleteModalActive}
            >
                {deleteContent && (
                    <div className="delete_modal">
                        <p>Вы уверены, что хотите удалить "{deleteContent.name}"?</p>
                        <div className="button_block">
                            <button
                                className="reset_button"
                                onClick={() => setDeleteModalActive(false)}
                            >
                                Отмена
                            </button>
                            <button
                                className="submit_button"
                                onClick={() => deleteCatalogItem()}
                            >
                                Да
                            </button>
                        </div>
                    </div>
                )}
            </ModalCustom>
        </>
    );
};

export default EditCareer;
