import React, {useEffect, useState} from "react"
import {usePagination} from "@hooks/usePagination";
import {GET_OFFERS_LIST} from "@api/endpoints";
import {useParams} from "react-router-dom";
import {useVariables} from "@hooks/useVariables";
import Table from "@pages/AdminCareersPage/components/Table";
import TableChildren from "@pages/AdminCareersPage/components/TableChildren";
import {removeOffer} from "@store/asyncActions/offers";
import ModalCustom from "@components/ModalCustom";
import {useSavedUser} from "@hooks/useAuth";


const Career = ()=>{
    const params = useParams();
    const {savedUser} = useSavedUser();
    const requestOptionsOutgoings =
        useVariables().requestOptionsGET;
    const [deleteModalActive, setDeleteModalActive] = useState(false);
    // operations modal content
    const [deleteContent, setDeleteContent] = useState({});
    const [filters,setFilters] = useState({authorId:params.organizationId,offerType:""});
    const [sort,setSort] = useState({offerId:"desc"});
    const [offerName,setOfferName] = useState("");
    const offers_ = usePagination(GET_OFFERS_LIST, requestOptionsOutgoings,`${Object.keys(sort)[0]},${sort.offerId}`,null,filters,true);
    const deleteCatalogItem = () =>{
        setDeleteModalActive(false);
        removeOffer(savedUser,deleteContent?.id).then(() => {
            offers_.refresh();
        })
    }

    const tabs = [
        {name:"Все",value:""},
        {name:"Вакансии",value:"VACANCY"},
        {name:"Стажировки",value:"INTERNSHIP"},
        {name:"Практика",value:"PRACTICE"},
    ]

    useEffect(()=>{
        offers_.refresh();
    },[sort])
    const removeItem = (offer) =>{
        setDeleteContent({name: offer.name, id: offer.offerId})
        setDeleteModalActive(true)

    }
    const submitFilters = () =>{
        setFilters({...filters,name: offerName})
    }
    return (
        <div className="active">
            <div className="edit-profile">
                <ul className="tag tags unstyled">
                    {tabs.map((tab,index)=>(
                        <li
                            onClick={()=>{
                                setFilters({...filters,offerType: tab.value})
                            }}
                            className={filters.offerType === tab.value? "active" : ""}>{tab.name}</li>
                    ))}
                </ul>
                <div className="search">
                    <input value={offerName} onChange={(e)=>setOfferName(e.target.value)} type="text" placeholder="Поиск по названию"/>
                        <button onClick={submitFilters}></button>
                </div>
                <div   onClick={()=>{
                    setSort(prevState => {
                        return (prevState.offerId === "desc" ?
                            {offerId: "asc"} : {offerId: "desc"})
                    })
                }}
                       className={`sorting ${sort.offerId === "desc"? "sorting-desc" : "sorting-asc"}`}>
                    <span>По дате создания</span>
                </div>
                <Table id={"offers-table"} orgId={params.organizationId} createFromOrg={true}>
                    {offers_ && offers_.data && offers_.data.map((offer,index)=>(
                        <TableChildren onRemove={()=>removeItem(offer)} item={offer} key={index}/>
                    ))}
                </Table>

            </div>
            <ModalCustom
                title="Удаление элемента"
                className={"autoWidth"}
                active={deleteModalActive}
                setActive={setDeleteModalActive}
            >
                {deleteContent && (
                    <div className="delete_modal">
                        <p>Вы уверены, что хотите удалить "{deleteContent.name}"?</p>
                        <div className="button_block">
                            <button
                                className="reset_button"
                                onClick={() => setDeleteModalActive(false)}
                            >
                                Отмена
                            </button>
                            <button
                                className="submit_button"
                                onClick={() => deleteCatalogItem()}
                            >
                                Да
                            </button>
                        </div>
                    </div>
                )}
            </ModalCustom>
        </div>
    )
}

export default Career