import React, { useEffect, useState, useRef } from "react";
import styled from "styled-components";

import { List, ListItem, ListItemButton, ListItemText } from "@mui/material";

import { GET_PROFESSIONS } from "@api/endpoints";
import { useVariables } from "@hooks/useVariables";

import SearchLine from "@components/SearchLine";
import Loader from "@components/Loader";

const PAGE_SIZE = 20;

const ProfessionsSelector = ({
  maxCount,
  searchRef,
  defaultUserProfessions,
  selectedProfessions,
  onChangeSelectedProfessions,
}) => {
  const [professions, setProfessions] = useState(selectedProfessions);
  useEffect(() => {
    onChangeSelectedProfessions(professions);
  }, [professions]);

  useEffect(() => {
    const selectedProfessionIdsStr = selectedProfessions
      .map(({ id }) => id)
      .sort()
      .join(",");
    const professionIdsStr = professions
      .map(({ id }) => id)
      .sort()
      .join(",");

    if (selectedProfessionIdsStr !== professionIdsStr) {
      setProfessions(selectedProfessions);
    }
  }, [selectedProfessions]);

  const defaultUserProfessionsId = defaultUserProfessions.map(
    (profession) => profession.id,
  );

  const userProfessionsId = professions.map((profession) => profession.id);

  const changeSelectedProfessions = (checked, profession) => {
    if (checked) {
      setProfessions((prevProfessions) => {
        if (prevProfessions.length <= maxCount) {
          return [...prevProfessions, { ...profession, userSelected: true }];
        }
        return prevProfessions;
      });
    } else {
      setProfessions((prev) => prev.filter(({ id }) => profession.id !== id));
    }
  };

  const [searchParam, setSearchParam] = useState({ name: null });
  const requestOptionsPOST = useVariables(searchParam).requestOptionsPOST;

  const [searching, setSearching] = useState(false);
  const [foundProfessions, setFoundProfessions] = useState([]);
  const [page, setPage] = useState(0);
  const [noMorePages, setNoMorePages] = useState();

  const getProfessions = (nextPage) => {
    console.log("### getProfessions", nextPage);
    setPage(nextPage);
    setSearching(true);

    fetch(GET_PROFESSIONS(page, PAGE_SIZE, "id"), requestOptionsPOST)
      .then((response) => response.json())
      .then((response) => {
        console.log(response);
        const { content, empty, first, last, size } = response;

        const filteredFromDefault = content.filter(
          ({ id }) => !defaultUserProfessionsId.includes(id),
        );

        setNoMorePages(last);

        setFoundProfessions((prevProfessions) =>
          first
            ? filteredFromDefault
            : [...prevProfessions, ...filteredFromDefault],
        );
      })
      .finally(() => {
        setSearching(false);
      });
  };

  useEffect(() => {
    getProfessions(0);
  }, [requestOptionsPOST]);

  const searchProfession = (value) => {
    value?.length > 3
      ? setSearchParam({ name: value })
      : setSearchParam({ name: null });
  };

  const searchProfessionInputChangeTimeout = useRef();
  const handleSearchProfessionChange = (value) => {
    if (searchProfessionInputChangeTimeout.current) {
      clearTimeout(searchProfessionInputChangeTimeout.current);
      searchProfessionInputChangeTimeout.current = null;
    }

    searchProfessionInputChangeTimeout.current = setTimeout(() => {
      searchProfessionInputChangeTimeout.current = null;

      searchProfession(value);
    }, 500);
  };

  const [smoothLoading, setSmoothLoading] = useState(false);

  useEffect(() => {
    if (searching === false) {
      setTimeout(() => setSmoothLoading(searching), 250);
    } else {
      setSmoothLoading(searching);
    }
  }, [searching]);

  const onScroll = (event) => {
    const element = event.target;

    if (
      element.scrollHeight - element.scrollTop === element.clientHeight &&
      !noMorePages
    ) {
      const nextPage = page + 1;
      getProfessions(nextPage);
    }
  };

  const handleProfessionToggle = (profession) => {
    const checked = userProfessionsId.indexOf(profession.id) !== -1;

    changeSelectedProfessions(!checked, profession);
  };

  return (
    <>
      <SearchLine
        placeholder="Поиск по профессиям"
        searchRef={searchRef}
        onChangeValue={handleSearchProfessionChange}
      />
      <FoundList className="variants_block">
        <List
          dense
          className="variants scroll_element"
          id="professions_list"
          onScroll={onScroll}
        >
          {foundProfessions.map((profession) => {
            const labelId = `checkbox-list-secondary-label-${profession.id}`;
            const selected = userProfessionsId.indexOf(profession.id) !== -1;

            return (
              <ListItem key={profession.id} disablePadding>
                <StyledListItemButton
                  selected={selected}
                  onClick={() => handleProfessionToggle(profession)}
                >
                  <StyledListItemText id={labelId} primary={profession.name} />
                  {selected ? <span className="i-close" /> : null}
                </StyledListItemButton>
              </ListItem>
            );
          })}

          {smoothLoading ? <Loader /> : null}

          {foundProfessions.length === 0 ? (
            <div className="empty"> Ничего не найдено :(</div>
          ) : null}
        </List>
      </FoundList>
    </>
  );
};

export default React.memo(ProfessionsSelector);

const FoundList = styled.div`
  margin-top: 10px;
`;
const StyledListItemButton = styled(ListItemButton)`
  border-radius: 8px;

  background-color: ${({ selected }) =>
    selected ? "#49A9BF" : "none"} !important;
  color: ${({ selected }) => (selected ? "white" : "none")} !important;

  &:hover {
    background-color: #49a9bf !important;
    color: white;
  }
`;

const StyledListItemText = styled(ListItemText)`
  padding-left: 10px;
`;
