import { useRef, useState } from "react";
import { useSelector } from "react-redux";

const useTyping = () => {
  const user = useSelector((state) => state.user.user);

  const [displayTyping, setDisplayTyping] = useState();
  const displayTypingTimeoutRef = useRef();
  const onTypingReceive = ({ userId, name }) => {
    if (user.id === userId) return;
    if (displayTypingTimeoutRef.current) {
      clearTimeout(displayTypingTimeoutRef.current);
    }
    setDisplayTyping({ userId, name });

    displayTypingTimeoutRef.current = setTimeout(() => {
      displayTypingTimeoutRef.current = null;
      setDisplayTyping(null);
    }, 2000);
  };

  return { displayTyping, onTypingReceive };
};

export default useTyping;
