import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { useSelector } from "react-redux";

import { usePagination } from "@hooks/usePagination";
import { useVariables } from "@hooks/useVariables";

import {
  GET_ALL_CATEGORIES,
  GET_ALL_COMPETENCIES,
  GET_RATING,
} from "@api/endpoints";

export const useRating = () => {
  const navigate = useNavigate();
  const user = useSelector((state) => state.user.user);

  const [searchParams, setSearchParams] = useState({ all: true });

  const requestOptionsPOST = useVariables(searchParams).requestOptionsPOST;
  const requestOptionsGET = useVariables().requestOptionsGET;

  const ratingData = usePagination(
    GET_RATING,
    requestOptionsPOST,
    "userId,asc",
  );
  const rating = ratingData.data;

  const [competencies, setCompetencies] = useState([]);
  const [categories, setCategories] = useState([]);

  const [isCustomSelectActive, setIsCustomSelectActive] = useState({
    competencies: false,
    polycoin: false,
  });

  const applyFilters = (filters) => {
    setSearchParams((prev) => {
      return {
        ...prev,
        filterRequest: filters,
      };
    });
  };

  const getCompetencies = () => {
    fetch(GET_ALL_COMPETENCIES, requestOptionsGET)
      .then((response) => response.json())
      .then((json) => {
        const options = makeOptionsForSelect("competencies", json);
        setCompetencies(options);
      })
      .catch(() => console.log("Произошла ошибка"));
  };

  const getCategories = () => {
    fetch(GET_ALL_CATEGORIES, requestOptionsGET)
      .then((response) => response.json())
      .then((json) => {
        const options = makeOptionsForSelect("categories", json);
        setCategories(options);
      })
      .catch(() => console.log("Произошла ошибка"));
  };

  const makeOptionsForSelect = (type, data) => {
    switch (type) {
      case "competencies":
        return data.map((el) => {
          return {
            label: el.competenceName,
            value: el.competenceId,
          };
        });
      case "categories":
        return data.map((el) => {
          return {
            label: el.name,
            value: el.engName,
          };
        });
      default:
        return [];
    }
  };

  useEffect(() => {
    getCompetencies();
    getCategories();
  }, []);

  const redirectStudentPage = (studentId) => {
    if (user.roles.includes("COMPANY")) {
      navigate(`../portfolio/${studentId}`);
    }
  };

  const changeCompetenceSortHandler = (type, value) => {
    switch (type) {
      case "competencies":
        setIsCustomSelectActive(
          value.length > 0
            ? { competencies: false, polycoin: true }
            : { competencies: false, polycoin: false },
        );
        return setSearchParams((prev) => {
          return value.length > 0
            ? { filterRequest: prev.filterRequest, competenceIds: value }
            : { filterRequest: prev.filterRequest, all: true };
        });
      case "categories":
        setIsCustomSelectActive(
          value.length > 0
            ? { polycoin: false, competencies: true }
            : { polycoin: false, competencies: false },
        );

        return setSearchParams((prev) => {
          return value.length > 0
            ? { filterRequest: prev.filterRequest, category: value }
            : { filterRequest: prev.filterRequest, all: true };
        });
      default:
        return [];
    }
  };

  return {
    rating,
    competencies,
    categories,
    applyFilters,
    redirectStudentPage,
    changeCompetenceSortHandler,
    isCustomSelectActive,
  };
};
