import React from 'react';
import vacancy_default from "@assets/images/vacancy_default.svg";
import internship_default from "@assets/images/internship_default.svg";
import {useDownloadFile} from "@hooks/useDownloadFile";
import {DOWNLOAD_FILE} from "@api/endpoints";
import {NavLink} from "react-router-dom";
import {offersLanguages, responseLanguages} from "@utils/languageList";
import StudentVacancyStatus from "@components/StudentVacancyStatus";


const StudentVacancyBlock = ({vacancy}) =>{
    const defaultImg =
        vacancy.type === "VACANCY" ? vacancy_default : internship_default;
    const img = useDownloadFile(
        DOWNLOAD_FILE,
        vacancy.vacancyId,
        defaultImg,
    ).file;

    const getVacancyStatus = (vacancy) => {
        switch (vacancy.type) {
            case "VACANCY":
                return {
                    name: "Вакансия",
                };
            case "INTERNSHIP":
                return {
                    name: "Стажировка",
                };
            default:
                return {
                    name: "-",
                };
        }
    };

    /*const handleClick = () => {
        onSelect({img, name: vacancy.name, description: vacancy.description});
    };*/

    return (
        <li className="list_item student-vacancy-block">
            <NavLink to={`/profile/view-career/${vacancy.offerId}`} className={"list_item"} >
                <div className="list_info newStyle">
                    <div className="vacancy-info ">
                        <div className="vacancy-name ">
                            <div className="create-blk">
                                <span className="name vacancy-name-list">{vacancy?.offerInfo?.name}</span>
                                <div className="create-blk">
                                    <div className="create-status_">
                                        {StudentVacancyStatus(vacancy?.jobResponseStatus)}

                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className={"td-tag"}>
                            <span className=" ">{offersLanguages.type[vacancy?.offerInfo?.offerType]}</span>

                        </div>
                    </div>
                    <p className="description">{vacancy.description}</p>
                </div>
            </NavLink>
        </li>
    );
}

export default React.memo(StudentVacancyBlock);