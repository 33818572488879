export const displayDateDDMMYYYY = (date) => {
    let d = typeof date === "string"? new Date(date) : date;
    //Возвращает дату в формате DD.MM.YYYY
    return checkZero(d.getDate()) + '.' + checkZero(d.getMonth() + 1) + '.' + d.getFullYear();

}

export const displayDateYYYY_MM_ddThh_mm = (date) => {
    let d = typeof date === "string"? new Date(date) : date;
    //Возвращает дату в формате yyyy-MM-ddThh:mm
    return d.getFullYear() + '-' + checkZero(d.getMonth() + 1) + '-' + checkZero(d.getDate()) + 'T' + checkZero(d.getHours()) + ':' + checkZero(d.getMinutes());
}
export const secondDateBigger = (date1,date2) => {
    //Проверяем что date1 < date2
    let d1 = typeof date1 === "string"? new Date(date1) : date1;
    let d2 = typeof date2 === "string"? new Date(date2) : date2;
    return d1 < d2;
}

//Метод для проверки месяца на наличие 0 в конце
export const checkZero = (month) => {
    return month < 10 ? '0' + month : month;
}