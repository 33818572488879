import React, { useEffect, useState } from "react";
import { Button, Dialog, DialogContent } from "@mui/material";

const BASE_VIDEO_WIDTH = 720;
const BASE_VIDEO_HEIGHT = 405;
const VIDEO_ASPECT_RATIO = BASE_VIDEO_HEIGHT / BASE_VIDEO_WIDTH;

const VideoDisplayButton = () => {
  const refreshVideoSizes = () => {
    const windowWidth = window.innerWidth;
    const windowHeight = window.innerHeight;

    if (windowWidth > windowHeight) {
      const windowAspectRatio = windowHeight / windowWidth;

      if (VIDEO_ASPECT_RATIO > windowAspectRatio) {
        const videoHeight = windowHeight - 50;
        const videoWidth = videoHeight / VIDEO_ASPECT_RATIO;

        setVideoSizes({
          width: videoWidth,
          height: videoHeight,
        });
      } else {
        const videoWidth = windowWidth - 50;
        const videoHeight = videoWidth * VIDEO_ASPECT_RATIO;

        setVideoSizes({
          width: videoWidth,
          height: videoHeight,
        });
      }
    } else {
      const videoWidth = windowWidth - 30;
      const videoHeight = videoWidth * VIDEO_ASPECT_RATIO;

      setVideoSizes({
        width: videoWidth,
        height: videoHeight,
      });
    }
  };

  useEffect(() => {
    refreshVideoSizes();
  }, []);

  useEffect(() => {
    const handleWindowResize = () => {
      setVideoModalOpened(false);
      refreshVideoSizes();
    };
    window.addEventListener("resize", handleWindowResize);
    return () => window.removeEventListener("resize", handleWindowResize);
  }, []);

  const [videoModalOpened, setVideoModalOpened] = useState(false);
  const openVideo = () => {
    setVideoModalOpened(true);
  };
  const [videoSizes, setVideoSizes] = useState();

  return (
    <>
      <button onClick={openVideo} className="btn btn-white">Видео о платформе</button>
      {/*<Button
        variant={"contained"}
        className="open_video_button"
        onClick={openVideo}
      >
        Смотри видео
      </Button>*/}

      <Dialog
        className={"video_modal"}
        open={videoModalOpened}
        onClose={() => setVideoModalOpened(false)}
      >
        <DialogContent>
          <iframe
            width={videoSizes?.width}
            height={videoSizes?.height}
            src="https://rutube.ru/play/embed/e57b2868e46cafd4ca4b026185418c21"
            frameBorder="0"
            allow="clipboard-write; autoplay"
            webkitAllowFullScreen
            mozallowfullscreen
            allowFullScreen
          ></iframe>
        </DialogContent>
      </Dialog>
    </>
  );
};

export default React.memo(VideoDisplayButton);
