import React from'react';
import {NavLink} from "react-router-dom";


const Table = ({children}) => {
    return (
        <table className="table table-profile">
            <thead>
            <tr>

                <th>Предложение</th>
                <th>Тип</th>
                <th>Статус</th>
                <th style={{textAlign:"center"}}>Предложение</th>
                <th></th>
            </tr>
            </thead>
            <tbody>
                {children}
            </tbody>
        </table>
    )

}
export default React.memo(Table);