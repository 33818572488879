import React from'react';
import BarChart from "@components/BarChart";


const Progress = ({studentData}) => {
    const activityPoints = studentData.activityPoints.map(el=>{
        return {
            name:el.activity.name,value:el.value,
            points:el.activityScore
        }
    });

    return(
        <div className="active">
            {activityPoints &&
                <BarChart maxValue={100} dataset={activityPoints} barHeight={"5px"} labels={true}/>

            }

        </div>
    )
}
export default Progress;