import React, { useEffect, useState } from "react";
import { create } from "zustand";
import styled from "styled-components";

import { useVariables } from "@hooks/useVariables";
import { useCompaniesStore } from "@pages/CompanyFromAdminPage/CompanyFromAdminPage";

import ModalCustom from "@components/ModalCustom";

import { CREATE_COMPANY, UPDATE_COMPANY } from "@api/endpoints";
import { toast } from "react-toastify";

export const useCreateCompanyModalStore = create((set) => ({
  visible: false,
  company: null,
  setVisible: (value) =>
    set((state) => ({
      ...state,
      visible: value,
      company: value ? state.company : null,
    })),
  show: (company) => set((state) => ({ ...state, visible: true, company })),
  hide: () => set((state) => ({ ...state, visible: false, company: null })),
}));

const CompanyCreateEditModal = () => {
  const { companies, setCompanies } = useCompaniesStore();
  const { visible, setVisible, hide, company } = useCreateCompanyModalStore();

  const editMode = !!company;
  console.log("### company", company);

  const [values, setValues] = useState(company);

  useEffect(() => {
    setValues(company);
  }, [company]);

  const constRequestOptionsPOST = useVariables().constRequestOptionsPOST;
  function addCompany(event) {
    event.preventDefault();
    const form = new FormData(event.target);
    const newCompany = {
      name: form.get("name"),
      bik: form.get("bik"),
      inn: form.get("inn"),
      ogrn: form.get("ogrn"),
      desc: form.get("desc") || "",
      contactPerson: {
        firstName: form.get("firstName"),
        middleName: form.get("middleName") || "",
        secondName: form.get("secondName"),
        phoneNumber: form.get("phoneNumber"),
        email: form.get("email"),
      },
    };
    event.target.reset();
    hide();

    fetch(
      company ? UPDATE_COMPANY(company.companyId) : CREATE_COMPANY,
      constRequestOptionsPOST(newCompany),
    )
      .then((response) => {
        console.log("### response", response);
        if (!response.ok) {
          throw new Error("Ошибка при загрузке файла");
        }
        return response.json();
      })
      .then((jsonData) => {
        console.log("### jsonData", jsonData);
        // if (jsonData.status === "Error") {
        //   return throw jsonData;
        // }

        if (editMode) {
          const indexOfCompany = companies.findIndex(
            ({ companyId }) => companyId === company.companyId,
          );
          if (typeof indexOfCompany !== "undefined") {
            companies[indexOfCompany] = jsonData;
            setCompanies([...companies]);
          }
        } else {
          setCompanies([...companies, jsonData]);
        }
      })
      .catch((error) => {
        console.log("Не удалось добавить компанию");
        toast.error("Не удалось добавить компанию", {
          position: toast.POSITION.TOP_CENTER,
        });
      });
  }

  const handleFormChange = (event) => {
    console.log(
      "### handleFormChange.event",
      event.target.name,
      event.target.value,
    );
    const { name, value } = event.target;

    setValues((state) => ({ ...state, [name]: value }));
  };

  return (
    <ModalCustom
      title={editMode ? "Редактирование компании" : "Добавление компании"}
      active={visible}
      setActive={setVisible}
    >
      <form method="POST" onSubmit={addCompany} onChange={handleFormChange}>
        <div className="object_info three_fraction">
          <div className="field">
            <label>Название</label>
            <input
              className="form_field"
              type="text"
              name="name"
              placeholder="Введите название"
              required
              value={values?.name}
            />
          </div>
          <div className="field">
            <label>БИК</label>
            <input
              className="form_field"
              type="text"
              name="bik"
              placeholder="Введите БИК"
              required
              value={values?.bik}
            />
          </div>
          <div className="field">
            <label>ИНН</label>
            <input
              className="form_field"
              type="text"
              name="inn"
              placeholder="Введите ИНН"
              required
              value={values?.inn}
            />
          </div>
          <div className="field">
            <label>ОГРН</label>
            <input
              className="form_field"
              type="text"
              name="ogrn"
              placeholder="Введите ОГРН"
              required
              value={values?.ogrn}
            />
          </div>
          <div className="field">
            <label>Описание</label>
            <input
              className="form_field"
              type="text"
              name="desc"
              placeholder="Введите описание"
              value={values?.desc}
            />
          </div>
          {!company ? (
            <>
              <div className="field">
                <label>Фамилия</label>
                <input
                  className="form_field"
                  type="text"
                  name="secondName"
                  placeholder="Введите фамилию"
                  required
                />
              </div>
              <div className="field">
                <label>Имя</label>
                <input
                  className="form_field"
                  type="text"
                  name="firstName"
                  placeholder="Введите имя"
                  required
                />
              </div>
              <div className="field">
                <label>Отчество</label>
                <input
                  className="form_field"
                  type="text"
                  name="middleName"
                  placeholder="Введите отчество"
                />
              </div>
              <div className="field">
                <label>Телефон</label>
                <input
                  className="form_field"
                  type="phone"
                  name="phoneNumber"
                  placeholder="Введите телефон"
                  required
                />
              </div>
              <div className="field">
                <label>E-mail</label>
                <input
                  className="form_field"
                  type="email"
                  name="email"
                  placeholder="Введите E-mail"
                  required
                />
              </div>
            </>
          ) : null}
        </div>
        <div className="button_block">
          <SaveButton
            type="submit"
            className="submit_button"
            value="Сохранить"
          />
        </div>
      </form>
    </ModalCustom>
  );
};

export default React.memo(CompanyCreateEditModal);

const SaveButton = styled.input`
  margin-top: 20px;
`;
