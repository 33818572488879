import {toast} from "react-toastify";
import {
    GET_OFFERS_TYPES,
    GET_OFFERS_LIST,
    CREATE_OFFER,
    GET_OFFERS_JOB_TYPE,
    GET_OFFERS_TIME_TYPES,
    GET_OFFERS_INFO,
    UPDATE_OFFER,
    GET_OFFERS_BY_USER_ID,
    GET_OFFERS_BY_ID,
    DELETE_OFFER,
    GET_CITY_BY_ID,
    GET_RESPONSES_BY_OFFER_ID,
    GET_COMPANIES_BY_NAME,
    GET_OFFERS_CITIES,
    GET_COMPANIES_BY_NAME_V2,
    GET_COMPANY_PROFILES_V2
} from "@api/endpoints";
import {
    setOffersListAction, setOffersReferenceAction, setUserOffersAction,
} from "../reducers/offersReducer";
import {usePagination} from "@hooks/usePagination";

export const loadUserOffers = (savedUser, type) => {
    const requestOptions = {
        method: "GET",
        headers: {
            Authorization: `${savedUser.type} ${savedUser.accessToken}`,
        },
    };
    return async (dispatch) => {
        try {
            const response = await (await fetch(GET_OFFERS_BY_USER_ID(savedUser.id, type), requestOptions)).json();
            dispatch(setUserOffersAction(response));
        } catch (error) {
            toast.error(error.message);
        }
    };
}

export const loadOffersReferenceInfo = (savedUser) => {
    const requestOptions = {
        method: "GET",
        headers: {
            Authorization: `${savedUser.type} ${savedUser.accessToken}`,
        },
    };
    return async (dispatch) => {
        try {
            const response = await (await fetch(GET_OFFERS_INFO, requestOptions)).json();
            dispatch(setOffersReferenceAction(response));
        } catch (error) {
            toast.error(error.message);
        }
    };
}
export const createOffer = async (savedUser, offer) => {
    let cfg = {
        method: "POST",
        headers: {
            "Content-Type": "application/json",
            Authorization: `${savedUser.type} ${savedUser.accessToken}`,
        },
        body: JSON.stringify(offer),
    };
    const response = await (await fetch(CREATE_OFFER, cfg)).json();
    return response;
}
export const loadCities  =  async (savedUser,name)  =>  {
    const requestOptions = {
        method: "GET",
        headers: {
            Authorization: `${savedUser.type} ${savedUser.accessToken}`,
        },
    };
    const response = await (await fetch(GET_OFFERS_CITIES(0,25,"",name), requestOptions)).json();
    return response;
}
export const removeOffer = async (savedUser, offerId) => {
    let cfg = {
        method: "DELETE",
        headers: {
            "Content-Type": "application/json",
            Authorization: `${savedUser.type} ${savedUser.accessToken}`,
        },
    };
    const response = await (await fetch(DELETE_OFFER(offerId), cfg)).json();
    return response;
}
export const getCityInfo = async (savedUser, cityId) => {
    let cfg = {
        method: "GET",
        headers: {
            "Content-Type": "application/json",
            Authorization: `${savedUser.type} ${savedUser.accessToken}`,
        },
    };
    const response = await (await fetch(GET_CITY_BY_ID(cityId), cfg)).json();
    return response;
}
export const updateOffer = async (savedUser, offer, offerId) => {
    let cfg = {
        method: "PUT",
        headers: {
            "Content-Type": "application/json",
            Authorization: `${savedUser.type} ${savedUser.accessToken}`,
        },
        body: JSON.stringify(offer),
    };
    const response = await (await fetch(UPDATE_OFFER(offerId), cfg)).json();
    return response;
}
export const loadOfferById = async (savedUser, offerId) => {
    const requestOptions = {
        method: "GET",
        headers: {
            "Content-Type": "application/json",
            Authorization: `${savedUser.type} ${savedUser.accessToken}`,
        },
    };
    const response = await (await fetch(GET_OFFERS_BY_ID(offerId), requestOptions)).json();
    return response;
}
export const loadAllCompanies = async (savedUser,size) => {
    const requestOptions = {
        method: "GET",
        headers: {
            Authorization: `${savedUser.type} ${savedUser.accessToken}`,
        },
    };
    const response = await(await fetch(GET_COMPANY_PROFILES_V2(0,size,""), requestOptions)).json();
    return response;
}
export const loadCompanies = async (savedUser,name="") => {
    const requestOptions = {
        method: "GET",
        headers: {
            Authorization: `${savedUser.type} ${savedUser.accessToken}`,
        },
    };
    const response = await(await fetch(GET_COMPANIES_BY_NAME_V2(name), requestOptions)).json();
    return response;
}
export const loadResponses = async (savedUser, offerId) => {
    const requestOptions = {
        method: "GET",
        headers: {
            Authorization: `${savedUser.type} ${savedUser.accessToken}`,
        },
    };
    const response = await(await fetch(GET_RESPONSES_BY_OFFER_ID(offerId), requestOptions)).json();
    return response;
}
export const loadOffersList = (savedUser, offerType) => {
    const requestOptions = {
        method: "GET",
        headers: {
            "Content-Type": "application/json",
            Authorization: `${savedUser.type} ${savedUser.accessToken}`,
        },
    };

    return async (dispatch) => {
        const response = await (await fetch(GET_OFFERS_LIST(offerType), requestOptions)).json();
        dispatch(setOffersListAction(response.content));
    };
    /* return async (dispatch) => {
       try {
         const response = await (await fetch(GET_OFFERS_LIST(), requestOptions)).json();
         dispatch(setOffersListAction(response.data));
       } catch (error) {
         toast.error(error.message);
       }
     };*/
}