import "../assets/styles/components/modal_custom.scss";
import { Dialog, DialogContent, DialogTitle } from "@mui/material";

const ModalCustom = ({ children,contentId="", title,onClose=()=>{}, active, setActive,className="" }) => (
  <Dialog
    className={`modal_custom ${className}`}
    open={active}
    onClose={() => {
      setActive(false)
      onClose();
    }}
    maxWidth="90%"
  >
    <DialogTitle className="modal_title">
      <span className="title">{title}</span>
      <span className="i-close close_button" onClick={() => setActive(false)} />
    </DialogTitle>
    <DialogContent id={contentId} className="modal_content scroll_element">
      {children}
    </DialogContent>
  </Dialog>
);

export default ModalCustom;
