import "../assets/styles/pages/page_from_admin.scss";

import { useEffect, useState } from "react";

import Search from "../components/Search";
import InputFile from "../components/InputFile";
import ModalCustom from "../components/ModalCustom";

const QUALIFICATION_OPTIONS = [
  {
    value: "bachelor",
    label: "Бакалавр",
    backgroundColor: "#3511C1",
    color: "#ffffff",
  },
  {
    value: "master",
    label: "Магистр",
    backgroundColor: "#2472FC",
    color: "#ffffff",
  },
  {
    value: "graduateStudent",
    label: "Аспирант",
    backgroundColor: "#15FFC5",
    color: "#ffffff",
  },
];

const STUDENT_SEARCH_FIELDS = [
  {
    id: "1",
    label: "ФИО",
    name: "name",
    type: "text",
    placeholder: "Поиск по ФИО",
  },
  {
    id: "2",
    label: "E-mail",
    name: "email",
    type: "email",
    placeholder: "Поиск по E-mail",
  },
  {
    id: "3",
    label: "Институт",
    name: "institute",
    type: "text",
    placeholder: "Поиск по институту",
  },
  {
    id: "4",
    label: "Образовательная программа",
    name: "eduProgram",
    type: "text",
    placeholder: "Поиск по программе",
  },
  {
    id: "5",
    label: "Направление",
    name: "eduDirection",
    type: "text",
    placeholder: "Поиск по направлению",
  },
  {
    id: "6",
    label: "Квалификация",
    name: "qualification",
    type: "customSelect",
    options: QUALIFICATION_OPTIONS,
    isMulti: true,
    placeholder: "Поиск по квалификации",
  },
  {
    id: "7",
    label: "Курс",
    name: "course",
    type: "text",
    placeholder: "Поиск по курсу",
  },
  {
    id: "8",
    label: "Статус",
    name: "status",
    type: "text",
    placeholder: "Поиск по статусу",
  },
];

function StudentFromAdminPage() {
  const [students, setStudents] = useState([]);
  const [filteredStudents, setFilteredStudents] = useState(students);

  //modals;
  const [addModalActive, setAddModalActive] = useState(false);

  // get student list
  useEffect(() => {
    fetch("http://localhost:8080/api/students/get")
      .then((response) => response.json())
      .then((jsonData) => {
        setStudents(jsonData);
        setFilteredStudents(jsonData);
      })
      .catch((error) => console.log("Не удалось загрузить список студентов"));
  }, []);

  function addStudent(event) {
    event.preventDefault();
    const form = new FormData(event.target);
  }

  function sendFile(event, url) {
    event.preventDefault();
    const form = new FormData(event.target);
    const sendData = new FormData();
    for (const [key, value] of form) {
      sendData.append("file", value, value.name);
    }
    const requestOptions = {
      method: "POST",
      headers: { "Content-Type": "multipart/form-data" },
      body: sendData,
    };
    fetch(url, requestOptions).catch((error) =>
      console.log("Не удалось загрузить файл на сервер"),
    );
  }

  return (
    <div className="page_from_admin">
      <div className="loaders">
        <div className="load_block">
          <h2>Выгрузка компетенций</h2>
          <form
            className="load_form"
            encType="multipart/form-data"
            onSubmit={(event) =>
              sendFile(event, "http://localhost:8080/api/competence/import")
            }
          >
            <InputFile name="competencies" accept=".csv" />
            <input className="submit_button" type="submit" value="Загрузить" />
          </form>
        </div>

        <div className="load_block">
          <h2>Выгрузка поликоинов</h2>
          <form
            className="load_form"
            encType="multipart/form-data"
            onSubmit={(event) =>
              sendFile(event, "http://localhost:8080/api/wallet/import")
            }
          >
            <InputFile name="polycoins" accept=".csv" />
            <input className="submit_button" type="submit" value="Загрузить" />
          </form>
        </div>
      </div>

      <Search
        fields={STUDENT_SEARCH_FIELDS}
        list={students}
        setFilteredList={setFilteredStudents}
      />

      <table className="list">
        <thead>
          <tr>
            <th>
              <div
                className="add_button"
                onClick={() => setAddModalActive(true)}
              ></div>
            </th>
            <th>ФИО</th>
            <th>E-mail</th>
            <th>Институт</th>
            <th>Образовательная программа</th>
            <th>Направление</th>
            <th>Квалификация</th>
            <th>Курс</th>
            <th>Статус</th>
          </tr>
        </thead>

        <tbody>
          {filteredStudents.length > 0 ? (
            filteredStudents.map((student) => (
              <tr key={student.userId} className="line">
                <td>
                  <div className="buttons">
                    <span className="control_button edit"></span>
                    <span className="control_button delete"></span>
                  </div>
                </td>
                <td>
                  {student.secondName} {student.firstName} {student.middleName}
                </td>
                <td>{student.email}</td>
                <td>{student.institute}</td>
                <td>{student.eduProgram}</td>
                <td>{student.eduDirection}</td>
                <td>{student.qualification}</td>
                <td>{student.course}</td>
                <td>{student.status}</td>
              </tr>
            ))
          ) : (
            <tr className="line">
              <td colSpan="9" className="empty_list">
                Список пуст...
              </td>
            </tr>
          )}
        </tbody>
      </table>

      <ModalCustom
        title="Добавление студента"
        active={addModalActive}
        setActive={setAddModalActive}
      >
        <form method="POST" onSubmit={addStudent}>
          <div className="object_info three_fraction">
            <div className="field">
              <label>Фамилия</label>
              <input
                className="form_field"
                type="text"
                name="firstName"
                placeholder="Введите фамилию"
              />
            </div>
            <div className="field">
              <label>Имя</label>
              <input
                className="form_field"
                type="text"
                name="lastName"
                placeholder="Введите имя"
              />
            </div>
            <div className="field">
              <label>Отчество</label>
              <input
                className="form_field"
                type="text"
                name="middleName"
                placeholder="Введите отчество"
              />
            </div>
            <div className="field">
              <label>СПБПУ логин</label>
              <input
                className="form_field"
                type="text"
                name="spbstuLogin"
                placeholder="Введите СПБПУ логин"
              />
            </div>
            <div className="field">
              <label>E-mail</label>
              <input
                className="form_field"
                type="email"
                name="email"
                placeholder="Введите E-mail"
              />
            </div>
            <div className="field">
              <label>Институт</label>
              <input
                className="form_field"
                type="text"
                name="institute"
                placeholder="Введите институт"
              />
            </div>
            <div className="field">
              <label>Группа</label>
              <input
                className="form_field"
                type="text"
                name="group"
                placeholder="Введите номер группы"
              />
            </div>
            <div className="field">
              <label>Направление</label>
              <input
                className="form_field"
                type="text"
                name="eduDirection"
                placeholder="Введите телефон"
              />
            </div>
            <div className="field">
              <label>Образовательная программа</label>
              <input
                className="form_field"
                type="text"
                name="eduProgram"
                placeholder="Введите программу"
              />
            </div>
            <div className="field">
              <label>Квалификация</label>
              <input
                className="form_field"
                type="text"
                name="qualification"
                placeholder="Введите квалификацию"
              />
            </div>
            <div className="field">
              <label>Статус</label>
              <input
                className="form_field"
                type="text"
                name="status"
                placeholder="Введите статус"
              />
            </div>
          </div>
          <div className="button_block">
            <input type="submit" className="submit_button" value="Сохранить" />
          </div>
        </form>
      </ModalCustom>
    </div>
  );
}

export default StudentFromAdminPage;
