import React, {useState, useEffect, useRef} from 'react';
import {NavLink} from "react-router-dom";
import {getDateInInput, validateEmail, validateGroupNumber} from "@utils/other";
import ValidateInput from "@components/ValidateInput";
import {toast} from "react-toastify";
import Loader from "@components/Loader";
import ModalCustom from "@components/ModalCustom";
import LoadModal from "@pages/AdminDatabasePage/components/LoadModal";

const EditList = ({data,sendList,back,loadToDB,fields,uploadThead}) => {
    const [activeModal,setActiveModal] = useState(false)
    const [allUsers, setAllUsers] = useState([]);
    const [load,setLoad] = useState(false);
    const [errorsUsers, setErrorsUsers] = useState([]);
    useEffect(() => {
        if (data && data.users) {
            setAllUsers(data.users);
            setErrorsUsers(data.users);
            console.log(data.users);
            /*let alUs = [...data.users].map((user,key)=>{
                return {...user, id: key+1}
            });
            const errUsr = [];
            /!*data.file.report.map(x=>{
                errUsr.push({...alUs[x.stringNumber - 1],messages:x.message});
                alUs[x.stringNumber-1] = null
            })
            alUs = alUs.filter(x => x);*!/
            setAllUsers(alUs);
            setErrorsUsers(errUsr);*/
        }
    }, [data])
    const getError = (field, value) => {
        if (field === "email" && !validateEmail(value))
            return {error: true, reason: "Введите корректный email"};
        else if(field === "group" && !validateGroupNumber(value))
            return {error: true, reason: "Пример поля - XXXXXXX/XXXXX"};
        else if (!value) return {error: true, reason: "Поле не может быть пустым"};

        else return {error: false, reason: ""}

    }
    const changeValue = (key, field, value) => {
        let us = [...errorsUsers];
        us[key]["problemObject"][field] = value;
        setErrorsUsers(us);
    }
    const saveList = (file,name) =>{
        if(file){

            sendList(file,name,data.file.jobId);
            back();
        }
       /* let errUs = [...errorsUsers];
        setErrorsUsers(null);
        let all = [...allUsers];
            let notEdited = errUs.filter(user => Object.values(user.problemObject).find(x => !x) || !validateEmail(user.problemObject.email) || !validateGroupNumber(user.problemObject.group));
            notEdited.map(item => {errUs.splice(errUs.indexOf(item), 1)});
            setAllUsers([...all,...errUs]);
            if(notEdited.length > 0){
                setErrorsUsers(notEdited);
                toast.error("Не все пользователи исправлены", {position: toast.POSITION.TOP_CENTER})
            }else{

                console.log(file)
                console.log(file)
                /!*sendList([...all,...errUs],data.file.jobId);
                toast.success("Отлично, список загружен на сервер.", {position: toast.POSITION.TOP_CENTER})
                back();*!/
            }*/

    }
    return (
        <div className="newStyle">
            <NavLink onClick={back} className="btn-back">Редактирование списка</NavLink>
            <div className="flex flex-col gap-15">

                <table className="tables edit-table">
                    <thead>
                    <tr>
                        <th style={{width:45}}>ID</th>
                        {uploadThead ? uploadThead.map((field, key) => (
                            <th key={key}>{field}</th>
                        )) :
                            <>
                                <th>Имя</th>
                                <th>Фамилия</th>
                                <th>Отчество</th>
                                <th>Группа</th>
                                <th>Email</th>
                                <th>Ошибки</th>
                            </>
                        }

                        {/*<th>Институт</th>
                        <th>Направление</th>
                        <th>Программа</th>
                        <th>Квалификация</th>*/}
                    </tr>
                    </thead>

                    <tbody  id={""} style={{maxHeight: 50,height:20}}>
                    {errorsUsers && errorsUsers.map((status, key) => {
                        let st = status;
                        status = st.problemObject;
                        if(!fields) {
                            return (
                                <>

                                    <tr key={key}>
                                        <td>
                                            #{key + 1}
                                        </td>
                                        <td>
                                            <ValidateInput
                                                disabled
                                                onChange={(e) => changeValue(key, "firstName", e.target.value)}
                                                defaultValue={status.firstName}
                                                placeholder={"Имя"}
                                                error_={getError("firstName", status.firstName).error}
                                                reason={getError("firstName", status.firstName).reason}
                                            />
                                        </td>
                                        <td>
                                            <ValidateInput
                                                disabled
                                                onChange={(e) => changeValue(key, "secondName", e.target.value)}
                                                defaultValue={status.secondName}
                                                placeholder={"Фамилия"}
                                                error_={getError("secondName", status.secondName).error}
                                                reason={getError("secondName", status.secondName).reason}
                                            />
                                        </td>
                                        <td>
                                            <ValidateInput
                                                disabled
                                                onChange={(e) => changeValue(key, "middleName", e.target.value)}
                                                defaultValue={status.middleName}
                                                placeholder={"Отчество"}
                                                error_={getError("middleName", status.middleName).error}
                                                reason={getError("middleName", status.middleName).reason}
                                            />
                                        </td>
                                        <td>
                                            <ValidateInput
                                                disabled
                                                onChange={(e) => changeValue(key, "group", e.target.value)}
                                                defaultValue={status.group}
                                                placeholder={"Группа"}
                                                error_={getError("group", status.group).error}
                                                reason={getError("group", status.group).reason}
                                            />
                                        </td>
                                        <td>
                                            <ValidateInput defaultValue={status.email}
                                                           disabled
                                                           onChange={(e) => changeValue(key, "email", e.target.value)}
                                                           error_={getError("email", status.email).error}
                                                           reason={getError("email", status.email).reason}
                                                           placeholder={"Email"}/>
                                        </td>
                                        {/* <td>
                                        <ValidateInput  defaultValue={status.institute}
                                                        onChange={(e) => changeValue(key, "institute", e.target.value)}
                                                        error_={getError("institute", status.institute).error}
                                                        reason={getError("institute", status.institute).reason}
                                                        placeholder={"Институт"}/>
                                    </td>
                                    <td>
                                        <ValidateInput  defaultValue={status.educationDirection}
                                                        onChange={(e) => changeValue(key, "educationDirection", e.target.value)}
                                                        error_={getError("educationDirection", status.educationDirection).error}
                                                        reason={getError("educationDirection", status.educationDirection).reason}
                                                        placeholder={"Направление"}/>
                                    </td>
                                    <td>
                                        <ValidateInput  defaultValue={status.educationProgram}
                                                        onChange={(e) => changeValue(key, "educationProgram", e.target.value)}
                                                        error_={getError("educationProgram", status.educationProgram).error}
                                                        reason={getError("educationProgram", status.educationProgram).reason}
                                                        placeholder={"Программа"}/>
                                    </td>
                                    <td>
                                        <ValidateInput defaultValue={status.qualification}
                                                       onChange={(e) => changeValue(key, "qualification", e.target.value)}
                                                       error_={getError("qualification", status.qualification).error}
                                                       reason={getError("qualification", status.qualification).reason}
                                                       placeholder={"Квалификация"}/>
                                    </td>*/}
                                        <td>
                                            {st.message.map(x => (
                                                <label htmlFor="" className="error">{x}</label>
                                            ))}

                                        </td>
                                    </tr>


                                </>
                            )
                        }
                        else{
                            return (
                                <tr>
                                    <td>
                                        #{key + 1}
                                    </td>
                                    {
                                        fields.map((field, key) => (
                                            <td>
                                                <ValidateInput
                                                    disabled
                                                    defaultValue={status[field]}
                                                    placeholder={uploadThead[key]}
                                                    value={uploadThead[key]}
                                                    error_={getError(field, status[field]).error}
                                                    reason={getError(field, status[field]).reason}
                                                />
                                            </td>
                                        ))
                                    }
                                </tr>
                            )
                        }
                    })}

                    </tbody>

                </table>
            </div>
            <ModalCustom
                title={"Перезапись"}
                active={activeModal}
                contentId={"logTable"}
                className={"autoWidth crateStudentModal newStyle"}
                setActive={setActiveModal}
            >
                <LoadModal uploadFields={fields} editList={false} loadToDB={saveList} setActiveModal={setActiveModal} />
            </ModalCustom>

            <div style={{marginTop:50}} className="modal-footer">
                <button onClick={()=>setActiveModal(true)} type="button" className="btn btn-blue modal-link"
                        data-modal="#avatarModal">Перезаписать
                </button>
            </div>
        </div>
    )

}
export default EditList;