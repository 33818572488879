import "../assets/styles/components/login_form.scss";

import React, { useState } from "react";

import { Tooltip } from "react-tooltip";
import Checkbox from "./Checkbox";

import {politic, privacyPolicy} from "../constants/links";
import {toast} from "react-toastify";
import {NavLink} from "react-router-dom";
import PasswordInput from "@components/organisms/PasswordInput";

const LoginForm = ({
                     setActiveBlock,
                     activeTab,
    setActiveTab,
  title = "",
  handlerFormSubmit,
  tooltipId = "id",
  tooltip = "",
  theme = "light",
}) => {
  const [acceptedPolicy, setAcceptedPolicy] = useState(false);
  const handlePolicyToggle = (e) => {
    setAcceptedPolicy(e.target.checked);
  };
  const handleSubmit = (e) => {
    e.preventDefault();
    if(!acceptedPolicy){
      toast.error("Для входа необходимо принять соглашения.", {
        position: toast.POSITION.TOP_CENTER,
      });
      return;
    }
    handlerFormSubmit(e);
  }


  return (
      <div className="login-content ">
        <div className="login-content-block">
          <div className="login-content-title">
            <Tooltip id={tooltipId} place="bottom" className="tooltip">
              {tooltip}
            </Tooltip>
            <h1>Вход</h1>
            <div className="login-popover">
              <div className="popover-blk">
                <p>Данные для входа придут на вашу корпоративную почту.</p>
              </div>
            </div>
          </div>
          <div className="tabs">
            <ul className="d-flex tab-menu">
              <li onClick={() => setActiveTab(0)} className={activeTab === 0 ?  "active" : ""}>Студент</li>
              <li onClick={()  => setActiveTab(1)} className={activeTab  ===  1 ?   "active"  :  ""}>Организация</li>
            </ul>
            <div className="tab-content">
              <div className="active">
                <form  onSubmit={handleSubmit} className="login-form">
                  <div className="login-form-blk">
                    <div className="form-blk">
                      <label htmlFor="Login">Логин</label>
                      <input name="login" required type="text" placeholder={activeTab === 0? "example@mail.com" : "ИНН"} id="Login"/>
                    </div>
                    <div className="form-blk">
                      <div className="flex">
                        <label htmlFor="Password">Пароль</label>
                        <a onClick={()=>setActiveBlock(3)}>Я не помню пароль</a>
                      </div>

                      <PasswordInput/>
                      {/*<div className="pass-input">
                        <input   name="password" required type="password" id="Password"/>
                        <i className="i-eye hidePass"></i>
                      </div>*/}

                    </div>
                  </div>
                  <label className="checks">
                    <input className="checkbox"

                           checked={acceptedPolicy}
                           onChange={handlePolicyToggle}
                           type="checkbox" name="checkbox-test"/>
                      <span className="checkbox-custom"></span>

                    <span className="label">Согласен(на) с <a href={privacyPolicy} target={"_blank"}>
                        Политикой конфиденциальности</a> и
                        <a href={politic} target={"_blank"}> Пользовательским соглашением</a></span>
                  </label>
                  <button className="btn btn-white">Войти</button>
                  <a style={{visibility: activeTab === 1 ? "visible" : "hidden",pointerEvents: activeTab ===  1 ? "auto" : "none"}} onClick={() => setActiveBlock(1)} className="create">Создать профиль</a>
                </form>
              </div>

            </div>
          </div>
          <div className="help-block">
            <p>По всем вопросам пишите на почту <a href="mailto:polykp@spbstu.ru">polykp@spbstu.ru</a></p>
          </div>
        </div>
      </div>
    /*<div className="login_form">
      <div className={`login_block ${theme}`}>
        <form className="login_form" onSubmit={handlerFormSubmit}>
          <Tooltip id={tooltipId} place="bottom" className="tooltip">
            {tooltip}
          </Tooltip>
          <h1>
            {title}
            <span className="i-hint" data-tooltip-id={tooltipId}></span>
          </h1>
          <div>
            <div className="field">
              <label>Логин</label>
              <input
                className="form_field"
                type="text"
                name="login"
                placeholder="ivanov.ii"
                autoComplete="username"
                required
              />
            </div>
            <div className="field">
              <label>Пароль</label>
              <input
                className="form_field"
                type="password"
                name="password"
                autoComplete="current_password"
                required
              />
            </div>
            <div className="checkbox_field">
              <Checkbox
                id={`policy_accept_${tooltipId}`}
                checked={acceptedPolicy}
                onChange={handlePolicyToggle}
              >
                Я принимаю политику конфиденциальности
              </Checkbox>
            </div>
          </div>
          <input
            className="submit_button"
            type="submit"
            name="sign_in"
            value="Войти"
            disabled={!acceptedPolicy}
          />
        </form>
        <div className="bottom_links">
          <p>
            По всем вопросам пишите на почту{" "}
            <a href="mailto: polykp@spbstu.ru">polykp@spbstu.ru</a>
          </p>
          <p>
            <a className="policy_link" href={privacyPolicy} target={"_blank"}>
              Политика конфиденциальности<span className="i-up_right"></span>
            </a>
          </p>
        </div>
      </div>
    </div>*/
  );
};

export default LoginForm;
