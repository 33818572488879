import React, {useEffect, useState} from "react";
import {loadCities} from "@store/asyncActions/offers";
import {useSavedUser} from "@hooks/useAuth";
import CustomSelect from "@components/CustomSelect";
import {useSelector} from "react-redux";



const CitiesListSelect = ({callback,loadDefault,refValue,isMulti=true,refVal}) => {
    const user = useSelector((state) => state.user.user);

    const [citiesList,setCitiesList] = useState([]);
    const [selectedCity,setSelectedCity] = useState(null);

    useEffect(()=>{
        if(refVal !== selectedCity){
            setSelectedCity(refVal);
        }
    },[refVal])

    const getCities = async (name ="") => {
        let cities = []; // to store api data
        let citiesRes = await loadCities(user,name);
        citiesRes.content.map((city)  =>  {
            cities.push({label: city.city, value: city.cityId})
        })
        setCitiesList(cities)
    };

    useEffect(()=>{
        getCities();
    },[])

    return (
        <CustomSelect
            loadDefault={loadDefault}
            autoFocus={false}
            onInputChange={(e)  =>  {
                getCities(e ? e  : "");
            }}
            parentStyle={{width:  "100%"}}
            refValue={refValue}
            onlyClear={true}
            placeholder={"Регион"}
            onChange={(e) => {
                setSelectedCity(e)
                callback(e)
                //setSelectedCity(e)
               /* setFilters({...filters, authorId: e})
                setAuthorId(e ? {label: companies.find((comp) =>  comp.id  === e).name, value: e} : null)*/
            }}
            isMulti={isMulti}
            options={citiesList ? citiesList : []}
        />
    )

}

export default React.memo(CitiesListSelect);

