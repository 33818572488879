import React from 'react';
import achievementIcon1 from "@assets/newpages/img/achievement-icon1.svg"
import achievementIcon2 from "@assets/newpages/img/achievement-icon2.svg"
import achievementIcon3 from "@assets/newpages/img/achievement-icon3.svg"
import achievementIcon4 from "@assets/newpages/img/achievement-icon4.svg"
import achievementIcon5 from "@assets/newpages/img/achievement-icon5.svg"

const Achievements = ({studentData}) => {
    const achievements = studentData.achievements;
    const icons = [
        achievementIcon1,
        achievementIcon2,
        achievementIcon3,
        achievementIcon4,
        achievementIcon5,
    ];
    return (
        <div className="active">
            <div className="indicator-achievement">
                <div className="swich-blk">
                    <label className="switch">
                        <input type="checkbox" className="toggleCheckbox"/>
                        <span className="slider"></span>
                    </label>
                    <span className="toggleLabel">График</span>
                </div>
                <table className="tables">
                    <thead>
                    <tr>
                        <th>Название</th>
                        <th><span>Количество</span></th>
                    </tr>
                    </thead>
                    <tbody>
                    {achievements.map((achievement, index) =>
                        <tr key={index}>
                            <td>
                                <div>
                                <span>
                                    <img src={icons[index] ? icons[index] : ""}
                                         alt={achievement.achievement.name}/>
                                </span>
                                    <p>{achievement.achievement.name}</p>
                                </div>
                            </td>
                            <td>{achievement.achievementScore}</td>
                        </tr>
                    )}

                    </tbody>
                </table>
            </div>
        </div>

    )

}
export default Achievements;