import React from "react";

import { useVariables } from "@hooks/useVariables";
import { usePagination } from "@hooks/usePagination";

import TeamMember from "./TeamMember";

import { GET_COMMAND } from "@api/endpoints";

const TeamMembers = ({
  teamChanges,
  isStudent,
  isCompany,
  redirectToProfile,
  setTeamChanges,
  setFeedbackModal,
  setFeedbackModalContent,
}) => {
  const requestOptionsTeam = useVariables(teamChanges).requestOptionsGET;
  const team = usePagination(GET_COMMAND, requestOptionsTeam).data;

  return (
    <div className="my_team">
      {team.length ? (
        team.map((user) => (
          <TeamMember
            key={user.id}
            user={user}
            redirectToProfile={redirectToProfile}
            isStudent={isStudent}
            isCompany={isCompany}
            setTeamChanges={setTeamChanges}
            setFeedbackModalContent={setFeedbackModalContent}
            setFeedbackModal={setFeedbackModal}
          />
        ))
      ) : (
        <div className="empty">Здесь пока пусто</div>
      )}
    </div>
  );
};

export default React.memo(TeamMembers);
