import React, {useEffect, useRef, useState} from 'react';
import {NavLink} from "react-router-dom";
import useClickOutside from "@hooks/useClickOutside";
import {useDownloadFile} from "@hooks/useDownloadFile";
import {DOWNLOAD_FILE} from "@api/endpoints";
import {changeOfferResponseStatus, changeOfferStatus} from "@store/asyncActions/offersResponses";
import {useSavedUser} from "@hooks/useAuth";
import {toast} from "react-toastify";
import {useSelector} from "react-redux";
import StudentVacancyStatus from "@components/StudentVacancyStatus";

const TableRow = ({index,item,edus,refreshResponses}) => {
    const user = useSelector(state=> state.user.user);
    const img = useDownloadFile(DOWNLOAD_FILE, item?.studentInfo?.avatarId).file;
    const [menu, setMenu] = React.useState(false);
    const [myEduDirection, setMyEduDirection]  =  useState(null);
    const menuRef  = useRef(null);
    menuRef.current = menu;
    const ref = useRef();
    const outSideClick = () =>{
        if(menuRef.current)
        {
            setMenu(false);
        }
    }
    useClickOutside(ref,outSideClick)
    useEffect(()=>{
        if(edus){
            let fEdu = edus.find(e=>e.id === item?.studentInfo?.eduDirectionId);
            if(fEdu)
                setMyEduDirection(fEdu.name);
        }
    },[edus]);

    useEffect(()=>{
        if(item && item.jobResponseStatus === "CONSIDERATION"){
            changeOfferResponseStatus(user,item?.jobResponseId,"VIEWED").then(r=>{})
        }
    },[item])

    const visibleStyle = {
        display: "flex",
        boxShadow: ["0px 2px 3px 0px rgba(0, 0, 0, 0.1)","0px 5px 10px 0px rgba(0, 0, 0, 0.1)"],
        border: "1px solid rgba(221, 221, 221, 1)",
        flexDirection: "column",
        position: "absolute",
        background: "white",
        right: 0,
        padding: 15,
        borderRadius: 20,
    }

    const changeStatus = (status) =>{
        setMenu(false)
        changeOfferResponseStatus(user,item?.jobResponseId,status).then(r=>{

            if(r.status && r.status === "Error"){
                toast.error("Ошибка."+ r.message, {
                        position: toast.POSITION.TOP_CENTER,
                    });
            }else{
                refreshResponses();
                if(status !== "REJECTED_BY_COMPANY")
                    toast.success("Вы утвердили этого студента.", {
                        position: toast.POSITION.TOP_CENTER,
                    });
                else
                    toast.success("Вы отозвали отклик", {
                        position: toast.POSITION.TOP_CENTER,
                    });
            }

        })
    }
    const disableButton = ["CANCELED_BY_STUDENT","APPROVED","REJECTED_BY_COMPANY"]

    return (

        <tr className="active">
            <td>
                <NavLink  to={`/profile/portfolio/${item.studentInfo?.studentId}`} end>
                <div className="td-responses ">
                    <span className="number">{index+1}</span>
                    <img
                        style={{ visibility: img ? "visible" : "hidden" }}
                        className="avatar"
                        src={img}
                        alt="студент"
                    />
                    <div>
                        <span>{myEduDirection}</span>
                        <p>{item?.studentInfo?.secondName} {item?.studentInfo?.firstName} {item?.studentInfo?.middleName}</p>
                    </div>
                </div>
                </NavLink>
            </td>
            <td data-label="Поликоины:">
                <span>{item?.studentInfo?.polycoins}</span>
            </td>
            <td className="status">
                <div className="flex">
                    <button disabled={disableButton.includes(item?.jobResponseStatus)} className={`btn btn-blue ${disableButton.includes(item?.jobResponseStatus) ? 
                        "btn-confirmed" : ""}`}
                    onClick={()  => changeStatus("APPROVED")}
                    >{item?.jobResponseStatus === "APPROVED" ? "Вы уже утвердили этого студента." : "Утвердить"}</button>
                    {StudentVacancyStatus(item?.jobResponseStatus)}
                    <button onClick={() => setMenu(prevMenu => !prevMenu)} className="td-more"></button>
                </div>
                <ul ref={ref} style={menu ? visibleStyle : {}} className="unstyled td-more-info">
                    <li>
                        <NavLink  to={`/profile/portfolio/${item.studentInfo?.studentId}`} end>
                            Перейти в профиль студента
                        </NavLink>
                    </li>
                    {item?.jobResponseStatus !== "REJECTED_BY_COMPANY" && item.jobResponseStatus !== "APPROVED" &&
                        <li>

                            <NavLink onClick={()  => changeStatus("REJECTED_BY_COMPANY")} end>
                                {item?.jobResponseStatus  ===  "APPROVED" ?
                                    "Отозвать приглашение":"Отозвать отклик"
                                }

                            </NavLink>
                        </li>
                    }

                   {/* <li>
                        <NavLink to={"#"} end>
                            Отозвать отклик
                        </NavLink>
                    </li>*/}
                </ul>
            </td>
        </tr>
    )
}


export default React.memo(TableRow);