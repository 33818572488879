import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";

import { useDownloadFile } from "@hooks/useDownloadFile";

import { DOWNLOAD_FILE } from "@api/endpoints";

const RatingListItem = ({ el, index, directions, onClick }) => {
  const user = useSelector((state) => state.user.user);
  const [avatar, setAvatar] = useState();
  const img = useDownloadFile(DOWNLOAD_FILE, el.avatarId).file;
  useEffect(() => {
    if (user.id === el.id && user.newAvatar) {
      setAvatar(user.newAvatar);
    } else {
      setAvatar(img);
    }
  }, [img]);

  return (
    <li className="rating_item" onClick={onClick}>
      <span className="number">{index + 1}</span>
      <div className="student_item">
        <img
          style={{ visibility: img ? "visible" : "hidden" }}
          className="avatar"
          src={avatar}
          alt="студент"
        />
        <div className="student_info">
          <p>{`${el.secondName} ${el.firstName} ${el.middleName}`}</p>
          <p>{directions?.[0] || ""}</p>
        </div>
        <span className="points">{el.points}</span>
      </div>
    </li>
  );
};

export default React.memo(RatingListItem);
