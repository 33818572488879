import React, { useState } from "react";

import BubbleButton from "@components/BubbleButton";

import { PRODUCT_STATUS } from "@constants/products";

const FiltersBlock = ({ opened, onClose, onFilterChange }) => {
  const [filter, setFilter] = useState();
  const handleChange = (e) => {
    setFilter(e.target.value);
  };
  const handleSubmit = (e) => {
    console.log("### handleSubmit", filter);
    e.preventDefault();
    onFilterChange(filter);
  };

  const handleReset = (e) => {
    console.log("### handleReset");
    setFilter(undefined);
    onFilterChange(undefined);
  };

  return (
    <form
      className={opened ? "filter_block opened" : "filter_block"}
      onChange={handleChange}
      onSubmit={handleSubmit}
      onReset={handleReset}
    >
      <div className="filter_header">
        <h2>Фильтры</h2>
        <p className="i-right_xs_after collapse_button" onClick={onClose}>
          <span>Свернуть</span>
        </p>
      </div>

      <div className="filter_item">
        <h3>Статус</h3>
        <div className="bubble_container">
          <BubbleButton name="item_status" id="1" value={PRODUCT_STATUS.draft}>
            Черновик
          </BubbleButton>
          <BubbleButton
            name="item_status"
            id="2"
            value={PRODUCT_STATUS.needReview}
          >
            На рассмотрении
          </BubbleButton>
          <BubbleButton
            name="item_status"
            id="3"
            value={PRODUCT_STATUS.publication}
          >
            Опубликован
          </BubbleButton>
          <BubbleButton
            name="item_status"
            id="4"
            value={PRODUCT_STATUS.archive}
          >
            Архив
          </BubbleButton>
        </div>
      </div>

      <div className="button_block">
        <input
          className="main_button turquoise_button"
          type="submit"
          value="Применить"
        />
        <input
          className="main_button gray_button"
          type="reset"
          value="Сбросить все"
        />
      </div>
    </form>
  );
};

export default React.memo(FiltersBlock);
