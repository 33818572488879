import {
  loadInstitutesAction,
  removeInstituteAction,
  addInstituteAction,
} from "../reducers/instituteReducer";

import {
  ADD_INSTITUTE, ADMIN_EDUCATION,
  LOAD_INSTITUTE,
  REMOVE_INSTITUTE,
} from "@api/endpoints";

const cfgGet = (user) => {
  return {
    method: "GET",
    headers:
    {
      Authorization: `${user.type} ${user.accessToken}`,
    },
  }
};

export const loadInstitutes = (user) => {
  return (dispatch) => {
    fetch(ADMIN_EDUCATION.LOAD_INSTITUTES,cfgGet(user))
      .then((response) => response.json())
      .then((json) => dispatch(loadInstitutesAction(json)))
      .catch(() => console.log("Не удается подключиться к серверу"));
  };
};

export const addInstitute = (payload) => {
  const requestOptions = {
    method: "POST",
    headers: { "Content-Type": "application/json" },
    body: JSON.stringify({ ...payload }),
  };
  return (dispatch) => {
    fetch(ADD_INSTITUTE, requestOptions)
      .then((response) => response.json())
      .then((jsonData) => dispatch(addInstituteAction(jsonData)))
      .catch(() => console.log("Не удается добавить данные"));
  };
};

export const removeInstitute = (payload) => {
  return (dispatch) => {
    fetch(REMOVE_INSTITUTE(payload))
      .then((response) => response.json())
      .then((jsonData) => dispatch(removeInstituteAction(payload)))
      .catch(() => console.log("Не удается удалить объект"));
  };
};
