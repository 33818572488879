import React, {useEffect, useRef, useState} from "react"
import CustomSelect from "@components/CustomSelect";
import {studentsLanguages} from "@utils/languageList";
import {usePagination} from "@hooks/usePagination";
import {ADMIN_STUDENTS, DOWNLOAD_FILE, GET_PROFESSIONS_V2, STUDENT_INFORMATION} from "@api/endpoints";
import {useVariables} from "@hooks/useVariables";
import {usePreviewFile} from "@hooks/usePreviewFile";
import ModalCustom from "@components/ModalCustom";
import CropModalContent from "@components/organisms/ProfileEditor/components/CropModalContent";
import {useDownloadFile} from "@hooks/useDownloadFile";
import ButtonWithLoading from "@components/ButtonWithLoading";
import {useLocation} from "react-router-dom";


const Information = ({info,save,user,setSelectedAvatar,selectedAvatar}) => {
    const formRef = useRef();
    const stateEmail = useLocation().state?.email
    const [avatarCropModalActive, setAvatarCropModalActive] = useState(false);
    const [selectedAvatarImage, setSelectedAvatarImage] = useState();
    const img = useDownloadFile(DOWNLOAD_FILE, user?.avatarId).file;

    const [limit,setLimit] = useState({size:1000})
    const requestGet = useVariables().requestOptionsGET;
    const institutes = usePagination(STUDENT_INFORMATION.getInstitutes,requestGet,"",null,limit).data
    const [eduDirFilters,setEduDirFilters] = useState({name:"fu"});
    const educationDirections = usePagination(STUDENT_INFORMATION.getEducationDirections,requestGet,"",null,limit).data
    const educationPrograms = usePagination(STUDENT_INFORMATION.getEducationPrograms,requestGet,"",null,limit).data
    const qualifications = usePagination(STUDENT_INFORMATION.getQualifications,requestGet,"").data
    const professions = usePagination(GET_PROFESSIONS_V2, requestGet, "",null,limit).data;




    const handleAvatarFileChange = (e) => {
        const file = e.target.files[0];
        if (file && file.type.match("image.*")) {
            const reader = new FileReader();
            reader.onload = (e) => {
                setSelectedAvatarImage({url: e.target.result});
            };
            reader.readAsDataURL(file);
        }
    };
    useEffect(() => {
        if (selectedAvatarImage) {
            setAvatarCropModalActive(true);
        }
    }, [selectedAvatarImage]);

    const [croppedImage, setCroppedImage] = useState();
    useEffect(() => {
        if (croppedImage) {
            setSelectedAvatar(croppedImage);
        }
    }, [croppedImage]);
    return (
        <div className="student-blks active">
            <div className="student-info-block">
                <div className="add-photo add-photo-org">
                    <span>Выберите файл или перетащите сюда</span>
                    <div className="chosen_avatar">
                        {((selectedAvatar) ||  (img && user?.avatarId)) &&
                            <img src={selectedAvatar ? selectedAvatar : img} alt="avatar" />
                        }
                    </div>
                    <input
                        name="avatar"
                        accept="image"
                        value={""}
                        onChange={handleAvatarFileChange} type="file" title="Выберите файл или перетащите сюда"/>
                </div>
                <div className="student-info-blk">
                    <form ref={formRef} onSubmit={save} className="search-form">
                        <div className="flex">
                            <div className="search-blk create-blk">
                                <label htmlFor="Name">Фамилия</label>
                                <input defaultValue={user?.secondName} type="text" placeholder="Иванов" name="secondName"/>
                            </div>
                            <div className="search-blk create-blk">
                                <label htmlFor="Name">Имя</label>
                                <input defaultValue={user?.firstName} type="text" placeholder="Иван" name="firstName"/>
                            </div>
                            <div className="search-blk create-blk">
                                <label htmlFor="Name">Отчество</label>
                                <input defaultValue={user?.middleName} type="text" placeholder="Иваночич" name="middleName"/>
                            </div>
                            <div className="search-blk create-blk">
                                <label htmlFor="Mail">Почта</label>
                                <input defaultValue={user?.email || stateEmail || ""} type="text" placeholder="example@gmail.com" name="email"/>
                            </div>
                            <div className="search-blk create-blk">
                                <label htmlFor="Group">Номер группы</label>
                                <input defaultValue={user?.group} type="text" placeholder="00000/00000" name="group"/>
                            </div>
                            {info && info.studentStatuses &&
                                <div className="search-blk create-blk">
                                    <label htmlFor="Status">Статус</label>
                                    <CustomSelect
                                        placeholder={"Статус"}
                                        refValue={user && user.status && {label:studentsLanguages.studentStatuses[user.status],value: user.status}}
                                        name="status"
                                        isMulti={false}
                                        options={info?.studentStatuses.map((item) => {
                                            return {label: studentsLanguages.studentStatuses[item], value: item}
                                        })}
                                    />
                                </div>
                            }

                            <div className="search-blk create-blk">
                                <label htmlFor="Level">Уровень получаемого образования</label>
                                <CustomSelect
                                    placeholder={"Уровень образования"}
                                    /*refValue={activities && activityId && activities.length ? getDefaultActivity() : null}*/
                                    onChange={(e) => {

                                    }}
                                    refValue={user && user.qualification && {label:user.qualification.name,value: user.qualification.qualificationId}}
                                    name="qualificationId"
                                    isMulti={false}
                                    options={qualifications.map((item) => {
                                        return {label: item.name, value: item.qualificationId}
                                    })}
                                />
                            </div>
                            <div className="search-blk create-blk">
                                <label htmlFor="Profession">Профессия</label>
                                <CustomSelect
                                    placeholder={"Профессия"}
                                    /*refValue={activities && activityId && activities.length ? getDefaultActivity() : null}*/
                                    onChange={(e) => {

                                    }}
                                    name="professionId"
                                    isMulti={true}
                                    refValue={user && user.profession && {label:user.profession.name,value: user.profession.professionId}}
                                    options={professions.map((item) => {
                                        return {label: item.name, value: item.id}
                                    })}
                                />
                            </div>
                            <div className="search-blk create-blk">
                                <label htmlFor="Institute">Институт</label>
                                <CustomSelect
                                    placeholder={"Институт"}
                                    /*refValue={activities && activityId && activities.length ? getDefaultActivity() : null}*/
                                    onChange={(e) => {

                                    }}
                                    refValue={user && user.institute && {label:user.institute.name,value: user.institute.instituteId}}
                                    name="instituteId"
                                    isMulti={false}
                                    options={institutes.map((item) => {
                                        return {label: item.name, value: item.instituteId}
                                    })}
                                />
                            </div>
                            <div className="search-blk create-blk">
                                <label htmlFor="Direction">Направление</label>
                                <CustomSelect
                                    scroll_id={"edudirselect"}
                                    placeholder={"Направление"}
                                    /*refValue={activities && activityId && activities.length ? getDefaultActivity() : null}*/
                                    onChange={(e) => {

                                    }}
                                    refValue={user && user.eduDirection && {label:user.eduDirection.name,value: user.eduDirection.educationDirectionId}}
                                    name="eduDirectionId"
                                    isMulti={false}
                                    options={educationDirections.map((item) => {
                                        return {label: item.name, value: item.educationDirectionId}
                                    })}
                                />
                            </div>
                            <div className="search-blk create-blk">
                                <label htmlFor="Program">Образовательная программа</label>
                                <CustomSelect
                                    placeholder={"Образовательная программа"}
                                    /*refValue={activities && activityId && activities.length ? getDefaultActivity() : null}*/
                                    onChange={(e) => {

                                    }}
                                    refValue={user && user.eduProgram && {label:user.eduProgram.name,value: user.eduProgram.educationProgramId}}

                                    name="eduProgramId"
                                    isMulti={false}
                                    options={educationPrograms.map((item) => {
                                        return {label: item.name, value: item.educationProgramId}
                                    })}
                                />
                            </div>
                            <div className="search-blk create-blk">
                                <label htmlFor="Program">Курс</label>
                                <CustomSelect
                                    placeholder={"Курс"}
                                    /*refValue={activities && activityId && activities.length ? getDefaultActivity() : null}*/
                                    onChange={(e) => {

                                    }}
                                    refValue={user && user.course && {label:user.course,value: user.course}}
                                    name="course"
                                    isMulti={false}
                                    options={[1,2,3,4,5,6,7,8,9,10,11,12,13,14,15].map((item) => {
                                        return {label: item, value: item}
                                    })}
                                />
                            </div>
                        </div>
                        <div className="table-btn student-btn">
                            <ButtonWithLoading className={"btn btn-blue modal-link"} type={"button"} paramsInOnClick={formRef.current} title={"Сохранить"}
                                               preventEvent={true}
                                               onClick={save}/>

                        </div>
                    </form>
                </div>
            </div>
            <ModalCustom
                title="Подготовка Аватара"
                active={avatarCropModalActive}
                setActive={setAvatarCropModalActive}
            >
                <CropModalContent
                    image={selectedAvatarImage ? selectedAvatarImage.url : undefined}
                    onClose={(croppedImage) => {
                        setCroppedImage(croppedImage);
                        setAvatarCropModalActive(false);
                    }}
                />
            </ModalCustom>
        </div>
    )
}

export default Information