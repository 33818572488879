import React from "react"


const Responses = ()=>{
    return (
        <div className="active">
            <div className="sorting">
                <span>По дате отклика</span>
            </div>
            <table className="table table-responses">
                <thead>
                <tr>
                    <th>Предложение</th>
                    <th>Тип</th>
                    <th>Статус</th>
                </tr>
                </thead>
                <tbody>
                <tr data-tab="3" className="active">
                    <td>
                        <div className="td-responses">
                            <img src="assets/img/company-image.svg" alt=""/>
                                <div>
                                    <span>Название организации</span>
                                    <p>Название предложения очень длинное</p>
                                </div>
                        </div>
                    </td>
                    <td className="td-tag">
                        <span>Практика</span>
                    </td>
                    <td className="status">
                        <div className="flex">
                            <span className="types1">Приглашение</span>
                            <button className="btn btn-confirmed">Подтверждено</button>
                            <button className="td-more"></button>
                        </div>
                        <ul className="unstyled td-more-info">
                            <li><a href="#">Перейти на страницу вакансии</a></li>
                            <li><a href="#">Перейти на страницу организации</a></li>
                            <li><a href="#">Отозвать отклик</a></li>
                        </ul>
                    </td>
                </tr>
                <tr data-tab="3" className="active">
                    <td>
                        <div className="td-responses">
                            <img src="assets/img/company-image.svg" alt=""/>
                                <div>
                                    <span>Название организации</span>
                                    <p>Название предложения очень длинное</p>
                                </div>
                        </div>
                    </td>
                    <td className="td-tag">
                        <span>Стажировка</span>
                    </td>
                    <td className="status">
                        <div className="flex">
                            <span className="types2">Просмотрено</span>
                            <button className="td-more"></button>
                        </div>
                        <ul className="unstyled td-more-info">
                            <li><a href="#">Перейти на страницу вакансии</a></li>
                            <li><a href="#">Перейти на страницу организации</a></li>
                            <li><a href="#">Отозвать отклик</a></li>
                        </ul>
                    </td>
                </tr>
                <tr data-tab="3" className="active">
                    <td>
                        <div className="td-responses">
                            <img src="assets/img/company-image.svg" alt=""/>
                                <div>
                                    <span>Название организации</span>
                                    <p>Название предложения очень длинное</p>
                                </div>
                        </div>
                    </td>
                    <td className="td-tag">
                        <span>Практика</span>
                    </td>
                    <td className="status">
                        <div className="flex">
                            <span className="types3">Переговоры</span>
                            <button className="td-more"></button>
                        </div>
                        <ul className="unstyled td-more-info">
                            <li><a href="#">Перейти на страницу вакансии</a></li>
                            <li><a href="#">Перейти на страницу организации</a></li>
                            <li><a href="#">Отозвать отклик</a></li>
                        </ul>
                    </td>
                </tr>
                <tr data-tab="3" className="active">
                    <td>
                        <div className="td-responses">
                            <img src="assets/img/company-image.svg" alt=""/>
                                <div>
                                    <span>Название организации</span>
                                    <p>Название предложения очень длинное</p>
                                </div>
                        </div>
                    </td>
                    <td className="td-tag">
                        <span>Вакансия</span>
                    </td>
                    <td className="status">
                        <div className="flex">
                            <span className="types4">На рассмотрении</span>
                            <button className="td-more"></button>
                        </div>
                        <ul className="unstyled td-more-info">
                            <li><a href="#">Перейти на страницу вакансии</a></li>
                            <li><a href="#">Перейти на страницу организации</a></li>
                            <li><a href="#">Отозвать отклик</a></li>
                        </ul>
                    </td>
                </tr>
                <tr data-tab="3" className="active">
                    <td>
                        <div className="td-responses">
                            <img src="assets/img/company-image.svg" alt=""/>
                                <div>
                                    <span>Название организации</span>
                                    <p>Название предложения очень длинное</p>
                                </div>
                        </div>
                    </td>
                    <td className="td-tag">
                        <span>Практика</span>
                    </td>
                    <td className="status">
                        <div className="flex">
                            <span className="types1">Приглашение</span>
                            <button className="btn btn-blue btn-not-confirmed">Подтвердить</button>
                            <button className="td-more"></button>
                        </div>
                        <ul className="unstyled td-more-info">
                            <li><a href="#">Перейти на страницу вакансии</a></li>
                            <li><a href="#">Перейти на страницу организации</a></li>
                            <li><a href="#">Отозвать отклик</a></li>
                        </ul>
                    </td>
                </tr>
                <tr data-tab="3" className="active">
                    <td>
                        <div className="td-responses">
                            <img src="assets/img/company-image.svg" alt=""/>
                                <div>
                                    <span>Название организации</span>
                                    <p>Название предложения очень длинное</p>
                                </div>
                        </div>
                    </td>
                    <td className="td-tag">
                        <span>Практика</span>
                    </td>
                    <td className="status">
                        <div className="flex">
                            <span className="types1">Приглашение</span>
                            <button className="btn btn-blue btn-not-confirmed">Подтвердить</button>
                            <button className="td-more"></button>
                        </div>
                        <ul className="unstyled td-more-info">
                            <li><a href="#">Перейти на страницу вакансии</a></li>
                            <li><a href="#">Перейти на страницу организации</a></li>
                            <li><a href="#">Отозвать отклик</a></li>
                        </ul>
                    </td>
                </tr>
                </tbody>
            </table>
        </div>
    )
}

export default Responses