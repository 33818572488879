import React, {useEffect, useMemo, useState} from 'react';
import PolarAreaChart from "@components/PolarAreaChart";


const Competences = ({studentData}) => {
    const [selectedChar,setSelectedChar] = useState(null);
    const [selectedChar2,setSelectedChar2] = useState(null);
    const [table,setTable] = useState(false)
    const competenceChartData = studentData.competenceChartData;
    const competenceChartDataDop = studentData.competenceChartDataDop;
    const competenceTableDataOrderedByPoints = useMemo(
        () =>
            [...studentData.competenceTableData].sort(
                ({ points: pointsA }, { points: pointsB }) => pointsB - pointsA,
            ),
        [studentData.competenceTableData],
    );
    const competenceTableDataOrderedByPointsDop = useMemo(
        () =>
            [...studentData.competenceTableDataDop].sort(
                ({ points: pointsA }, { points: pointsB }) => pointsB - pointsA,
            ),
        [studentData.competenceTableDataDop],
    );
    useEffect(() => {
        if(competenceChartData && competenceChartDataDop){
            setSelectedChar({name:competenceChartData.labels[0],value:competenceChartData.datasets[0].data[0]});
            setSelectedChar2({name:competenceChartDataDop.labels[0],value:competenceChartDataDop.datasets[0].data[0]});
        }

    },[competenceChartData,competenceChartDataDop])
    const clickPolar = (name,value) => {
        setSelectedChar({name,value});
    }
    const clickPolar2 = (name,value) => {
        setSelectedChar2({name,value});
    }
    return (
        <div className="active">
            <div className="swich-blk">
                <label className="switch">
                    <input onChange={e=>{setTable(!table)}} type="checkbox" className="toggleCheckbox"/>
                        <span className="slider"></span>
                </label>
                <span className="toggleLabel">{table ? "Таблица" : "График"}</span>
            </div>
            {!table &&
                <div className="chartDiv">
                    <div className="chart-block">
                        <div className="chart-blk blk1">
                            <span>Основные</span>
                            <div>
                                <PolarAreaChart
                                    labels={competenceChartData.labels}
                                    datasets={competenceChartData.datasets}
                                    clickEvent={clickPolar}
                                />
                            </div>
                        </div>
                        <div className="chart-blk blk2">
                            <span>Дополнительные</span>
                            <div>
                                <PolarAreaChart
                                    labels={competenceChartDataDop.labels}
                                    datasets={competenceChartDataDop.datasets}
                                    clickEvent={window.innerWidth < 576 ? clickPolar2 : clickPolar}
                                />
                            </div>
                        </div>
                        {selectedChar &&
                            <div className="chart-description blk3">
                                <div className="primary-description">
                                    <div id="label">{selectedChar?.name}</div>
                                    <div id="value">{selectedChar?.value}</div>
                                </div>
                            </div>
                        }

                        <div className="chart-description blk4">
                            <div className="secondary-description" style={{display: "none"}}>
                                <div id="label-right">{selectedChar2?.name}</div>
                                <div id="value-right">{selectedChar2?.value}</div>
                            </div>
                        </div>
                    </div>
                </div>
            }
            {table &&
            <div className="tableDiv indicator-table">
                <h3>Основные компетенции</h3>
                <table className="tables">
                    <thead>
                    <tr>
                        <th>Компетенция</th>
                        <th style={{textAlign: "end"}}><span>Текущие баллы</span></th>
                    </tr>
                    </thead>
                    <tbody>
                    {competenceTableDataOrderedByPoints.map((el, index) => (
                        <tr key={index} className="line">
                            <td>{el?.name}</td>
                            <td>{el.points}</td>
                        </tr>
                    ))}

                    </tbody>
                </table>
                <h3>Дополнительные компетенции</h3>
                <table className="tables">
                    <thead>
                    <tr>
                        <th>Компетенция</th>
                        <th><span>Текущие баллы</span></th>
                    </tr>
                    </thead>
                    <tbody>
                        {competenceTableDataOrderedByPointsDop.map((el, index) => (
                            <tr key={index} className="line">
                                <td>{el?.name}</td>
                                <td>{el.points}</td>
                            </tr>
                        ))}
                    </tbody>
                </table>
            </div>
            }
        </div>
)

}
export default Competences;