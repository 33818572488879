import React, {useEffect, useState} from "react"
import CustomTabs from "@components/CustomTabs";
import Competences from "@pages/AdminCreateStudent/Tabs/Indicators/Competences";
import Achievements from "@pages/AdminCreateStudent/Tabs/Indicators/Achievements/Achievements";
import Rsv from "@pages/AdminCreateStudent/Tabs/Indicators/Rsv";
import Dpo from "@pages/AdminCreateStudent/Tabs/Indicators/Dpo/Dpo";
import Polycoins from "@pages/AdminCreateStudent/Tabs/Indicators/Polycoins";
import {useVariables} from "@hooks/useVariables";
import {ADMIN_STUDENTS} from "@api/endpoints";
import Activities from "@pages/AdminCreateStudent/Tabs/Indicators/Activities";


const Indicators = ({studentId})=>{
    const [marks,setMarks] = useState()
    const [activeTabId,setActiveTabId] = useState(0);
    const requestGet = useVariables().requestOptionsGET;
    const tabs = [
        {title:"Компетенции",id:0},
        {title:"Область деятельности",id:5},
        {title:"Достижения",id:1},
        {title:"РСВ",id:2},
        {title:"Пройденные ДПО",id:3},
        {title:"Поликоины",id:4},
    ];
    const updateData= () =>{
        fetch(ADMIN_STUDENTS.GET_STUDENT_MARKS(studentId),requestGet).then(res=>res.json()).then(res=>{
            setMarks(res)
        })
    }
    const tabsElements = [
        <Competences updateData={updateData} data={marks?.competences}/>,
        <Achievements updateData={updateData} data={marks?.achievements}/>,
        <Rsv updateData={updateData} data={marks?.rsvs}/>,
        <Dpo data={marks?.dpos} updateData={updateData}/>,
        <Polycoins data={marks?.wallet} updateData={updateData}/>,
        <Activities data={marks?.activities} updateData={updateData}/>,
    ];
    useEffect(()=>{
        if(studentId)
        fetch(ADMIN_STUDENTS.GET_STUDENT_MARKS(studentId),requestGet).then(res=>res.json()).then(res=>{
            setMarks(res)
        })
    },[studentId])
    return (
        <div className="active">
            <div className="tabs tabs-scroll tabs-inside">
                <CustomTabs
                    tabs={tabs}
                    activeTab={activeTabId}
                    setActiveTab={setActiveTabId}
                    className="tabsmenu tags"
                />

                <div className="tab-content tab-inside">
                    {tabsElements[activeTabId]}
                </div>
                {/*<div className="table-btn student-btn">
                    <button type="submit" className="btn btn-blue modal-link"
                            data-modal="#avatarModal">Сохранить
                    </button>
                </div>*/}
            </div>
        </div>
    )
}

export default Indicators