import React, {useEffect, useRef, useState} from 'react';
import CustomSelect from "@components/CustomSelect";
import {useVariables} from "@hooks/useVariables";
import {ADMIN_STUDENTS} from "@api/endpoints";
import {toast} from "react-toastify";
import ButtonWithLoading from "@components/ButtonWithLoading";


const Rsv = ({data,updateData}) => {
    const [rsvs,setRsvs] = useState(null);
    const types = [
        {name:"Начальный",id:1},
        {name:"Средний",id:2},
        {name:"Высокий",id:3}
    ]
    useEffect(()=>{
        if(data){
            setRsvs(data);
            /*const main = data.filter(competence => competence.competence.competenceType.type ==='MAIN');
            const extra = data.filter(competence => competence.competence.competenceType.type ==='EXTRA');
            setRsvs({main,extra});*/
        }
    },[data])

    const [changes, setChanges] = useState([]);
    const changesRef = useRef();
    changesRef.current = changes;
    const requestPut = useVariables().constRequestOptionsPUT;

    const handleChange = (value,studentRsvId) => {
        let changes_ = [...changes];
        let index = changes_.findIndex(item => item.studentRsvId === studentRsvId);
        if (index >= 0)
            changes_[index].rsvMarkId = parseInt(value);
        else
            changes_.push({studentRsvId: studentRsvId, rsvMarkId: parseInt(value)});
        setChanges([...changes_]);
    }
    const saveChanges = async () => {
        let errors = 0;

        if (changesRef.current.length > 0){
            let cfg = requestPut(changesRef.current)
            let status = await fetch(ADMIN_STUDENTS.UPDATE_MARKS.RSV, cfg);
            status = await status.json();
            if (status.status !== "Success")
                errors++;
        }

        if (errors === 0) {
            toast.success(`Изменения успешно сохранены`, {
                position: toast.POSITION.TOP_CENTER,
            });
            updateData();
            setChanges([]);
        } else {
            toast.error(`Произошла ошибка при сохранении. `, {
                position: toast.POSITION.TOP_CENTER,
            });
        }
        return true;
    }


    return(
        <div className="active">
            <div className="table-blk">
                <h3>Основные РСВ</h3>
                <table className="tables tables-student">
                    <thead>
                    <tr>
                        <th>РСВ</th>
                        <th style={{textAlign: "end"}}>Текущие баллы</th>
                        {/*<th>Редактирование</th>*/}
                    </tr>
                    </thead>
                    <tbody>
                    {rsvs && rsvs && rsvs.map((rsv,index) =>(
                        <tr key={index}>
                            <td>{rsv?.rsv?.name}</td>
                            <td>
                                <CustomSelect
                                    options={types.map(type => ({label:type.name,value:type.id}))}
                                    placeholder={"Уровень"}
                                    initialValue={rsv?.rsvMark?.rsvMark ? [{label:rsv.rsvMark.rsvMark,value:rsv.rsvMark.rsvMark}] : []}
                                    onChange={(value) => {
                                        handleChange(value,rsv.studentRsvId);
                                    }}
                                />
                            </td>
                        </tr>
                    ))}

                    </tbody>
                </table>
            </div>
            <div className="table-btn student-btn">
                <ButtonWithLoading
                    type={"button"}
                    className={"btn btn-blue modal-link"}
                    title={"Сохранить"}
                    onClick={saveChanges}
                />
                {/* <button type="button" className="btn btn-blue modal-link"
                        data-modal="#avatarModal">Сохранить
                </button>*/}
            </div>
        </div>

    )

}
export default Rsv;