import "../assets/styles/components/feedback.scss";
import { useSelector } from "react-redux";
import { ToastContainer } from "react-toastify";

import { useAddFeedback } from "../hooks/useAddFeedback";

import BubbleButton from "./BubbleButton";

const AddFeedbackForm = ({ student, setFeedbackModal }) => {
  const user = useSelector((state) => state.user.user);
  const contactPerson = user.contactPersonList[0];
  const feedback = useAddFeedback(student, setFeedbackModal);
  const handleSaveFeedback = feedback.handleSaveFeedback;

  return (
    <div id={student.id} className="feedback">
      <ToastContainer containerId="feedbackSaveError" />
      <div className="student_info shadow_block table_block">
        <table>
          <tbody>
            <tr>
              <td className="student_name" colSpan="4">
                {`${student.secondName} ${student.firstName} ${student.middleName}`}
              </td>
            </tr>
            <tr className="table_headers">
              <td>Уровень образования</td>
              <td>Институт</td>
              <td>Направление</td>
              <td>Образовательная программа</td>
            </tr>
            <tr className="table_main_info">
              <td>{`${student.qualification?.name} ${student.course} курс`}</td>
              <td>{student.institute?.name}</td>
              <td>{student.direction?.name}</td>
              <td>{student.program?.name}</td>
            </tr>
          </tbody>
        </table>
      </div>
      <div className="company_info table_block">
        <table>
          <tbody>
            <tr className="table_headers">
              <td>Организация</td>
              <td>Сотрудник</td>
            </tr>
            <tr className="table_main_info">
              <td>{user.name}</td>
              <td>{`${contactPerson.secondName} ${contactPerson.firstName} ${contactPerson.middleName}`}</td>
            </tr>
          </tbody>
        </table>
      </div>
      {/*<div className="all_competencies_mark">*/}
      {/*    <span>Оценить все компетенции, как</span>*/}
      {/*    <BubbleButton name="all"*/}
      {/*                  id="allLIKE"*/}
      {/*                  value="LIKE"*/}
      {/*                  onClick={evaluateAllCompetencies}>*/}
      {/*        <span className="i-smile_joy icon">Соответствует</span>*/}
      {/*    </BubbleButton>*/}
      {/*    <BubbleButton name="all"*/}
      {/*                  id="allMIDDLE"*/}
      {/*                  value="MIDDLE"*/}
      {/*                  onClick={evaluateAllCompetencies}>*/}
      {/*        <span className="i-smile_ok icon">Недостаточно соответствует</span>*/}
      {/*    </BubbleButton>*/}
      {/*    <BubbleButton name="all"*/}
      {/*                  id="allDISLIKE"*/}
      {/*                  value="DISLIKE"*/}
      {/*                  onClick={evaluateAllCompetencies}>*/}
      {/*        <span className="i-smile_sad icon">Не соответствует</span>*/}
      {/*    </BubbleButton>*/}
      {/*</div>*/}
      <form onSubmit={handleSaveFeedback}>
        <div className="competencies_mark">
          <table className="list">
            <thead>
              <tr>
                <td></td>
                <td>Баллов за весь период обучения</td>
                <td>Баллов за семестр</td>
                <td>
                  Соотношение уровня компетенции и реального уровня подготовки
                  специалиста
                </td>
              </tr>
            </thead>
            <tbody>
              {student.competences &&
                student.competences.map((el) => (
                  <tr key={el.competenceId} className="line">
                    <td>{el.competenceName}</td>
                    <td>{el.points}</td>
                    <td>{el.points}</td>
                    <td>
                      <div className="mark_block">
                        <BubbleButton
                          name={el.competenceId}
                          id={`${student.id}-${el.competenceId}LIKE`}
                          value="LIKE"
                        >
                          <span className="i-smile_joy icon"></span>
                        </BubbleButton>
                        <BubbleButton
                          name={el.competenceId}
                          id={`${student.id}-${el.competenceId}MIDDLE`}
                          value="MIDDLE"
                        >
                          <span className="i-smile_ok icon"></span>
                        </BubbleButton>
                        <BubbleButton
                          name={el.competenceId}
                          id={`${student.id}-${el.competenceId}DISLIKE`}
                          value="DISLIKE"
                        >
                          <span className="i-smile_sad icon"></span>
                        </BubbleButton>
                      </div>
                    </td>
                  </tr>
                ))}
            </tbody>
          </table>
        </div>

        <div className="button_block">
          <input
              onClick={()=>setFeedbackModal(false)}
            className="main_button gray_button"
            type="button"
            value="Отменить"
          />
          <input
              className="main_button gray_button"
              type="reset"
              value="Очистить"
          />
          <input
            className="main_button turquoise_button"
            type="submit"
            value="Сохранить"
          />
        </div>
      </form>
    </div>
  );
};

export default AddFeedbackForm;
