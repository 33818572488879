import "@assets/styles/pages/page_from_admin.scss";
import { useEffect, useState } from "react";
import { create } from "zustand";

import { useVariables } from "@hooks/useVariables";

import Search from "@components/Search";
import CompaniesTable from "./components/CompaniesTable";
import CompanyCreateEditModal from "./components/CompanyCreateEditModal";

import {GET_COMPANY_PROFILES, GET_COMPANY_PROFILES_V2} from "@api/endpoints";
import { COMPANY_SEARCH_FIELDS } from "./constants";
import {usePagination} from "@hooks/usePagination";

export const useCompaniesStore = create((set) => ({
  companies: [],
  loading: false,
  setCompanies: (values) =>
    set((state) => ({
      ...state,
      companies: values,
    })),
}));

function CompanyFromAdminPage() {
  const { setCompanies } = useCompaniesStore();
  const [filters, setFilters] = useState({name:"",inn:""});
  const requestOptionsGET  = useVariables().requestOptionsGET;
  const companies = usePagination(GET_COMPANY_PROFILES_V2,requestOptionsGET,'companyId,desc',null,filters,true)

  const constRequestOptionsPOST = useVariables().requestOptionsGET;
  // load companies
 /* useEffect(() => {
    fetch(
      GET_COMPANY_PROFILES_V2,
      constRequestOptionsPOST,
    )
      .then((response) => response.json())
      .then((jsonData) => {
        const companies = jsonData.content;
        setCompanies(companies);
      })
      .catch((error) => console.log("Не удалось загрузить список компаний"));
  }, [filters]);*/

  return (
    <div className=" newStyle">
      <Search fields={COMPANY_SEARCH_FIELDS} onFiltersChange={setFilters} />
      <CompaniesTable companies={companies.data} />
      <CompanyCreateEditModal />
    </div>
  );
}

export default CompanyFromAdminPage;
