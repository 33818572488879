import "../assets/styles/components/login_form.scss";
import {NavLink} from "react-router-dom";
import React from "react";


const RegisteredSuccess = ({sentEmail, back}) => {

    return (
        <div className="login-content">

            <div className="login-content-block">
                <div className="login-content-title popover-top">
                    <div className={"flex flex-start"}>
                        <a onClick={back} className="btn-back  btn-back__white"></a>
                        <h1>Регистрация нового профиля</h1>
                    </div>

                </div>
                <div className="sent-message">
                    <p>Данные для входа были успешно отправлены на {sentEmail}</p>
                </div>
                <div className="help-block">
                    <p>По всем вопросам пишите на почту <a href="mailto:polykp@spbstu.ru">polykp@spbstu.ru</a>
                    </p>
                </div>
            </div>
        </div>
    );
};

export default RegisteredSuccess;
