import React from "react";
import { useDownloadFile } from "@hooks/useDownloadFile";
import { DOWNLOAD_FILE } from "@api/endpoints";
import defaultProduct from "@assets/images/product-default.png";
import styled from "styled-components";

const ProductImage = ({ product }) => {
  const img = useDownloadFile(
    DOWNLOAD_FILE,
    product.imgId,
    defaultProduct,
  ).file;

  return <Image src={img} alt="товар" />;
};

export default React.memo(ProductImage);

const Image = styled.img`
  width: 50px;
`;
