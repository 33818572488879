import React, { useEffect, useRef } from "react";
import styled from "styled-components";
import { useSelector } from "react-redux";
import { CircularProgress } from "@mui/material";

const Messages = ({
  selectedChat,
  fetchMessages,
  messagesLoading,
  sendReadMessage,
}) => {
  const user = useSelector((state) => state.user.user);
  const scrollContainer = useRef(null);
  const chatId = selectedChat?.chatId;

  const messages = selectedChat?.messages;

  useEffect(() => {
    scrollContainer.current.scrollTop = scrollContainer.current.scrollHeight;
  }, [messages]);

  useEffect(() => {
    if (chatId) {
      fetchMessages(chatId, true);
    }
  }, [chatId]);

  const sentReadMessageId = useRef();
  const onScroll = (event) => {
    const element = event.target;

    if (element.scrollTop === 0) {
      fetchMessages(chatId);
    } else if (
      element.scrollHeight - element.scrollTop - 1 <=
      element.clientHeight
    ) {
      if (messages?.length > 0) {
        const lastMessage = messages[0];

        if (
          lastMessage.authorId !== user.id &&
          sentReadMessageId.current !== lastMessage.id
        ) {
         // console.log("### SEND", lastMessage);
          sentReadMessageId.current = lastMessage.id;
          sendReadMessage(lastMessage.id);
        }
      }
    }
  };

  return (
    <Wrapper
      className="dialog_content scroll_element"
      ref={scrollContainer}
      onScroll={onScroll}
    >
      {messagesLoading ? (
        <LoaderWrapper>
          <CircularProgress />
        </LoaderWrapper>
      ) : null}
      {chatId && messages?.length > 0 ? (
        <MessagesList>
          {messages.map((el) => (
            <li
              key={el.id}
              className={
                el.authorId === user.id
                  ? "message_item my_message"
                  : "message_item"
              }
            >
              <p dangerouslySetInnerHTML={{__html: el.text.replace(/\n/g,"<br/>")}}/>
            </li>
          ))}
        </MessagesList>
      ) : null}
      {chatId && !messages?.length ? (
        <div className="empty_list">Начните диалог первыми</div>
      ) : null}
      {!chatId ? (
        <div className="empty_list">Выберите, кому хотели бы написать</div>
      ) : null}
    </Wrapper>
  );
};

export default React.memo(Messages);

const Wrapper = styled.div`
  position: relative;
`;

const MessagesList = styled.ul`
  position: relative;
`;

const LoaderWrapper = styled.div`
  display: flex;
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;

  justify-content: center;
  align-self: center;

  padding: 30px;
`;
