import "@assets/styles/components/list_item_from_company_profile.scss";
import React from "react";

import ProductCompanyListItem from "./ProductCompanyListItem";

const ProductModal = ({ products }) => (
  <div className="list_from_company_profile">
    <ul className="list_items">
      {products.length > 0 ? (
        products.map((product) => (
          <ProductCompanyListItem key={product.productId} product={product} />
        ))
      ) : (
        <div className="empty">Список товаров пуст</div>
      )}
    </ul>
  </div>
);

export default React.memo(ProductModal);
