import React, {useEffect} from 'react';
import {getDateInInput} from "@utils/other";
import ModalCustom from "@components/ModalCustom";
import CreateModal from "@pages/AdminStudentList/CreateModal/CreateModal";
import {useVariables} from "@hooks/useVariables";
import {usePagination} from "@hooks/usePagination";
import {ADMIN_STUDENTS} from "@api/endpoints";
import {NavLink} from "react-router-dom";
import LoadModal from "@pages/AdminDatabasePage/components/LoadModal";

const LogsTable = ({statuses,openCreate,editList,fields,rewriteFile}) => {
    const [selectedStatus, setSelectedStatus] = React.useState();
    const [activeModal, setActiveModal] = React.useState(false);
    const [activeRewriteModal, setRewriteModal] = React.useState(false);

    const spans = {
        FAILED:<span className={"error"}>ОШИБКА</span>,
        AWAITING:<span className={"processed"}>В ОЧЕРЕДИ</span>,
        RUNNING:<span className={"processed"}>В ПРОЦЕССЕ</span>,
        COMPLETED:<span className={"success"}>ВЫПОЛНЕНО</span>,
    }
    useEffect(() => {

    }, [])
    const openModal = (status) => {
        if(status.jobState !== "COMPLETED"){
            setActiveModal(true);
            let st = status;
            st.report = typeof status.report === "string"? JSON.parse(status.report) : status.report;
            if(!Array.isArray(st.report))
                st.report = [st.report]
            rewriteFile(st.report,status)
                //setSelectedStatus(st);
        }

    }
    const closeModal = () => {
        setActiveModal(false);
        setSelectedStatus(null);
    }
    return (
        <>
            <table className="tables logs-table">
                <thead>
                <tr>
                    <th className="add"><a onClick={openCreate} className="btn btn-blue modal-link" ></a>
                    </th>
                    <th>Название</th>
                    <th>Время загрузки</th>
                    <th>Время обработки</th>
                    <th>Количество строк</th>
                    <th>Статус</th>
                </tr>
                </thead>

                <tbody  id={""} style={{maxHeight: 50,height:20}}>
                {statuses.map((status) => {
                    return (
                        <tr onClick={()=>openModal(status)} key={status.jobId}>
                            <td>
                                <ul className="unstyled">
                                    <li>
                                        <a className="edit"  href={`#`}>
                                        </a>
                                    </li>

                                    <li> </li>
                                </ul>

                            </td>
                            <td>
                                {status.fileName}
                            </td>

                            <td>{getDateInInput(new Date(status.startTime),true)}</td>
                            <td>{status.endTime && getDateInInput(new Date(status.endTime), true)}</td>
                            <td>{status.entry}</td>
                            <td className={"status1"}>
                                {spans[status.jobState]}
                            </td>

                        </tr>
                    )
                })}
                </tbody>

            </table>

            <ModalCustom
                title={"Загрузите тот же файл"}
                active={activeRewriteModal}
                contentId={"logTable"}
                className={"autoWidth crateStudentModal newStyle"}
                setActive={setRewriteModal}
            >
                <LoadModal editList={false} loadToDB={(res)=> {
                    rewriteFile(res,selectedStatus)
                    setRewriteModal(false);
                    setActiveModal(false);
                }} setActiveModal={setRewriteModal} />
            </ModalCustom>


            <ModalCustom
                title={"Просмотр логов"}
                active={activeModal}
                className={"autoWidth crateStudentModal newStyle "}
                setActive={setActiveModal}
                onClose={closeModal}
            >
                <div className={"logs  logs-table"}>
                    <p>
                       Статус файла:  {selectedStatus && spans[selectedStatus.jobState]}
                    </p>
                    {selectedStatus && selectedStatus.report && selectedStatus.report.map((status,k) => (
                        <p className={"log"} key={k}>
                            <span className={"status"}>{status.status}</span>
                            <span dangerouslySetInnerHTML={{__html: status.message.join("<br/>")}}></span>
                        </p>
                    ))}
                </div>
                <div className="modal-footer">
                    <button onClick={() => setRewriteModal(true)} className="btn btn-blue">Перезаписать</button>

                </div>
            </ModalCustom>
        </>

    )
}
export default LogsTable;