import React from "react"
import {useVariables} from "@hooks/useVariables";
import {ADMIN_STUDENTS} from "@api/endpoints";
import {toast} from "react-toastify";


const LoginPassword = ({user})=>{
    const requestPost = useVariables().constRequestOptionsPOST;
    const resetPassword = ()=>{
        fetch(ADMIN_STUDENTS.RESET_PASS,requestPost({email:user.email})).then(r=>r.json()).then(r=>{
            toast.success("Пароль сброшен и отправлен на почту студента", {position: toast.POSITION.TOP_CENTER})
        })
    }
    return (
        <div className="active">
            <div className="login-block">
                <div className="login-blk">
                    <span>Логин</span>
                    <p>{user?.email}</p>
                </div>
                <div className="login-blk">

                    <button onClick={resetPassword} style={{maxWidth:400}} className="secondary_button turquoise_button">
                        Сбросить пароль
                    </button>
                </div>
            </div>

        </div>
    )
}

export default LoginPassword