import { Chart } from "chart.js/auto";
import { PolarArea } from "react-chartjs-2";
import {useRef} from "react";

function PolarAreaChart({ labels, datasets,clickEvent=()=>{} }) {
  let chart = useRef();
  const data = {
    labels: labels,

    datasets: datasets.map((dataset,key) => {
      return {
        label: datasets.names ? datasets.names[key] : "",
        data: dataset.data || [],
        backgroundColor: dataset.bgColor || "#ffffff",
        borderWidth: 1,
      };
    }),
  };
  const options = {
    onClick: (event, item) => {
      //NAME - VALUE
      if(item && item.length)
        clickEvent(data.labels[item[0].index],data.datasets[0].data[item[0].index]);
    },
    borderRadius: 8,
    plugins: {
      legend: {
        display: false,
      },
    },
    scales: {
      r: {
        pointLabels: {
          display: false,
          centerPointLabels: true,
          font: {
            size: 12,
            weight: 700,
            family: "Unbounded, sans-serif",
          },
        },
      },
    },
    axes: {
      // display: false
    },
  };

  return (
    <div className="chart">
      <PolarArea ref={ref=>chart.current = ref} data={data} options={options} />
    </div>
  );
}

export default PolarAreaChart;
