import React, {useEffect} from 'react';

const Dpos = ({studentData}) => {
    const [mainDpo,setMainDpo] = React.useState([]);
    const [secondaryDpo,setSecondaryDpo] = React.useState([]);
    useEffect(()=>{
        const main = studentData.dpos.filter(dpo => dpo.dpo.dpoType.type ==='MAIN');
        const digital = studentData.dpos.filter(dpo => dpo.dpo.dpoType.type ==='DIGITAL');
        setMainDpo(main);
        setSecondaryDpo(digital);
    },[]);
    return (
        <div className={"active"}>
            <div className="indicator-table">
                <h3>Основные ДПО</h3>
                <table className="tables tables-dpo">
                    <thead>
                    <tr>
                        <th>Название ДПО</th>
                        <th style={{textAlign:"end"}}>Дата</th>
                    </tr>
                    </thead>
                    <tbody>
                    {mainDpo && mainDpo.map(dpo => (
                        <tr>
                            <td>{dpo.dpo.name}</td>
                            <td>{dpo.year}</td>
                        </tr>
                    ))}

                    </tbody>
                </table>
                <h3>Цифровые ДПО</h3>
                <table className="tables tables-dpo">
                    <thead>
                    <tr>
                        <th>Название ДПО</th>
                        <th style={{textAlign:"end"}}>Дата</th>
                    </tr>
                    </thead>
                    <tbody>
                    {secondaryDpo.map(dpo => (
                        <tr>
                            <td>{dpo.dpo.name}</td>
                            <td>{dpo.year}</td>
                        </tr>
                    ))}
                    </tbody>
                </table>

            </div>
        </div>

    )
}
export default Dpos;