import React from "react"


const FeedBack = ()=>{
    return (
        <div className="active">
            <h3 className="table-title">Оценки от организаций</h3>
            <div className="table-block">
                <div className="table-popover">
                    <div className="popover-blk">
                        <p>Число обозначает количество компетенций, получивших данную оценку</p>
                    </div>
                </div>
                <table className="tables table-grade">
                    <thead>
                    <tr>
                        <th>Организация</th>
                        <th>Хорошо</th>
                        <th>Нейтрально</th>
                        <th>Плохо</th>
                    </tr>
                    </thead>
                    <tbody>
                    <tr>
                        <td>Название организации очень длинное</td>
                        <td data-label="Хорошо">10</td>
                        <td data-label="Нейтрально">4</td>
                        <td data-label="Плохо">0</td>
                    </tr>
                    <tr>
                        <td>Название организации очень длинное</td>
                        <td data-label="Хорошо">0</td>
                        <td data-label="Нейтрально">0</td>
                        <td data-label="Плохо">14</td>
                    </tr>
                    <tr>
                        <td>Название организации очень очень длинное</td>
                        <td data-label="Хорошо">14</td>
                        <td data-label="Нейтрально">0</td>
                        <td data-label="Плохо">0</td>
                    </tr>
                    <tr>
                        <td>Название организации очень длинное</td>
                        <td data-label="Хорошо">9</td>
                        <td data-label="Нейтрально">5</td>
                        <td data-label="Плохо">0</td>
                    </tr>
                    </tbody>
                </table>
            </div>
        </div>
    )
}

export default FeedBack