import {
    addEduDirectionAction,
    loadEduDirectionsAction,
    removeEduDirectionAction
} from "../reducers/eduDirectionReducer";
import {CREATE_OFFER, GET_ALL_EDU_DIRECTIONS_NEW} from "@api/endpoints";

export const loadEduDirections = () => {
    return dispatch => {
        fetch('http://localhost:8080/api/edu/get/edudirection')
            .then(response => response.json())
            .then(json => dispatch(loadEduDirectionsAction(json)))
            .catch(error => console.log("Не удается подключиться к серверу"))
    }
}
export const getAllEduDirections = async (savedUser)=>{
    let cfg = {
        method: "GET",
        headers: {
            "Content-Type": "application/json",
            Authorization: `${savedUser.type} ${savedUser.accessToken}`,
        },
    };
    const response = await (await fetch(GET_ALL_EDU_DIRECTIONS_NEW, cfg)).json();
    return response;
}

export const addEduDirection = (payload) => {
    const requestOptions = {
        method: "POST",
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify({...payload})
    }
    return dispatch => {
        fetch("http://localhost:8080/api/edu/add/edudirection", requestOptions)
            .then(response => response.json())
            .then(jsonData => dispatch(addEduDirectionAction(jsonData)))
            .catch(error => console.log("Не удается добавить данные"))
    }
}

export const removeEduDirection = (payload) => {
    return dispatch => {
        fetch(`http://localhost:8080/api/edu/delete/edudirection/${payload}`)
            .then(response => response.json())
            .then(jsonData => dispatch(removeEduDirectionAction(payload)))
            .catch(error => console.log("Не удается удалить объект"))
    }
}
