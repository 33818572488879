import "@assets/styles/pages/page_from_admin.scss";

import React from "react";

import ProductsTable from "./components/ProductsTable";

const ShopFromAdminPage = () => {
  return (
    <div className="page_from_admin">
      <h1>Список товаров</h1>
      <div className="shop_block filtered_page_block">
        <div className="shop_content filtered_page_content">
          <ProductsTable />
        </div>
        {/*<FiltersBlock
          opened={!collapseFilter}
          onClose={() => setCollapseFilter(true)}
          onFilterChange={setSelectedFilter}
        />*/}
      </div>
    </div>
  );
};

export default ShopFromAdminPage;
