import React, { useState } from "react";
import { CircularProgress } from "@mui/material";
import styled from "styled-components";
import { toast } from "react-toastify";
import { ToastContainer } from "react-toastify";

import { useTeamInteraction } from "@hooks/useTeamInteraction";
import { useDownloadFile } from "@hooks/useDownloadFile";

import { DOWNLOAD_FILE } from "@api/endpoints";

const OutcomingRequest = ({
  outgoing,
  isStudent,
  isCompany,
  redirectToProfile,
  setOutgoingsChanges,
}) => {
  const { rejectInvitation } = useTeamInteraction();

  const outgoingUser = isCompany ? outgoing.student : outgoing.company;

  const img = useDownloadFile(DOWNLOAD_FILE, outgoingUser.avatarId).file;

  const [rejectClicked, setRejectClicked] = useState(false);
  const handleRejectClick = () => {
    setRejectClicked(true);
    rejectInvitation(outgoing.id)
      .then((response) => {
        if (response.ok) {
          setOutgoingsChanges(outgoing.id);
          toast.success(
            isCompany ? "Приглашение отозвано" : "Заявка отменена",
            {
              position: toast.POSITION.TOP_CENTER,
            },
          );
        } else {
          throw response;
        }
      })
      .catch((error) => {
        toast.error(
          isCompany ? "Ошибка отзыва приглашения" : "Ошибка отмены заявки",
          {
            containerId: "feedbackSaveError",
            position: toast.POSITION.TOP_CENTER,
          },
        );
      })
      .finally(() => setRejectClicked(false));
  };

  return (
    <div className="student shadow_block">
      <div className="student_info">
        <div className="user_picture">
          <img src={img} alt="student" />
        </div>
        <div className="name">
          {isCompany && (
            <>
              <p>{outgoingUser.secondName}</p>
              <p>{outgoingUser.firstName}</p>
              <p>{outgoingUser.middleName}</p>
              <p className="additional">{outgoingUser.course} курс</p>
            </>
          )}
          {isStudent && (
            <>
              <p>{outgoingUser.name}</p>
              <p className="additional">{outgoingUser.desc}</p>
            </>
          )}
        </div>
        <div className="menu_actions">
          <span className="i-hidden_menu menu_button">
            <div className="hidden_menu">
              <ul className="hidden_menu_content">
                <li onClick={() => redirectToProfile(outgoingUser.id)}>
                  Перейти в цифровой профиль
                </li>
                <li>Связаться</li>
              </ul>
            </div>
          </span>
        </div>
      </div>
      <button
        className="submit_button turquoise_button"
        disabled={rejectClicked}
        onClick={handleRejectClick}
      >
        {rejectClicked ? (
          <ButtonLabel>
            <StyledCircularProgress size={16} color={"primary"} />
            {isCompany ? "Отзыв приглашения" : "Отмена заявки"}
          </ButtonLabel>
        ) : isCompany ? (
          "Отозвать приглашение"
        ) : (
          "Отменить заявку"
        )}
      </button>
      {rejectClicked ? <ToastContainer /> : null}
    </div>
  );
};

export default React.memo(OutcomingRequest);

const StyledCircularProgress = styled(CircularProgress)`
  margin-right: 10px;
`;

const ButtonLabel = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
`;
