import React from 'react';
import Collapse from "@pages/AdminCareersPage/components/Collapse";

const Search = () => {

    return (
        <Collapse
            header={"Поиск"}
        >
            <div style={{width: "100%"}}>

                <form action="" method="" className="search-form">
                    <div className="flex">
                        <div className="search-blk create-blk">
                            <label htmlFor="Name">ФИО</label>
                            <input type="text" placeholder="ivanov.ii" id="Name"/>
                        </div>
                        <div className="search-blk create-blk">
                            <label htmlFor="Mail">Почта</label>
                            <input type="text" placeholder="ivanov.ii" id="Mail"/>
                        </div>
                        <div className="search-blk create-blk">
                            <label htmlFor="Group">Номер группы</label>
                            <input type="text" placeholder="ivanov.ii" id="Group"/>
                        </div>
                        <div className="search-blk create-blk">
                            <label htmlFor="Status">Статус</label>
                            <div className="create-select create-all">
                                <ul className="tag tags tag-choice active unstyled">
                                    <li className="active selected">Учится <button className="btn-close"></button></li>
                                    <li className="tags-more">+2</li>
                                </ul>
                                <div className="select-block">
                                    <ul className="unstyled select-blk">
                                        <li>Статус2</li>
                                        <li>Статус3</li>
                                    </ul>
                                </div>
                            </div>
                        </div>
                        <div className="search-blk create-blk">
                            <label htmlFor="Level">Уровень получаемого образования</label>
                            <div className="create-select">
                                <div className="select-block">
                                    <ul className="unstyled select-blk">
                                        <li>Не выбрано</li>
                                        <li>Уровень 1</li>
                                        <li>Уровень 2</li>
                                        <li>Уровень 3</li>
                                    </ul>
                                </div>
                                <ul className="tag tags tag-choice active unstyled"></ul>
                            </div>
                        </div>
                        <div className="search-blk create-blk">
                            <label htmlFor="Profession">Профессия</label>
                            <div className="create-select">
                                <div className="select-block">
                                    <ul className="unstyled select-blk">
                                        <li>Не выбрано</li>
                                        <li>Профессия 1</li>
                                        <li>Профессия 2</li>
                                        <li>Профессия 3</li>
                                    </ul>
                                </div>
                                <ul className="tag tags tag-choice active unstyled"></ul>
                            </div>
                        </div>
                        <div className="search-blk create-blk">
                            <label htmlFor="Institute">Институт</label>
                            <div className="create-select">
                                <div className="select-block">
                                    <ul className="unstyled select-blk">
                                        <li>Не выбрано</li>
                                        <li>Институт 1</li>
                                        <li>Институт 2</li>
                                        <li>Институт 3</li>
                                    </ul>
                                </div>
                                <ul className="tag tags tag-choice active unstyled"></ul>
                            </div>
                        </div>
                        <div className="search-blk create-blk">
                            <label htmlFor="Direction">Направление</label>
                            <div className="create-select create-all">
                                <ul className="tag tags tag-choice active unstyled">
                                    <li className="active selected">Дизайн архитектурной среды <button
                                        className="btn-close"></button></li>
                                    <li className="tags-more">+2</li>
                                </ul>
                                <div className="select-block">
                                    <ul className="unstyled select-blk">
                                        <li>Направление 2</li>
                                        <li>Направление 3</li>
                                    </ul>
                                </div>
                            </div>
                        </div>
                        <div className="search-blk create-blk">
                            <label htmlFor="Program">Образовательная программа</label>
                            <div className="create-select">
                                <div className="select-block">
                                    <ul className="unstyled select-blk">
                                        <li>Не выбрано</li>
                                        <li>Программа 1</li>
                                        <li>Программа 2</li>
                                        <li>Программа 3</li>
                                    </ul>
                                </div>
                                <ul className="tag tags tag-choice active unstyled"></ul>
                            </div>
                        </div>
                    </div>
                    <div className="search-btn">
                        <button type="reset" className="btn btn-reset">Сбросить</button>
                        <button type="submit" className="btn btn-blue">Применить</button>
                    </div>
                </form>
            </div>
        </Collapse>
    )
}
export default Search;