import React, { useEffect, useState } from "react";
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
} from "@mui/material";
import styled from "styled-components";

import { privacyPolicy } from "../constants/links";

const COOKIE_ACCEPTED_STORAGE_KEY = "cookie_accept";

const CookieAccept = () => {
  const [open, setOpen] = useState(false);

  useEffect(() => {
    const cookieAccepted = localStorage.getItem(COOKIE_ACCEPTED_STORAGE_KEY);
    setOpen(!cookieAccepted);
  }, []);

  const handleCloseClick = () => {
    setOpen(false);
  };

  const handleAcceptClick = () => {
    localStorage.setItem(COOKIE_ACCEPTED_STORAGE_KEY, true);
    setOpen(false);
  };

  return (
    <>
      <Dialog
        open={open}
        onClose={handleCloseClick}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogContent>
          <DialogContentText id="alert-dialog-description">
            <p>
              Мы используем файлы cookies для улучшения работы сайта. Оставаясь
              на нашем сайте, вы соглашаетесь с условиями использования файлов
              cookies. Чтобы ознакомиться с нашими Положениями о
              конфиденциальности и об использовании файлов cookie,{" "}
              <LinkMarker>
                <a
                  className="policy_link"
                  href={privacyPolicy}
                  target={"_blank"}
                >
                  нажмите здесь
                </a>
              </LinkMarker>
              .
            </p>
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleAcceptClick} autoFocus>
            Принять
          </Button>
        </DialogActions>
      </Dialog>
    </>
  );
};

export default React.memo(CookieAccept);

const LinkMarker = styled.span`
  color: #49a9bf;
`;
