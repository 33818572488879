import { useState } from "react";

const CustomInput = ({
  name,
  type,
  value = "",
  label = null,
  noSpecial,
  ...props
}) => {
  const [innerValue, setInnerValue] = useState(value);

  const onChangeHandler = (e) => {
    const newValue = e.target.value;
    setInnerValue(
      noSpecial ? newValue.replace(/[^a-zA-Z0-9\\/:?\.# ]/g, "") : newValue,
    );
  };

  return (
    <div className="field">
      {label && <label>{label}</label>}
      {type === "textarea" ? (
        <textarea
          className="textarea"
          name={name}
          value={innerValue}
          onChange={onChangeHandler}
          {...props}
        ></textarea>
      ) : (
        <input
          className="form_field"
          type={type}
          name={name}
          value={innerValue}
          onChange={onChangeHandler}
          {...props}
        />
      )}
    </div>
  );
};

export default CustomInput;
