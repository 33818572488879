export const offersLanguages = {
    status: {
        DRAFT: "Черновик",
        ACTUAL: "Актуальный",
        ARCHIVE: "Архивный",
    },
    type: {
        VACANCY: "Вакансия",
        PRACTICE: "Практика",
        INTERNSHIP: "Стажировка",
    },
    timeJobTypes:{
        FULL_TIME: "Полная занятость",
        PART_TIME: "Частичная занятость",
        ONE_TIME_ASSIGMENT: "Один раз",
    },
    formatJobTypes:{
        FULL_TIME: "Полный день",
        CHANGING_GRAPHICS: "Меняющийся график",
        FLEXIBLE_SCHEDULE: "Гибкий график",
        DISTANT_WORK: "Дистанционная работа",
        SHIFT_WORK: "Посменная работа"
    }
}
export const studentsLanguages= {
    studentStatuses:{
        DRAFT: "Черновик",
        ACTUAL: "Актуальный",
        ARCHIVE: "Архивный",
    }
}
export const responseLanguages  =  {
    status:{
        CONSIDERATION:"На рассмотрении",
        REJECTED_BY_COMPANY:"Отклонен компанией",
        CANCELED_BY_STUDENT:"Отменен студентом",
        VIEWED:"Просмотрен",
        NEGOTIATION:"Направлено приглашение",
        APPROVED:"Утвержден"
    }
}