import "@assets/styles/pages/company_list.scss";
import React, {useState} from "react";

import { useCompanyList } from "@hooks/useCompanyList";

import CompanyListItem from "./components/CompanyListItem";

const CompanyListPage = () => {
  const { companies, redirectCompanyPage } = useCompanyList();
    const [openedFilters,setOpenedFilters] = useState(false)
  return (
      <div className="list-company newStyle">
          <h1>Компании</h1>
          <div className="content">
              <div className="edit-profile">
                  <div className="search">
                      <input type="text" placeholder="Поиск по названию"/>
                      <button type="submit"></button>
                  </div>
                  <div className="sorting flex">
                      <span>По алфавиту</span>
                      <button onClick={()=>setOpenedFilters(!openedFilters)} className="filters"></button>
                  </div>
                  <div className="list-company-block row-block">
                      {companies.map((company) => (
                          <CompanyListItem
                              key={company.id}
                              company={company}
                              onClick={redirectCompanyPage}
                          />
                      ))}
                  </div>
              </div>
              {openedFilters &&
                  <div className="filter-block">
                      <div className="flex flex-filter">
                          <h2 className="filter-title">Фильтры</h2>
                          <button className="filters" onClick={()=>setOpenedFilters(!openedFilters)}>Свернуть</button>
                      </div>
                      <div className="filter-blk">
                          <span className="title">Карьера</span>
                          <label className="checkbox-ios">
                              <input type="checkbox" checked/>
                              <span className="checkbox-ios-switch"></span>
                              <p>Есть открытые предложения</p>
                          </label>
                      </div>
                      <div className="filter-blk">
                          <div className="flex">
                              <span className="title">Направление</span>
                              <span className="choise">Свернуть</span>
                          </div>
                          <div className="search">
                              <input type="text" placeholder="Поиск"/>
                          </div>
                      </div>
                      <div className="filtr-blk">
                          <div className="check-block">
                              <label className="option-item form-check">
                                  <input className="checkbox" type="checkbox" checked name="checkbox"
                                         data-name='Биоинженерия и биоинформатика'/>
                                  <span className="checkbox-custom"></span>
                                  <span className="label">Биоинженерия и биоинформатика</span>
                              </label>
                              <label className="option-item form-check">
                                  <input className="checkbox" type="checkbox" checked name="checkbox"
                                         data-name='Дизайн архитектурной среды'/>
                                  <span className="checkbox-custom"></span>
                                  <span className="label">Дизайн архитектурной среды</span>
                              </label>
                              <label className="option-item form-check">
                                  <input className="checkbox" type="checkbox" name="checkbox"
                                         data-name='Прикладные математика и физика'/>
                                  <span className="checkbox-custom"></span>
                                  <span className="label">Прикладные математика и физика</span>
                              </label>
                              <label className="option-item form-check">
                                  <input className="checkbox" type="checkbox" name="checkbox"
                                         data-name='Биоинженерия и биоинформатика2'/>
                                  <span className="checkbox-custom"></span>
                                  <span className="label">Биоинженерия и биоинформатика2</span>
                              </label>
                              <label className="option-item form-check">
                                  <input className="checkbox" type="checkbox" name="checkbox"
                                         data-name='Дизайн архитектурной среды2'/>
                                  <span className="checkbox-custom"></span>
                                  <span className="label">Дизайн архитектурной среды2</span>
                              </label>
                              <label className="option-item form-check">
                                  <input className="checkbox" type="checkbox" name="checkbox"
                                         data-name='Биоинженерия и биоинформатика3'/>
                                  <span className="checkbox-custom"></span>
                                  <span className="label">Биоинженерия и биоинформатика3</span>
                              </label>
                              <label className="option-item form-check">
                                  <input className="checkbox" type="checkbox" name="checkbox"
                                         data-name='Дизайн архитектурной среды3'/>
                                  <span className="checkbox-custom"></span>
                                  <span className="label">Дизайн архитектурной среды2</span>
                              </label>
                              <label className="option-item form-check">
                                  <input className="checkbox" type="checkbox" name="checkbox"
                                         data-name='Прикладные математика и физика2'/>
                                  <span className="checkbox-custom"></span>
                                  <span className="label">Прикладные математика и физика2</span>
                              </label>
                          </div>
                          <div className="check-choise check-option-block">
                              <div className="check-blk">
                                  <span>Биоинженерия и биоинформатика</span>
                                  <button type="button" className="btn-close" aria-label="Close"></button>
                              </div>
                              <div className="check-blk">
                                  <span>Дизайн архитектурной среды</span>
                                  <button type="button" className="btn-close" aria-label="Close"></button>
                              </div>
                          </div>
                      </div>
                      <div className="filter-blk">
                          <div className="flex">
                              <span className="title">Профессии</span>
                              <span className="choise">Выбрать</span>
                          </div>
                          <ul className="tag active tag-choice unstyled">
                              <li>Первая <button className="btn-close"></button></li>
                              <li>Вторая профессия <button className="btn-close"></button></li>
                              <li>Третья профессия <button className="btn-close"></button></li>
                          </ul>
                      </div>
                      <div className="filter-btn">
                          <button className="btn btn-blue">Применить</button>
                          <button className="btn btn-grey">Сбросить всё</button>
                      </div>
                  </div>
              }
          </div>

      </div>
   /* <div className="company_list  page newStyle">
      <h1>Представители компаний</h1>
      {/!* TODO waiting for endpoint search support *!/}
      {/!*<SearchLine placeholder="Поиск по названию" />*!/}

      <ul>
        {companies.map((company) => (
          <CompanyListItem
            key={company.id}
            company={company}
            onClick={redirectCompanyPage}
          />
        ))}
      </ul>
    </div>*/
  );
};

export default CompanyListPage;
