import React, {useEffect, useRef, useState} from 'react';
import {useVariables} from "@hooks/useVariables";
import {ADMIN_STUDENTS} from "@api/endpoints";
import ButtonWithLoading from "@components/ButtonWithLoading";
import {toast} from "react-toastify";


const Activities= ({data,updateData}) => {
    const [activities, setActivities] = useState(data);
    const [changes, setChanges] = useState([]);
    const changesRef = useRef();
    changesRef.current = changes;
    const requestPut = useVariables().constRequestOptionsPUT;
    const handleChange = (e) => {
        let itemId = parseInt(e.target.dataset.id);
        let changes_ = [...changes];
        let index = changes_.findIndex(item => item.studentActivityId === itemId);
        if (index >= 0)
            changes_[index].activityScore = parseInt(e.target.value);
        else
            changes_.push({studentActivityId: itemId, activityScore: parseInt(e.target.value)});
        setChanges([...changes_]);
    }
    const saveChanges = async () => {
        let errors = 0;
        if (changesRef.current.length > 0)
        {
            let cfg = requestPut(changesRef.current);
            let status = await fetch(ADMIN_STUDENTS.UPDATE_MARKS.ACTIVITY, cfg);
            status = await status.json();
            if (status.status !== "Success")
                errors++;
        }
        if (errors === 0) {
            toast.success(`Изменения успешно сохранены`, {
                position: toast.POSITION.TOP_CENTER,
            });
            updateData();
            setChanges([]);
        } else {
            toast.error(`Произошла ошибка при сохранении. Ошибок ${errors} из ${changesRef.current.length} изменений`, {
                position: toast.POSITION.TOP_CENTER,
            });
        }
        return true;
    }
    return (
        <div className="active">
            <div className="table-blk">
                <h3>Основные компетенции</h3>
                <table className="tables tables-student">
                    <thead>
                    <tr>
                        <th>Название</th>
                        <th>Текущие баллы</th>
                        <th>Редактирование</th>
                    </tr>
                    </thead>
                    <tbody>
                    {activities && activities.map(activity => (
                        <tr>
                            <td>{activity.activity.name}</td>
                            <td>{activity.activityScore}</td>
                            <td className="td-edit">
                                <input onChange={handleChange} data-id={activity.studentActivityId} type="number"
                                       min={0} defaultValue={activity.activityScore}/>
                            </td>
                        </tr>
                    ))}
                    </tbody>
                </table>
            </div>

            <div className="table-btn student-btn">
                <ButtonWithLoading
                    type={"button"}
                    className={"btn btn-blue modal-link"}
                    title={"Сохранить"}
                    onClick={saveChanges}
                />
                {/* <button type="button" className="btn btn-blue modal-link"
                        data-modal="#avatarModal">Сохранить
                </button>*/}
            </div>
        </div>

    )

}
export default Activities;