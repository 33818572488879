import React, {useRef, useState} from 'react';
import { Swiper, SwiperSlide } from 'swiper/react';
import 'swiper/css';
import module1 from "../../assets/images/modul1.png"
import module2 from "../../assets/images/modul2.png"
import module3 from "../../assets/images/modul3.png"
import module4 from "../../assets/images/modul4.png"
import module5 from "../../assets/images/modul5.png"
import module6 from "../../assets/images/modul6.png"
import 'swiper/scss/pagination';
import { Pagination } from 'swiper/modules';
import Tab1 from "@pages/StartPage/components/ModulesCarousel/Tab1";
import Tab2 from "@pages/StartPage/components/ModulesCarousel/Tab2";
import Tab3 from "@pages/StartPage/components/ModulesCarousel/Tab3";

const ModulesCarousel = ({activeTab}) => {
    const [activeSlide,setActiveSlide] = useState(0);
    const bulletsRef = useRef(null);
    const [swiper,setSwiper] = useState();
    const tabs = [
        Tab1,
        Tab2,
        Tab3
    ]
    return (
        <div className={"swiper modulsSwiper"}>
            <Swiper
                pagination={{el:bulletsRef.current,clickable:true}}
                slidesPerView={1}
                loop={true}
                spaceBetween={20}
                modules={[Pagination]}
                onSlideChange={(e) => setActiveSlide(e.activeIndex)}
                onSwiper={setSwiper}
            >
                {tabs[activeTab].map(el=>(el))}
            </Swiper>
            <div className="modul-controls">
                <div style={{bottom:0,zIndex:2}} ref={bulletsRef} className="swiper-pagination moduls-pagination"></div>
                <div onClick={()=>swiper?.slideNext()} style={{cursor:"pointer",zIndex:2}} className="swiper-button-next modul-next">Дальше</div>
            </div>
        </div>

    )

}
export default ModulesCarousel;