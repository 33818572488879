import React, {useEffect, useRef, useState} from 'react';
import ModalCustom from "@components/ModalCustom";
import EditOrCreateModal from "@pages/AdminCreateOrganization/Tabs/Tariff/EditOrCreateModal";
import CreateModal from "@pages/AdminCreateStudent/Tabs/Indicators/Achievements/CreateModal";
import {ADMIN_STUDENTS} from "@api/endpoints";
import {toast} from "react-toastify";
import {useVariables} from "@hooks/useVariables";
import ButtonWithLoading from "@components/ButtonWithLoading";


const Achievements = ({data,updateData}) => {
    const [achievements, setAchievements] = useState(null);
    const [selectedAchievement, setSelectedAchievement] = useState(null);
    const [activeModal, setActiveModal] = useState(false);
    useEffect(() => {
        if (data) {
            setAchievements(data);
        }
    }, [data])

    const [changes, setChanges] = useState([]);
    const changesRef = useRef();
    changesRef.current = changes;
    const requestPut = useVariables().constRequestOptionsPUT;

    const selectAchievement = (achievement) => {
        setSelectedAchievement(achievement);
        setActiveModal(true);
    }
    const closeModal = () => {
        setActiveModal(false);
        setSelectedAchievement(null);
    }

    const handleChange = (e) => {
        let itemId = parseInt(e.target.dataset.id);
        let changes_ = [...changes];
        let index = changes_.findIndex(item => item.studentAchievementId === itemId);
        if (index >= 0)
            changes_[index].achievementScore = parseInt(e.target.value);
        else
            changes_.push({studentAchievementId: itemId, achievementScore: parseInt(e.target.value)});
        setChanges([...changes_]);
    }
    const saveChanges = async () => {
        let errors = 0;
        if (changesRef.current.length > 0)
        {
            let cfg = requestPut(changesRef.current);
            let status = await fetch(ADMIN_STUDENTS.UPDATE_MARKS.ACHIEVEMENT, cfg);
            status = await status.json();
            if (status.status !== "Success")
                errors++;
        }
        if (errors === 0) {
            toast.success(`Изменения успешно сохранены`, {
                position: toast.POSITION.TOP_CENTER,
            });
            updateData();
            setChanges([]);
        } else {
            toast.error(`Произошла ошибка при сохранении. Ошибок ${errors} из ${changesRef.current.length} изменений`, {
                position: toast.POSITION.TOP_CENTER,
            });
        }
        return true;
    }


    return (
        <div className="active">
            <table className="tables tables-achievements">
                <thead>
                <tr>
                    <th className="add">
                       {/* <a onClick={() => setActiveModal(true)}
                                           className="btn btn-blue modal-link"
                                           ></a>*/}</th>
                    <th>Название достижения</th>
                    <th>Количество</th>
                    <th>Редактирование количества</th>
                </tr>
                </thead>
                <tbody>
                    {achievements && achievements.map((achievement, index) => (
                        <tr key={index}>
                            <td>
                                <a className="delete"></a>
                            </td>
                            <td>{achievement.achievement.name}</td>
                            <td data-label="Количество"><span>{achievement.achievementScore}</span></td>
                            <td data-label="Редактирование достижения" className="td-edit">
                                <input onChange={handleChange} data-id={achievement.studentAchievementId} type="number" min={0} defaultValue={achievement.achievementScore}/>
                            </td>
                        </tr>
                    ))}

                </tbody>
            </table>



            <div className="table-btn student-btn">
                <ButtonWithLoading
                    type={"button"}
                    className={"btn btn-blue modal-link"}
                    title={"Сохранить"}
                    onClick={saveChanges}
                />

            </div>

            <ModalCustom
                title={"Добавление достижения"}
                active={activeModal}
                className={"autoWidth"}
                setActive={setActiveModal}
            >
                <CreateModal
                    closeModal={closeModal}
                />
            </ModalCustom>
        </div>

    )

}
export default Achievements;