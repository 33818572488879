import { useState } from "react";
import { useSelector } from "react-redux";
import { toast } from "react-toastify";

import { ADD_FEEDBACK } from "../api/endpoints";

export const useAddFeedback = (student, setFeedbackModal) => {
  const user = useSelector((state) => state.user.user);
  const requestOptions = {
    method: "GET",
    headers: {
      Authorization: `${user.type} ${user.accessToken}`,
    },
  };

  const errorToast = () =>
    toast.error("Не удается сохранить обратную связь", {
      containerId: "feedbackSaveError",
      position: toast.POSITION.TOP_CENTER,
    });

  const [feedback, setFeedback] = useState({});
  const [allCompetenciesMark, setAllCompetenciesMark] = useState(null);

  // const evaluateAllCompetencies = (event) => {
  //     TODO
  //     console.log('click', event);
  //     setAllCompetenciesMark(event.target.value);
  // }

  const handleSaveFeedback = (event) => {
    event.preventDefault();
    const feedback = [];
    const form = new FormData(event.target);
    for (const [key, value] of form) {
      feedback.push({
        competenceId: key,
        value: value,
      });
    }
    if (feedback.length < student.competences.length) {
      toast.error("Заполните все поля обратной связи", {
        position: toast.POSITION.TOP_CENTER,
      });
    } else {
      addFeedback(student.id, { competenceGrades: feedback });
    }
    //console.log(feedback);
  };

  const addFeedback = (studentId, feedback) => {
    const requestOptions = {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        Authorization: `${user.type} ${user.accessToken}`,
      },
      body: JSON.stringify(feedback),
    };

    fetch(ADD_FEEDBACK(studentId), requestOptions)
      .then((response) => {
        if (response.ok) {
          return response.json();
        } else {
          throw true;
        }
      })
      .then((json) => {
        //console.log(json);
        setFeedback(json);
        //console.log(setFeedbackModal);
        setFeedbackModal((prev) => !prev);
      })
      .catch(() => errorToast());
  };

  return {
    handleSaveFeedback,
    allCompetenciesMark,
  };
};
