import React, {useMemo, useRef, useState} from "react";
import {usePagination} from "@hooks/usePagination";
import {ADMIN_STUDENTS} from "@api/endpoints";
import LogsTable from "@pages/AdminStudentList/CreateModal/LogsTable";
import EditList from "@pages/AdminDatabasePage/components/EditList";
import {toast} from "react-toastify";
import {useVariables} from "@hooks/useVariables";
import ModalCustom from "@components/ModalCustom";
import LoadModal from "@pages/AdminDatabasePage/components/LoadModal";

const Students = ({setOpenedList}) => {
    const [activeModal,setActiveModal] = useState(false)
    const requestGet = useVariables().requestOptionsGET
    const [filters,setFilters] = useState({size:100,jobType:"CREATE_STUDENT"})
    const requestPost = useVariables().constRequestOptionsPOST;
    const openCreate = () => {
        setActiveModal(true)
    }
    const [editList,setEditList] = useState(false)
    const [activeUsers,setActiveUsers] = useState(null)
    const statuses = usePagination(ADMIN_STUDENTS.GET_FILES_LOGS,requestGet,"","",filters,true)
    const loadToDB = (users,fileName,jobId=null) => {
        const config = requestPost({"students": users})
        fetch(ADMIN_STUDENTS.CREATE_STUDENTS_JSON(jobId,fileName), config).then(res => res.json()).then((res) => {
            if (res.status === "Success") {
                toast.success("Список обрабатывается", {position: toast.POSITION.TOP_CENTER});
                statuses.refresh();
            } else
                toast.error("Ошибка при загрузке списка", {position: toast.POSITION.TOP_CENTER})

        })
    };
    const doEdit = (users,responseFile) => {
        /*if(responseFile.entry !== users.length){
            toast.error("Ошибка. Кажется это не тот файл. Списки отличаются", {position: toast.POSITION.TOP_CENTER})
            return;
        }*/

        setActiveUsers({users,file:responseFile})
        setEditList(true);
        setActiveModal(false)
    }
    useMemo(()=>setOpenedList(editList),[editList])
    const back = () => {
        setEditList(false);
        setActiveModal(false);
        setActiveUsers({})
    }
    const rewrite = (fileUsers,responseFile) =>{
        doEdit(fileUsers,responseFile)
    }
    return (
        <div className={"active"}>
            <div style={{marginTop:20}} className="scroll">
                {!editList &&
                    <LogsTable rewriteFile={rewrite} openCreate={openCreate} statuses={statuses.data}/>
                }
                {editList &&
                    <EditList loadToDB={loadToDB} sendList={loadToDB} back={back} data={activeUsers}/>}
            </div>
            <ModalCustom
                title={"Добавление студента"}
                active={activeModal}
                contentId={"logTable"}
                className={"autoWidth crateStudentModal newStyle"}
                setActive={setActiveModal}
            >
                <LoadModal editList={false} loadToDB={loadToDB} setActiveModal={setActiveModal} />
            </ModalCustom>
        </div>
    )

}
export default Students;