import "@assets/styles/pages/shop.scss";

import React, {useEffect, useState} from "react";
import "@assets/newpages/style.scss"
import {NavLink, useLocation, useNavigate, useParams} from "react-router-dom";
import {useDispatch, useSelector} from "react-redux";
import Information from "@pages/AdminCreateStudent/Tabs/Information";
import Career from "@pages/AdminCreateOrganization/Tabs/Career";
import LoginPassword from "@pages/AdminCreateStudent/Tabs/LoginPassword";
import Indicators from "@pages/AdminCreateStudent/Tabs/Indicators/Indicators";
import FeedBack from "@pages/AdminCreateStudent/Tabs/FeedBack";
import Responses from "@pages/AdminCreateStudent/Tabs/Responses";
import {useVariables} from "@hooks/useVariables";
import {ADMIN_GET_COMPANY, ADMIN_STUDENTS, UPDATE_AVTAR_V2} from "@api/endpoints";
import {usePreviewFile} from "@hooks/usePreviewFile";
import {toast} from "react-toastify";


const AdminCreateStudent = () => {
    const user = useSelector((state) => state.user.user);
    const params = useParams();
    const previousLocationPathname = useLocation().state?.previousLocationPathname
    const navigate = useNavigate();
    const {
        selectedFile: selectedAvatar,
        setSelectedFile: setSelectedAvatar,
        handleFileChange: handleImageChange,
    } = usePreviewFile(
        null,
        "image.*",
    );
    const [student, setStudent] = useState(null);
    const requestPut = useVariables().constRequestOptionsPUT;
    const [activeTab, setActiveTab] = useState(0);
    const [info, setInfo] = useState(null)
    const requestGet = useVariables().requestOptionsGET;
    useEffect(() => {
        fetch(ADMIN_STUDENTS.GET_REFERENCE_INFORMATION, requestGet).then(res => res.json()).then(res => setInfo(res))
    }, [])
    useEffect(() => {
        if (params.studentId) {
            fetch(ADMIN_STUDENTS.GET_ONE(params.studentId), requestGet).then(res => res.json()).then(data => {
                setStudent(data)
            }).catch(err => {
                toast.error("Произошла ошибка при открытии студента", {
                    position: toast.POSITION.TOP_CENTER,
                });
            })
        }
    }, [params.studentId])
    const tabs = student ? [
        "Основная информация",
        "Логин и пароль",
        "Показатели",
        "Обратная связь",
        "Отклики на предложения",
    ] : ["Основная информация"];
    const jsonKeys = [
        "eduProgramId",
        "eduDirectionId",
        "instituteId",
        "professionId",
        "qualificationId",
        "status",
        "course",
    ]
    const updateAvatar = (companyId, requestOptions) => {
        fetch(UPDATE_AVTAR_V2(companyId), requestOptions)
            .then((response) => {
                if (response.ok) {
                    toast.success("Изменения аватара сохранены", {
                        position: toast.POSITION.TOP_CENTER,
                    });

                    // onClose?.();
                    return response.json();
                } else {
                    throw true;
                }
            })

            .catch((error) => {
                toast.error("Ошибка при обновление аватара", {
                    position: toast.POSITION.TOP_CENTER,
                });
            });
    }
    const validate = (data) => {
        let errors = [];
        if (!data.firstName) {
            errors.push({message: "Введите имя", field: "inn"});
        }
        if (!data.secondName) {
            errors.push({message: "Введите фамилию", field: "name"});
        }


        if (!data.group) {
            errors.push({message: "Введите группу", field: "activityId"});//Тип занятости
        }
        if (!data.email) {
            errors.push({message: "Введите почту", field: "activityId"});//Тип занятости
        }
        if (!data.instituteId) {
            errors.push({message: "Выберите институт", field: "activityId"});//Тип занятости
        }
        if (!data.eduDirectionId) {
            errors.push({message: "Выберите направление", field: "activityId"});//Тип занятости
        }
        if (!data.eduProgramId) {
            errors.push({message: "Выберите программу обучения", field: "activityId"});//Тип занятости
        }
        if (!data.qualificationId) {
            errors.push({message: "Выберите квалификацию", field: "activityId"});//Тип занятости
        }
        if (!data.course) {
            errors.push({message: "Выберите курс", field: "activityId"});//Тип занятости
        }

        errors.map(x => {
            toast.error(x.message, {
                position: toast.POSITION.TOP_CENTER,
            });
        })
        return errors.length === 0;
    }

    const save = async (e) => {
        let form = new FormData(e);
        const data = {
            ...Object.fromEntries(form.entries()),
        }
        jsonKeys.map((key) => {
            data[key] = JSON.parse(data[key]).value
        })
        let studentId = null;
        if (!validate(data))
            return true;
        if (student) {
            studentId = student.studentId;
            const config = requestPut(data);
            let stud = await fetch(ADMIN_STUDENTS.UPDATE_STUDENT(student.studentId, data), config);
            stud = await stud.json();
            if(stud.status !== "Error"){
                toast.success("Студент отредактирован", {position: toast.POSITION.TOP_CENTER})
            }else
                toast.error(stud.message[0], {position: toast.POSITION.TOP_CENTER})
        } else {
            const requestPost = {
                method: "POST",
                headers: {
                    "Content-Type": "application/json",
                    Authorization: `${user.type} ${user.accessToken}`,
                },
                body: JSON.stringify(data),
            };

            let stud = await fetch(ADMIN_STUDENTS.CREATE_STUDENT, requestPost);
            stud = await stud.json();
            if (stud && stud.studentId) {
                studentId = stud.studentId;
                toast.success("Студент создан", {position: toast.POSITION.TOP_CENTER})
                navigate(`/admin/student-update/${studentId}`, {state: {previousLocationPathname: "/admin/students"}});
            } else {
                toast.error(stud.message ? stud.message[0] : "Ошибка при сохранении", {position: toast.POSITION.TOP_CENTER})
            }
        }


        if (selectedAvatar && studentId) {
            fetch(selectedAvatar)
                .then((response) => response.blob())
                .then((blob) => {
                    // Update the formData object
                    const formData = new FormData();
                    formData.append("file", blob);

                    const requestOptionsAvatar = {
                        method: "POST",
                        headers: {
                            // "Content-Type": "multipart/form-data",
                            Authorization: `${user.type} ${user.accessToken}`,
                        },
                        body: formData,
                    };

                    updateAvatar(studentId, requestOptionsAvatar);
                });
        }
        return true;
    }
    const tabsElements = [
        <Information selectedAvatar={selectedAvatar} info={info} setSelectedAvatar={setSelectedAvatar} user={student}
                     save={save}/>,
        <LoginPassword user={student}/>,
        <Indicators studentId={student?.studentId}/>,
        <FeedBack/>,
        <Responses/>,
    ]


    return (
        <div className="newStyle">
            <NavLink to={previousLocationPathname ? previousLocationPathname : -1} className="btn-back">
                {params.studentId? "Редактирование студента" : "Создание профиля студента"}</NavLink>
            <div className="tabs tabs-scroll">
                <ul style={{marginTop: 20}} className="tabsmenu">
                    {tabs.map((item, index) => (
                        <li key={index} className={`tab1 ${activeTab === index ? "active" : ""}`}
                            onClick={() => setActiveTab(index)}
                        >{item}</li>
                    ))}

                </ul>
                <div className="tab-content">
                    {tabsElements[activeTab]}

                </div>
                {/*{activeTab !== 0 && activeTab !== 1 && activeTab !== 2 &&
                    <div className="table-btn student-btn">
                        <button type="button" className="btn btn-blue modal-link"
                                data-modal="#avatarModal">Сохранить
                        </button>
                    </div>
                }*/}

            </div>
        </div>
    );
};

export default React.memo(AdminCreateStudent);
