import { useEffect, useState } from "react";

export const usePagination = (
  url,
  requestOptions,
  sort = "id,desc",
  scrollElementId = null,
  otherParams={},
  paramsInUrl=false
) => {
  const [data, setData] = useState([]);
  const [currentPage, setCurrentPage] = useState(0);
  const [fetching, setFetching] = useState(true);
  const [totalCount, setTotalCount] = useState(0);
  const [currentPortion, setCurrentPortion] = useState([]);
  const [loading, setLoading] = useState(false);
  const scrollElement = document.getElementById(scrollElementId) || document;

  const scrollHandler = (e) => {
    const isExistElement = !!document.getElementById(scrollElementId);
    const params = {
      scrollHeight: isExistElement
        ? e.target.scrollHeight
        : e.target.documentElement.scrollHeight,
      scrollTop: isExistElement
        ? e.target.scrollTop
        : e.target.documentElement.scrollTop,
      innerHeight: isExistElement ? e.target.clientHeight : window.innerHeight,
    };
    if (
      params.scrollHeight - (params.scrollTop + params.innerHeight) < 10 &&
      data.length < totalCount
    ) {
      setFetching(true);
    }
  };

  const refresh = () => {
    setData([]);
    setCurrentPage(0);
    setFetching(true);
  };

  useEffect(refresh, [requestOptions]);

  useEffect(() => {
    scrollElement.addEventListener("scroll", scrollHandler);

    return () => {
      scrollElement.removeEventListener("scroll", scrollHandler);
    };
  }, [totalCount, data]);
  const loadALl = () =>{
    setLoading(true);
    fetch(url(currentPage, 10, sort,otherParams,paramsInUrl), requestOptions)
        .then((response) => response.json())
        .then((json) => {
          setData([...data, ...json.content]);
          setCurrentPortion([...json.content]);
          setCurrentPage((prevState) => prevState + 1);
          setTotalCount(json.totalElements);
        })
        .catch(() => console.log("Произошла ошибка"))
        .finally(() => {
          setFetching(false);
          setLoading(false);
        });
  }
  useEffect(() => {
    if(Object.keys(otherParams).length > 0 && !fetching){
      refresh()
    }
  },[otherParams])
  useEffect(() => {
    if (fetching) {
      loadALl();
    }
  }, [fetching]);

  return {
    data,
    currentPortion,
    loading,
    totalCount,
    refresh,
  };
};
