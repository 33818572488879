import "../assets/styles/components/competencies_table.scss";
import React from "react";

import { Button } from "@mui/material";

import { calcMethod } from "@constants/links";

const CompetenciesTable = () => {
  const handleMorePress = () => {
    window.open(calcMethod, "_blank");
  };

  return (
    <div className="competencies_table">
      <div className="competence_item">
        <h2>Эффективная коммуникация</h2>
        <p className="description">
          Способность доносить необходимую информацию, качественно обмениваться
          знаниями или сведениями посредством вербального и невербального
          общения
        </p>
        <ul className="achievements">
          <li>Владение языком</li>
          <li>Участие в волонтерских движениях</li>
          <li>Получение гранта</li>
          <li>Информационное обеспечение мероприятий</li>
          <li>Получение награды (культура, наука, спорт)</li>
          <li>Прохождение научной школы и(или) стажировки</li>
          <li>Организация мероприятий</li>
          <li>Публикация результатов научной деятельности</li>
          <li>Публичное представление результатов своей деятельности</li>
          <li>Оформление прав на РИД</li>
          <li>Участие в деятельности студенческих сообществ</li>
          <li>
            Участие в культурно-творческих, общественных, профильных и
            спортивных мероприятиях
          </li>
        </ul>
      </div>
      <div className="competence_item">
        <h2>Критическое мышление</h2>
        <p className="description">
          Способность взвешенно перерабатывать и потреблять информацию из разных
          источников, самостоятельно находить решение проблем, аргументированно
          отстаивать свою точку зрения участие в волонтерских движениях
        </p>
        <ul className="achievements">
          <li>Участие в волонтерских движениях</li>
          <li>Получение гранта</li>
          <li>Получение награды (наука)</li>
          <li>Организация мероприятий</li>
          <li>Публикация результатов научной деятельности</li>
          <li>Публичное представление результатов научной деятельности</li>
          <li>Оформление прав на РИД</li>
          <li>Прохождение стажировки</li>
          <li>
            Участие в деятельности студенческих сообществ, а также в
            общественных мероприятиях
          </li>
          <li>Участие в профильных мероприятиях</li>
        </ul>
      </div>
      <div className="competence_item">
        <h2>Межкультурное взаимодействие</h2>
        <p className="description">
          Способность общаться с представителями различных культур и
          национальностей посредством языка, речи и письменности
        </p>
        <ul className="achievements">
          <li>
            Аналогично компетенции «Коммуникации», но акцент ставится на знание
            и владение иностранными языками
          </li>
        </ul>
      </div>
      <div className="competence_item">
        <h2>Достижение научных результатов</h2>
        <p className="description">
          Способность выявлять научные проблемы и находить лучшее решение из
          возможных вариантов, получая конкретные результаты
        </p>
        <ul className="achievements">
          <li>Получение гранта</li>
          <li>Получение награды (наука)</li>
          <li>Прохождение научной школы и(или) стажировки</li>
          <li>Публикация результатов научной деятельности</li>
          <li>Публичное представление результатов научной деятельности</li>
          <li>Оформление прав на РИД</li>
        </ul>
      </div>
      <div className="competence_item">
        <h2>Разработка и реализация проектов</h2>
        <p className="description">
          Способность участвовать в создании проектов и управлять ими участие в
          волонтерских движениях
        </p>
        <ul className="achievements">
          <li>Получение гранта</li>
          <li>Информационное обеспечение мероприятий</li>
          <li>Получение награды (наука)</li>
          <li>Организация мероприятий</li>
          <li>Оформление прав на РИД</li>
          <li>
            Участие в деятельности студенческих сообществ, а также в
            культурно-творческих и общественных мероприятиях
          </li>
        </ul>
      </div>
      <div className="competence_item">
        <h2>Публичное представление результатов</h2>
        <p className="description">
          Способность описывать и формулировать результаты своей деятельности,
          представляя их общественности
        </p>
        <ul className="achievements">
          <li>Получение награды (наука)</li>
          <li>Публикация результатов научной деятельности</li>
          <li>
            Публичное представление результатов культурно-творческой и научной
            деятельности
          </li>
          <li>Оформление прав на РИД</li>
          <li>Участие в культурно-творческих мероприятиях</li>
        </ul>
      </div>
      <div className="competence_item">
        <h2>Осознанное отношение к своему здоровью</h2>
        <p className="description">
          Способность критически оценивать свое физическое состояние и осознанно
          вести активный образ жизни{" "}
        </p>
        <ul className="achievements">
          <li>Получение награды (спорт)</li>
          <li>Участие в спортивных мероприятиях</li>
        </ul>
      </div>
      <div className="competence_item">
        <h2>Социальная активность</h2>
        <p className="description">
          Способность личности к деятельности, которая позволяет вносить в
          общество определенные инновации, повышающие общую степень комфорта
          жизни
        </p>
        <ul className="achievements">
          <li>Участие в волонтерских движениях</li>
          <li>Информационное обеспечение мероприятий</li>
          <li>Организация культурно-творческих мероприятий</li>
          <li>Организация мероприятий</li>
          <li>Организация общественных мероприятий</li>
          <li>
            Публичное представление результатов культурно-творческой
            деятельности
          </li>
          <li>
            Участие в деятельности студенческих сообществ, а также в
            культурно-творческих и общественных мероприятиях
          </li>
        </ul>
      </div>
      <div className="competence_item">
        <h2>Наставничество</h2>
        <p className="description">
          Способность развивать других, адаптировать их к новым условиям,
          делиться знаниями и предоставлять эффективную обратную связь
        </p>
        <ul className="achievements">
          <li>Участие в волонтерских движениях</li>
          <li>Получение гранта</li>
          <li>Организация мероприятий</li>
          <li>
            Публичное представление результатов культурно-творческой
            деятельности
          </li>
          <li>Публичное представление результатов научной деятельности</li>
          <li>Прохождение стажировки</li>
          <li>Участие в деятельности студенческих сообществ</li>
          <li>Участие в мероприятиях</li>
        </ul>
      </div>
      <div className={"more_link_wrapper"}>
        <Button
          variant={"contained"}
          className="open_video_button"
          onClick={handleMorePress}
        >
          Подробнее
        </Button>
      </div>
    </div>
  );
};

export default CompetenciesTable;
