import { useParams } from "react-router-dom";

import { useProfileCompany } from "../hooks/useProfileCompany";

import ProfileCompany from "./ProfilePage/components/ProfileCompany";

const CompanyFromListPage = () => {
  const params = useParams();
  const { companyData } = useProfileCompany(params.companyId);

  return (
    <div className="profile">
      <ProfileCompany companyData={companyData} />
    </div>
  );
};
export default CompanyFromListPage;
