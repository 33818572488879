import "@assets/styles/pages/shop.scss";
import React, {useEffect, useRef, useState} from "react";
import "@assets/newpages/style.scss"
import {ADMIN_STUDENTS} from "@api/endpoints";
import {useVariables} from "@hooks/useVariables";
import {usePagination} from "@hooks/usePagination";
import ModalCustom from "@components/ModalCustom";
import LogsTable from "@pages/AdminStudentList/CreateModal/LogsTable";
import LoadModal from "@pages/AdminDatabasePage/components/LoadModal";
import {toast} from "react-toastify";
import EditList from "@pages/AdminDatabasePage/components/EditList";
import Students from "@pages/AdminDatabasePage/Tabs/Students";
import Achievements from "@pages/AdminDatabasePage/Tabs/Achievements";
import {NavLink} from "react-router-dom";


const AdminStudentsList = () => {
    const [activeTab,setActiveTab] = useState(0)
    const [loadList,setLoadList] = useState(false)
    const [openedEditList,setOpenedList] = useState(false)


    const tabs = [
        "Студенты",
        "Показатели"
    ];
    const tabsElements = [
        <Students setOpenedList={setOpenedList}/>,
        <Achievements setOpenedList={setOpenedList}/>,
    ]
    return (
        <div className="newStyle logTable">
            <h1  className="">База</h1>
            <div className="tabs tabs-scroll">
                {!openedEditList &&
                    <ul style={{marginTop: 20}} className="tabsmenu">
                        {tabs.map((item, index) => (
                            <li key={index} className={`tab1 ${activeTab === index ? "active" : ""}`}
                                onClick={() => setActiveTab(index)}
                            >{item}</li>
                        ))}

                    </ul>
                }

                <div className="tab-content">
                    {tabsElements[activeTab]}

                </div>
                {/*{activeTab !== 0 && activeTab !== 1 && activeTab !== 2 &&
                    <div className="table-btn student-btn">
                        <button type="button" className="btn btn-blue modal-link"
                                data-modal="#avatarModal">Сохранить
                        </button>
                    </div>
                }*/}

            </div>
           {/* <div style={{marginTop:20}} className="scroll">
                {!editList &&
                    <LogsTable  openCreate={openCreate} statuses={statuses.data}/>
                }
                {editList &&
                <EditList sendList={loadToDB} back={back} users={activeUsers}/>}
            </div>*/}

        </div>
    );
};

export default React.memo(AdminStudentsList);
