import React from "react";
import { toast } from "react-toastify";

import { useVariables } from "@hooks/useVariables";

import { ADD_AUCTION } from "@api/endpoints";

const EditAuction = ({ product, onAdd }) => {
  const constRequestOptionsPOST = useVariables().constRequestOptionsPOST;
  const sendData = (data) => {
    console.log("### sendData", product.productId, data);
    fetch(ADD_AUCTION(product.productId), constRequestOptionsPOST(data))
      .then((response) => {
        console.log("### response", response);
        // navigate(`/profile/shop/${product.productId}`, { replace: true });
        onAdd();
      })
      .catch((error) => {
        console.log("### error", error);
        toast.error("Ошибка при добавление аукциона", {
          position: toast.POSITION.TOP_CENTER,
        });
      });
  };

  const handleSubmit = (event) => {
    console.log("### handleSubmit", event);
    event.preventDefault();
    const form = new FormData(event.target);
    console.log("### form", form);

    const values = {};

    for (const [key, value] of form) {
      console.log(key, value);
      try {
        if (["startDate", "endDate"].includes(key)) {
          values[key] = new Date(value).toISOString();
        } else {
          values[key] = value;
        }
      } catch {}
    }

    console.log("### values", values);

    sendData(values);
  };

  return (
    <form onSubmit={handleSubmit}>
      <div className="field">
        <label>Начальная цена</label>
        <input
          className="form_field"
          name="startBid"
          type="number"
          placeholder="Введите начальную цену"
        />
      </div>
      <div className="field">
        <label>Количество</label>
        <input
          className="form_field"
          name="count"
          type="number"
          placeholder="Введите количество товаров"
        />
      </div>
      <div className="field">
        <label>Дата начала</label>
        <input
          className="form_field"
          name="startDate"
          type="datetime-local"
          placeholder="Введите дату начала"
        />
      </div>
      <div className="field">
        <label>Дата окончания</label>
        <input
          className="form_field"
          name="endDate"
          type="datetime-local"
          placeholder="Введите дату окончания"
        />
      </div>
      <input className="submit_button" type="submit" value="Обновить" />
    </form>
  );
};

export default React.memo(EditAuction);
