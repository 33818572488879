import React from "react";

import FilterSelect from "./FilterSelect";

import { usePagination } from "@hooks/usePagination";
import { useVariables } from "@hooks/useVariables";

import { GET_EDU_DIRECTIONS } from "@api/endpoints";

const SCROLL_ELEMENT_ID = "directions_list";

const DirectionsFilterSelect = ({ filters, onFilterSelect }) => {
  const requestOptionsGET = useVariables().requestOptionsGET;
  const { data: directions, loading } = usePagination(
    GET_EDU_DIRECTIONS,
    requestOptionsGET,
    "name",
    SCROLL_ELEMENT_ID,
  );

  return (
    <FilterSelect
      values={directions}
      loading={loading}
      placeholder="Направление образования"
      filterName="directionIds"
      filters={filters}
      scrollId={SCROLL_ELEMENT_ID}
      onFilterSelect={onFilterSelect}
    />
  );
};

export default React.memo(DirectionsFilterSelect);
