import "@assets/styles/pages/rating.scss";
import React, {useEffect, useMemo, useState} from "react";
import _ from "lodash";

import {useRating} from "./hooks/useRating";
import {useVariables} from "@hooks/useVariables";

import CustomSelect from "@components/CustomSelect";
import SearchLine from "@components/SearchLine";

import RatingListItem from "./components/RatingListItem";
import InstitutesFilterSelect from "./components/InstitutesFilterSelect";
import QualificationsFilterSelect from "./components/QualificationsFilterSelect";
import ProgramsFilterSelect from "./components/ProgramsFilterSelect";
import DirectionsFilterSelect from "./components/DirectionsFilterSelect";
import ProfessionsFilterSelect from "./components/ProfessionsFilterSelect";

import {GET_ALL_EDU_DIRECTIONS} from "@api/endpoints";
import {useSelector} from "react-redux";
import FilterAccordion from "@pages/CareersPage/components/FilterAccordion";

const RatingPage = () => {
    const {
        rating,
        competencies,
        categories,
        applyFilters,
        redirectStudentPage,
        changeCompetenceSortHandler,
        isCustomSelectActive,
    } = useRating();
    const user = useSelector((state) => state.user.user);
    const [collapseFilter, setCollapseFilter] = useState(true);
    const [filters, setFilters] = useState({});
    const handleFilterSelect = (filterId, selectedIds) => {
        setFilters((state) => ({...state, [filterId]: selectedIds}));
    };
    const competenciesAlphabetical = useMemo(
        () => _.sortBy(competencies, ["label"]),
        [competencies],
    );

    const categoriesAlphabetical = useMemo(
        () => _.sortBy(categories, ["label"]),
        [categories],
    );

    const handleApplyClick = () => {
        applyFilters(filters);
    };
    const handleResetClick = () => {
        const emptyFilters = {};
        setFilters(emptyFilters);
        applyFilters(emptyFilters);
    };

    const handleFIOChange = (value) => {
        setFilters((oldFilters) => {
            const newFilters = {...oldFilters, name: value};
            applyFilters(newFilters);

            return newFilters;
        });
    };

    const [allEduDirections, setAllEduDirections] = useState();
    const requestOptionsGET = useVariables().requestOptionsGET;
    const getAllEduDirections = () => {
        fetch(GET_ALL_EDU_DIRECTIONS, requestOptionsGET)
            .then((response) => response.json())
            .then((json) => {
                setAllEduDirections(json);
            })
            .catch(() => console.log("Произошла ошибка"));
    };

    useEffect(() => {
        getAllEduDirections();
    }, []);

    const allDirectionIdToNameMap = useMemo(
        () =>
            allEduDirections?.reduce((all, direction) => {
                all[direction.id] = direction.name;
                return all;
            }, {}),
        [allEduDirections],
    );

    return (
        <div className="rating filtered_page page newStyle">
            <h1>Рейтинг</h1>

            <div className="rating_block filtered_page_block">
                <div className="rating_content filtered_page_content">
                    <SearchLine
                        value={filters.name}
                        onChangeValue={handleFIOChange}
                        placeholder="Поиск по ФИО"
                    />
                    <div className="sort_menu">
                        <div className="sorts">
                            <CustomSelect
                                name="competencies"
                                options={competenciesAlphabetical}
                                isMulti
                                placeholder={
                                    <span className="i-sort sort_item">
                    Сортировка по компетенциям
                  </span>
                                }
                                maxElements={3}
                                isDisabled={isCustomSelectActive.competencies}
                                onChange={(value) => {
                                    changeCompetenceSortHandler("competencies", value);
                                }}
                            />
                            <CustomSelect
                                name="activity"
                                options={categoriesAlphabetical}
                                isMulti={false}
                                placeholder={
                                    <span className="i-sort sort_item">
                    Сортировка по областям деятельности
                  </span>
                                }
                                isDisabled={isCustomSelectActive.polycoin}
                                onChange={(value) => {
                                    changeCompetenceSortHandler("categories", value);
                                }}
                            />
                        </div>
                        <button
                            className="i-filter filter_button"
                            onClick={() => setCollapseFilter(false)}
                        />
                    </div>
                    <ul className="rating_list rating">
                        {rating.length > 0 && allDirectionIdToNameMap ? (
                            rating.map((el, index) => {
                                const directions = el.directionIds?.map(
                                    (id) => allDirectionIdToNameMap[id],
                                );

                                return (
                                    <RatingListItem
                                        key={el.id}
                                        el={el}
                                        index={index}
                                        directions={directions}
                                        onClick={() => redirectStudentPage(el.id)}
                                    />
                                );
                            })
                        ) : (
                            <div className="empty">Здесь пока пусто</div>
                        )}
                    </ul>
                </div>

                <div
                    className={collapseFilter ? "filter_block active" : "filter_block opened active newStyle"}
                >
                    <div className="filter_header">
                        <h2>Фильтры</h2>
                        <p
                            className="i-right_xs_after collapse_button"
                            onClick={() => setCollapseFilter(true)}
                        >
                            Свернуть
                        </p>
                    </div>
                    <FilterAccordion title={"Институт"}>
                        <div className="filter_item" style={{width: "100%"}}>

                        <InstitutesFilterSelect
                                filters={filters}
                                onFilterSelect={handleFilterSelect}
                            />
                        </div>
                    </FilterAccordion>
                    <FilterAccordion title={"Уровень образования"}>

                        <div className="filter_item" style={{width: "100%"}}>
                        <QualificationsFilterSelect
                                filters={filters}
                                onFilterSelect={handleFilterSelect}
                            />
                        </div>
                    </FilterAccordion>
                    <FilterAccordion title={"Образовательная программа"}>
                        <div className="filter_item" style={{width: "100%"}}>
                        <ProgramsFilterSelect
                                filters={filters}
                                onFilterSelect={handleFilterSelect}
                            />
                        </div>
                    </FilterAccordion>
                    <FilterAccordion title={"Направление образования"}>

                        <div className="filter_item" style={{width: "100%"}}>
                        <DirectionsFilterSelect
                                filters={filters}
                                onFilterSelect={handleFilterSelect}
                            />
                        </div>
                    </FilterAccordion>

                    <FilterAccordion title={"Профессии"}>
                        <div className="filter_item" style={{width: "100%"}}>
                            <ProfessionsFilterSelect
                                filters={filters}
                                onFilterSelect={handleFilterSelect}
                            />
                        </div>
                    </FilterAccordion>

                    <div className="button_block">
                        <input
                            className="main_button turquoise_button"
                            type="submit"
                            value="Применить"
                            onClick={handleApplyClick}
                        />
                        <input
                            className="main_button gray_button"
                            type="reset"
                            value="Сбросить всё"
                            onClick={handleResetClick}
                        />
                    </div>
                </div>
                {/*<form
          ref={filtersFormRef}
          className="filter_block"
          onSubmit={applyFilters}
        >
          <h2>Фильтры</h2>


          </div>
        </form>*/}
            </div>
        </div>
    );
};

export default RatingPage;
