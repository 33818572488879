import React from'react';

const FilterAccordion = ({children,title,count=0}) => {
    const [open, setOpen] = React.useState(false);
    return (
        <div className={`filter-blk filter-blk-${open? "open" : "close"}`}>
            <div className="flex tabs"  onClick={() => setOpen(!open)}>
                <span className="title">
                    {title}

                </span>
                <span className={`choise choise-${open ? "open" : "close"}`}>Выбрать</span>
            </div>
            {open && children}
        </div>
    )
}

export default React.memo(FilterAccordion);