import "@assets/styles/pages/shop.scss";

import React, {useEffect, useState} from "react";
import "@assets/newpages/style.scss"
import {NavLink, useLocation, useNavigate, useParams} from "react-router-dom";
import {useSelector} from "react-redux";
import {useVariables} from "@hooks/useVariables";
import {usePagination} from "@hooks/usePagination";
import {GET_COMPETENCIES, GET_OFFERS_CITIES, GET_OFFERS_SPECIALIZATIONS} from "@api/endpoints";
import {createOffer, loadOfferById, removeOffer, updateOffer} from "@store/asyncActions/offers";
import {toast} from "react-toastify";
import {offersLanguages} from "@utils/languageList";
import OfferType from "@pages/CreateCareerPage/components/offerType";
import CustomSelect from "@components/CustomSelect";
import Loader from "@components/Loader";
import ModalCustom from "@components/ModalCustom";
import CreateOrEdit from "@pages/AdminCreateCareerPage/Tabs/CreateOrEdit";
import Responses from "@pages/AdminCreateCareerPage/Tabs/Responses/Responses";


const AdminCreateCareerPage = () => {
    const [offer, setOffer] = useState();
    const previousLocationPathname = useLocation().state?.previousLocationPathname

    const params = useParams();
    const navigate = useNavigate();
    const [activeTab, setActiveTab] = useState(0);
    const updateOffer = (col,val) => {
        let of = {...offer};
        of[col] = val;
        setOffer({...of});
    }
    const tabs = [
        <CreateOrEdit offer={offer} setOffer={setOffer}/>,
        <Responses updateOfferState={updateOffer} offer={offer}/>,
    ]

    return (
        <div className="newStyle">
            <NavLink to={previousLocationPathname} onClick={()=> {
                if(!previousLocationPathname)
                    navigate(-1);
            }}>
                <a className="btn-back">{params.careerId >= 0 ? "Редактирование предложения" : "Создание предложения"}</a>
            </NavLink>

            <div className="tab-block tab-edit">
                <ul className="unstyled tabs">
                    <li className={activeTab === 0 && "active"} onClick={() => setActiveTab(0)}>Основная информация</li>
                    {params.careerId &&
                        <li className={activeTab === 1 && "active"} onClick={() => setActiveTab(1)}>Отклики</li>
                    }

                </ul>
            </div>
            {tabs[activeTab]}
        </div>
    );
};

export default React.memo(AdminCreateCareerPage);
