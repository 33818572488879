import React from'react';


const EditOrCreateModal = ({contact,changeData,save,setErrors,close,edit=false,errors}) => {
    const getError = (field,text=false) =>{
        return errors && errors.hasOwnProperty(field)  &&  errors[field] ?  (text ?
            <label htmlFor="" className="error">{errors[field]}</label>
            :"form-error") : ""
    }
    const focusInput  =  field  =>  {
        let errs = {...errors}
        if(errs[field])
            delete errs[field];
        setErrors(errs);
    }
    return (
        <div className="newStyle">
            <div className={"flex flex-col gap-15"}>
                <div className={`search-blk create-blk ${getError("secondName")}`}>
                    <label htmlFor="Name2">Фамилия </label>
                    <input onFocus={()=>focusInput("secondName")} value={contact?.secondName} data-field={"secondName"}
                           onChange={changeData} type="text"
                           placeholder="Иван" id="Name2"/>
                    {getError("secondName",true)}
                </div>
                <div className={`search-blk create-blk ${getError("firstName")}`}>
                    <label htmlFor="Name2">Имя </label>
                    <input onFocus={()=>focusInput("firstName")} value={contact?.firstName} data-field={"firstName"}
                           onChange={changeData} type="text"
                           placeholder="Иванов" id="Name2"/>
                    {getError("firstName",true)}
                </div>
                <div className={`search-blk create-blk ${getError("middleName")}`}>
                    <label htmlFor="Name2">Отчество </label>
                    <input onFocus={()=>focusInput("middleName")} value={contact?.middleName} data-field={"middleName"}
                           onChange={changeData} type="text"
                           placeholder="Иванович" id="Name2"/>
                    {getError("middleName",true)}
                </div>
                <div className={`search-blk create-blk ${getError("email")}`}>
                    <label htmlFor="Email">Почта </label>
                    <input onFocus={()=>focusInput("email")} value={contact?.email} data-field={"email"}
                           onChange={changeData} type="email"
                           placeholder="example@mail.com" id="Email"/>
                    {getError("email",true)}
                </div>
                <div className={`search-blk create-blk ${getError("phoneNumber")}`}>
                    <label htmlFor="phone">Номер телефон</label>
                    <input onFocus={()=>focusInput("phoneNumber")} value={contact?.phoneNumber} data-field={"phoneNumber"}
                           onChange={changeData} type="text"
                           placeholder="7999999999" id="phone"/>
                    {getError("phoneNumber",true)}
                </div>
                <div className={`search-blk create-blk ${getError("position")}`}>
                    <label htmlFor="position">Должность</label>
                    <input onFocus={()=>focusInput("position")} value={contact?.position} data-field={"position"}
                           onChange={changeData} type="text"
                           placeholder="Директор" id="position"/>
                    {getError("position",true)}
                </div>
            </div>
            <div className="table-btn student-btn btn-all">
                <button onClick={close} className="btn btn-grey">Отменить</button>
                <button onClick={save} type="submit" className="btn btn-blue">
                    {edit ? "Сохранить" : "Добавить"}
                </button>
            </div>

        </div>
    )
}
export default EditOrCreateModal;