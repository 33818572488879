import React from "react";
import styled from "styled-components";

import CompaniesListHeader from "./components/CompaniesListHeader";
import CompaniesList from "./components/CompaniesList";

const CompaniesTable = ({companies}) => {
    return (
        <div className="scroll" style={{marginTop: 20}}>
            <table className="tables table-list">
                <CompaniesListHeader/>
                <CompaniesList companies={companies}/>
            </table>
        </div>
    );
};

export default CompaniesTable;

const TableScrollWrapper = styled.div`
  overflow: auto;
  box-shadow: 0 0 12px 1px rgba(0, 0, 0, 0.2);
  border-top-left-radius: 25px;
  border-top-right-radius: 25px;
`;
