import React from "react";

import { useDownloadFile } from "@hooks/useDownloadFile";
import getProductStatus from "@utils/getProductStatus";

import defaultProduct from "@assets/images/product-default.png";

import { DOWNLOAD_FILE } from "@api/endpoints";

const ProductCompanyListItem = ({ product }) => {
  const img = useDownloadFile(
    DOWNLOAD_FILE,
    product.imgId,
    defaultProduct,
  ).file;

  return (
    <li className="list_item">
      <div className="image">
        <img src={img} alt="product_image" />
      </div>
      <div className="list_info">
        <p className="name">{product.displayName}</p>
        <p className="description">{product.description}</p>
        <button className="status">{getProductStatus(product)?.name}</button>
      </div>
    </li>
  );
};

export default React.memo(ProductCompanyListItem);
