import React, {useState} from 'react';
import {useVariables} from "@hooks/useVariables";
import {usePagination} from "@hooks/usePagination";
import {ADMIN_EDUCATION, ADMIN_STUDENTS} from "@api/endpoints";
import {getDateInInput} from "@utils/other";

const Programs= ({update,creat_,remove,data}) => {


    return (
        <table className="tables logs-table">
            <thead>
            <tr>
                <th style={{width: 100}} className="add">
                    <a onClick={()=>{
                        creat_()
                    }} className="btn btn-blue modal-link"></a>
                </th>
                <th>Название</th>
                <th>Код</th>
            </tr>
            </thead>

            <tbody id={""} style={{maxHeight: 50, height: 20}}>
            {data && data.data.map((programs, index) => {
                return (
                    <tr key={index}>
                        <td>
                            <ul className="unstyled">
                                <li>
                                    <a className="edit" onClick={()=>update(programs)}>
                                    </a>
                                </li>
                                <li><a onClick={()=>remove(programs)} className="delete"></a></li>
                                <li></li>
                            </ul>

                        </td>
                        <td>
                            {programs.name}
                        </td>
                        <td>
                            {programs.code}
                        </td>
                    </tr>
                )
            })}
            </tbody>

        </table>
    )

}
export default Programs;