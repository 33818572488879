const initialState = {
    chatList: [],
    selectedChat: {
        chatId: null,
        authors: null,
        messages: [],
    }
};

const LOAD_CHAT_LIST = "LOAD_CHAT_LIST";
const UPDATE_CHAT_LIST = "UPDATE_CHAT_LIST";
const LOAD_SELECTED_CHAT = "LOAD_SELECTED_CHAT";

export const messengerReducer = (state = initialState, action) => {
    switch (action.type) {
        case LOAD_CHAT_LIST:
            return {
                ...state,
                chatList: [...state.chatList, ...action.payload]
            }
        case UPDATE_CHAT_LIST: {
            const updatedChatList = state.chatList.filter(chat => chat.chatId !== action.payload.chatId);
            updatedChatList.unshift(action.payload)
            return {
                ...state,
                chatList: updatedChatList
            }
        }
        case LOAD_SELECTED_CHAT:
            return {
                ...state,
                selectedChat: {
                    ...state.selectedChat,
                    chatId: action.payload.chatId,
                    authors: action.payload.authors,
                    messages: [...state.selectedChat.messages, ...action.payload.messages]
                }
            }
        default:
            return state
    }
}

export const loadChatListAction = (payload) => ({type: LOAD_CHAT_LIST, payload});
export const updateChatListAction = (payload) => ({type: UPDATE_CHAT_LIST, payload});
export const loadSelectedChatAction = (payload) => ({type: LOAD_SELECTED_CHAT, payload});

