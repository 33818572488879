import React, {useEffect, useMemo, useState} from 'react';
import CustomSelect from "@components/CustomSelect";
import {GET_SUBSCRIPTIONS} from "@api/endpoints";
import {useVariables} from "@hooks/useVariables";
import {getDateInInput} from "@utils/other";


const EditOrCreateModal = ({tariff,editTariff,closeModal}) => {
    const refVal = useMemo(()=>{
        if(tariff)
            return {
                value:tariff.subscriptionId,
                label:tariff.subscriptionName
            }
    },[tariff])
    const edit = !!tariff;
    const [selectValue,setSelectValue] = useState(null);
    const [date,setDate] = useState(tariff && tariff.endDate ? new Date(tariff.endDate) : new Date());
    const [errors,setErrors] = useState();
    const [tariffs,setTariffs] = useState();
    const requestGet = useVariables().requestOptionsGET
    useEffect(()=>{
        fetch(GET_SUBSCRIPTIONS,requestGet).then(res => res.json()).then(data => {
            setTariffs(data.content.sort((a,b)=>a.subscriptionId - b.subscriptionId))
        })
    },[])
    const getError = (field,text=false) =>{
        return errors && errors.hasOwnProperty(field)  &&  errors[field] ?  (text ?
            <label htmlFor="" className="error">{errors[field]}</label>
            :"form-error") : ""
    }
    const focusInput  =  field  =>  {
        let errs = {...errors}
        if(errs[field])
            delete errs[field];
        setErrors(errs);
    }
    const save = () =>{
        if(!date){
            setErrors({...errors,date:"Введите дату окончания"});
            return;
        }
        if(date <= new Date()){
            setErrors({...errors,date:"Дата окончания должна быть больше текущей даты"});
            return;
        }
        editTariff(selectValue,date);
    }

    return (
        <div className="newStyle">
            <div className={"flex flex-col gap-15"}>
                <div className={`search-blk create-blk ${getError("secondName")}`}>
                    <label htmlFor="Name2">Тариф </label>
                    <CustomSelect
                        onChange={(x)=>setSelectValue(x)}
                        placeholder={"Выберите тариф"}
                        options={tariffs && tariffs.map(x=>{
                            return {
                                label: x.subscriptionName,
                                value: x.subscriptionId
                            }
                        })}
                        refValue={refVal}
                    />
                </div>
                <div className={`search-blk create-blk ${getError("date")}`}>
                    <label htmlFor="Name2">Дата окончания </label>
                        <input
                            onFocus={()=>focusInput("date")}
                            value={typeof date === "object" ? getDateInInput(date,true) : date}
                            onChange={(e)=>setDate(new Date(e.target.value))}
                            className="form_field"
                            name="endDate"
                            type="datetime-local"
                            placeholder="Введите дату окончания"
                        />
                    {getError("date",true)}
                </div>

            <div className="table-btn student-btn btn-all">
                <button onClick={closeModal} className="btn btn-grey">Отменить</button>
                <button onClick={save} type="submit" className="btn btn-blue">
                    {edit ? "Сохранить" : "Добавить"}
                </button>
            </div>

        </div>
        </div>
    )
}
export default EditOrCreateModal;