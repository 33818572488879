import "@assets/styles/pages/shop.scss";

import React, {useEffect, useRef, useState} from "react";
import "@assets/newpages/style.scss"
import {NavLink, useLocation, useNavigate, useParams} from "react-router-dom";
import {useDispatch, useSelector} from "react-redux";
import {ADMIN_STUDENTS} from "@api/endpoints";
import {useVariables} from "@hooks/useVariables";
import {usePagination} from "@hooks/usePagination";
import StudentTable from "@pages/AdminStudentList/components/StudentTable";
import StudentBlock from "@pages/AdminStudentList/components/StudentBlock";
import Search from "./components/Search";
import ModalCustom from "@components/ModalCustom";
import ButtonWithLoading from "@components/ButtonWithLoading";
import {toast} from "react-toastify";
import CreateModal from "@pages/AdminStudentList/CreateModal/CreateModal";


const AdminStudentsList = () => {
    const [filters,setFilters] = useState({});
    const [loadList,setLoadList] = useState(false)
    const [activeModal,setActiveModal] = useState(false)
    const requestGet = useVariables().requestOptionsGET
    const students = usePagination(ADMIN_STUDENTS.GET_ALL,requestGet,"studentId,desc",null,filters);

    return (
        <div className="newStyle ">
              <Search/>
            <div style={{marginTop:20}} className="scroll">
               <StudentTable openModal={()=> {
                   setLoadList(false)
                   setActiveModal(true)
               }}>
                   {students && students.data && students.data.map((item,index)=>(
                       <StudentBlock key={index} user={item}/>
                   ))}
               </StudentTable>
            </div>
            <ModalCustom
                title={"Добавление студента"}
                active={activeModal}
                contentId={"logTable"}
                className={"autoWidth crateStudentModal newStyle"}
                setActive={setActiveModal}
            >
                <CreateModal setActiveModal={setActiveModal} />


            </ModalCustom>
        </div>
    );
};

export default React.memo(AdminStudentsList);
