import React from 'react';


const FiltersCompetences = ({filters, specs, checkIfActive,removeFilter, selectFilter}) => {
    const getSelectedSpecs = () => {
        if (filters && filters["competenceId"]) {
            return specs.filter(city => filters["competenceId"].includes(city.competenceId));
        }
        return []
    }
    const getNonSelectedSpec = () => {
        if (filters && filters["competenceId"])
            return specs.filter(city => !filters["competenceId"].includes(city.competenceId));
        else return specs;
    }
    return (
        <>
            <ul className="tag active tag-choice unstyled">
                {getSelectedSpecs().map((item) => (
                    <li
                        className={`${checkIfActive("competenceId",specs.competenceId)} tab`}>
                        {item.competenceName}
                        <button className="btn-close"
                                data-type={"competenceId"}
                                data-arr={"true"}
                                data-value={item.competenceId}
                                onClick={selectFilter}
                        ></button>
                    </li>
                ))}
            </ul>

            <ul className="tabs tag tag-active unstyled">
                {specs && getNonSelectedSpec().map((item) => (
                    <li
                    data-type={"competenceId"}
                    data-arr={"true"}
                    data-value={item.competenceId}
                    onClick={selectFilter}
                    className={`${checkIfActive("competenceId",item.competenceId)} tab`}>
                        {item.competenceName}
                    </li>
                    ))}
            </ul>
        </>
    )
}

export default React.memo(FiltersCompetences);