import React from "react";

import ChatButton from "./ChatButton";

const ChatsPanel = ({ chats, selectedChat, selectChat }) => {
  return (
    <div className="message_list scroll_element">
      {chats?.length ? (
        <ul>
          {chats.map((el) => {
              return (
                  <ChatButton
                      key={el?.chatId}
                      el={el}
                      selectedChat={selectedChat}
                      selectChat={selectChat}
                  />
              )

          })}
        </ul>
      ) : (
        <div className="empty_list">Пока никого нет</div>
      )}
    </div>
  );
};

export default React.memo(ChatsPanel);
