import React from "react"
import {responseLanguages} from "@utils/languageList";

const StudentVacancyStatus = (status) => {
    const getIcon = () =>{
        switch (status) {
            case "CONSIDERATION"://На рассмотрении
                return "i-response-consideration";

            case "REJECTED_BY_COMPANY"://Отклонен компанией
                    return "i-close";

            case "CANCELED_BY_STUDENT"://Отклонен студентом
                return "i-close";

            case "VIEWED"://Просмотрен
                return "i-response-viewed";

            case "NEGOTIATION"://Направлено приглашение
                return "i-response-approved type-green-noicon";

            case "APPROVED"://Утверждено компанией
                return "i-response-approved type-green-noicon";
        }
    }

    return (
        <span className={`status ${getIcon()}`}>
             {responseLanguages.status[status]}
        </span>
    )
}
export default  StudentVacancyStatus;