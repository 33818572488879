import {toast} from "react-toastify";
import {
    GET_OFFERS_TYPES,
    GET_OFFERS_LIST,
    CREATE_OFFER,
    GET_OFFERS_JOB_TYPE,
    GET_OFFERS_TIME_TYPES,
    GET_OFFERS_INFO,
    UPDATE_OFFER,
    GET_OFFERS_BY_USER_ID,
    GET_OFFERS_BY_ID,
    DELETE_OFFER,
    GET_CITY_BY_ID,
    GET_RESPONSES_BY_OFFER_ID, GET_COMPANIES_BY_NAME, GET_OFFERS_CITIES, CREATE_OFFER_RESPONSE, UPDATE_RESPONSE
} from "@api/endpoints";
import {
    setOffersListAction, setOffersReferenceAction, setUserOffersAction,
} from "../reducers/offersReducer";
import {usePagination} from "@hooks/usePagination";


export const changeOfferResponseStatus = async (savedUser,offerId,status)=>{
    let cfg = {
        method: "PUT",
        headers: {
            "Content-Type": "application/json",
            Authorization: `${savedUser.type} ${savedUser.accessToken}`,
        },
        body:  JSON.stringify({jobResponseStatus:status}),
    };
    const response = await (await fetch(UPDATE_RESPONSE(offerId), cfg)).json();
    return response;

}

export const getOfferList = async (savedUser,page, size, sort="",otherParams={},paramsInUrl=true) =>{
    let cfg = {
        method: "GET",
        headers: {
            "Content-Type": "application/json",
            Authorization: `${savedUser.type} ${savedUser.accessToken}`,
        },
    };
    const response = await (await fetch(GET_OFFERS_LIST(page,size,sort,otherParams,paramsInUrl), cfg)).json();
    return response;

}


export const createOfferResponse = async (savedUser, offerId) => {
    let cfg = {
        method: "POST",
        headers: {
            "Content-Type": "application/json",
            Authorization: `${savedUser.type} ${savedUser.accessToken}`,
        },
    };
    const response = await (await fetch(CREATE_OFFER_RESPONSE(offerId), cfg)).json();
    return response;
}