import React, { useEffect, useState } from "react";
import {useDispatch, useSelector} from "react-redux";

import { usePagination } from "./usePagination";
import { useVariables } from "./useVariables";
import { useTeamInteraction } from "./useTeamInteraction";

import {
  GET_COMMAND,
  GET_PORTFOLIO,
    GET_PORTFOLIO_BY_COMPANY_ID,
    GET_PORTFOLIO_BY_STUDENT_ID,
  GET_PRODUCT_LIST_OF_COMPANY,
  GET_PROFILE,
  GET_VACANCY_LIST_OF_COMPANY,
} from "../api/endpoints";
import {updateCompanyProfileAction} from "@store/reducers/companyReducer";

export const useProfileCompany = (companyId) => {
  const user = useSelector((state) => state.user.user);
  const requestOptionsGET = useVariables().requestOptionsGET;
  const [company, setCompany] = useState({});
  const dispatch = useDispatch();
  useEffect(() => {
    if (user.professions) {
      setCompany((state) => ({
        ...state,
        professions: user.professions,
      }));
    }
  }, [user.professions]);


  useEffect(() => {
    if (user.newAvatar) {
      setCompany((state) => ({
        ...state,
        newAvatar: user.newAvatar,
      }));
    }
  }, [user.newAvatar]);



  const team = usePagination(GET_COMMAND, requestOptionsGET).data;



  const getCompanyProfile = () => {
    const url = GET_PORTFOLIO_BY_COMPANY_ID(companyId);//companyId === user.id ? GET_PORTFOLIO_BY_COMPANY_ID(companyId) : GET_PORTFOLIO_BY_STUDENT_ID(companyId);
    console.log(url,"IFETCJ");
    fetch(url, requestOptionsGET)
      .then((response) => response.json())
      .then((json) => {
        console.log(json)
        setCompany(json)
        dispatch(updateCompanyProfileAction({
          company:json,
          team,
          products,
          vacancies,
        }))
      })
      .catch(() => console.log("Не удается подключиться к серверу"));
  };

  const products = usePagination(
    GET_PRODUCT_LIST_OF_COMPANY(companyId),
    requestOptionsGET,
    "productId,desc",
  ).data;
  const vacancies = usePagination(
    GET_VACANCY_LIST_OF_COMPANY(companyId),
    requestOptionsGET,
    "id,desc",
  ).data;

  const knockToCommand = useTeamInteraction(setCompany).knockToCommand;
  const leaveCommand = useTeamInteraction(setCompany).leaveCommand;
  const approveInvitation = useTeamInteraction(setCompany).approveInvitation;

  const getButtonForStudent = () => {
    switch (company.inviteStatus) {
      case "NO_INVITE":
        return (
          <button
            className="main_button turquoise_button"
            onClick={() => knockToCommand(companyId)}
          >
            Постучаться в команду
          </button>
        );
      case "COMPANY_INVITE":
        return (
          <button
            className="main_button turquoise_button"
            onClick={() => approveInvitation(company.invitationId)}
          >
            Принять приглашение в команду
          </button>
        );
      case "STUDENT_KNOCK":
        return (
          <button className="main_button disabled_button">
            Запрос отправлен
          </button>
        );
      case "APPROVED":
        return (
          <button
            className="main_button turquoise_button"
            onClick={() => leaveCommand(companyId)}
          >
            Покинуть команду
          </button>
        );
      case "REQUEST_SENT":
        return (
          <button className="main_button disabled_button">
            Запрос отправлен
          </button>
        );
      default:
        return null;
    }
  };

  useEffect(() => {
    getCompanyProfile();
  }, []);

  useEffect(() => {

  },[company,team,vacancies,products])

  const companyData = {
    company,
    team,
    products,
    vacancies,
    knockToCommand,
    leaveCommand,
    approveInvitation,
    getButtonForStudent,
  };


  return {
    companyData,
  };
};
