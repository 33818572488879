import "@assets/styles/pages/login.scss";
import { ScrollRestoration } from "react-router-dom";
import React, {useEffect} from "react";
import { ToastContainer } from "react-toastify";

import { useSavedUser } from "@hooks/useAuth";

import HeaderBlock from "./components/HeaderBlock";
import LoginBlock from "./components/LoginBlock";
import PromotionBlock from "./components/PromotionBlock";

const LoginPage = () => {
  useSavedUser();

    // scroll to top of page after a page transition.
    useEffect(() => {
        document.documentElement.scrollTo({ top:0, left:0, behavior: "instant" });
    }, []);
  return (
    <div className="login">
      <ToastContainer />
      <HeaderBlock />
      <div className="content_blocks">
        <PromotionBlock />
        <LoginBlock />
      </div>
    </div>
  );
};

export default React.memo(LoginPage);
