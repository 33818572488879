import React from'react';

const CustomTabs = ({tabs,activeTab,setActiveTab,className="tabs"}) => {
    return (
        <ul className={className}>
            {tabs.map((tab,index) => {
                return (
                    <li onClick={() => setActiveTab(tab.id)} key={index} className={activeTab === tab.id ? 'active' : ''}>
                       {tab.title}
                    </li>
                )
            })}
        </ul>
    )
}
export default CustomTabs;