import { useState } from "react";

export const usePreviewFile = (defaultFile, type) => {
  const [selectedFile, setSelectedFile] = useState(defaultFile);

  const handleFileChange = (e) => {
    const file = e.target.files[0];
    if (file && file.type.match(type)) {
      const reader = new FileReader();
      reader.onload = (e) => {
        setSelectedFile(e.target.result);
      };
      reader.readAsDataURL(file);
    } else {
      setSelectedFile(defaultFile);
    }
  };

  return {
    selectedFile,
    setSelectedFile,
    handleFileChange,
  };
};
