import React, {useRef, useState} from 'react';
import {NavLink} from "react-router-dom";
import ButtonWithLoading from "@components/ButtonWithLoading";
import {ADMIN_STUDENTS} from "@api/endpoints";
import {toast} from "react-toastify";
import CustomTabs from "@components/CustomTabs";
import {useVariables} from "@hooks/useVariables";
import InputFile from "@components/InputFile";
import LogsTable from "@pages/AdminStudentList/CreateModal/LogsTable";
import {usePagination} from "@hooks/usePagination";

const CreateModal = ({setActiveModal}) => {
    const [modalEmail,setModalEmail] = useState("")
    const requestGet = useVariables().requestOptionsGET;
    const statuses = usePagination(ADMIN_STUDENTS.GET_FILES_LOGS,requestGet,"","logTable")
    const requestPost = useVariables().constRequestOptionsPOST
    const inputRef = useRef();
    const [activeTab, setActiveTab] = React.useState(0)
    const tabs = [
        {id: 0, title: "Добавление студента"},
        {id: 1, title: "Загрузка базы"},
        {id: 2, title: "Загрузка достижений"},
    ]
    function decode_utf8(s) {
        return decodeURIComponent(escape(s));
    }
    const readFile = (e) => {
        const reader = new FileReader()
        reader.onload = () => {
            let res = decode_utf8(reader.result).split(/\r|\n|\r\n/);
            res = res.splice(1,res.length);
            res = res.filter(item => item).map(item => {
                if (item)
                    return item.split(";")
            });
            res = res.filter(item => item.find(x=>x))
            res = res.map(item => {
                if (item)
                    return {
                        "firstName": item[0],
                        "middleName": item[1],
                        "secondName": item[2],
                        "group": item[3],
                        "email": item[4],
                        "institute": item[5],
                        "educationDirection": item[6],
                        "educationProgram": item[7],
                        "qualification": item[8],
                    }
            })
            const config = requestPost({"students": res})
            fetch(ADMIN_STUDENTS.CREATE_STUDENTS_JSON, config).then(res => res.json()).then((res) => {
                if (res.status === "Success") {
                    toast.success("Список обрабатывается", {position: toast.POSITION.TOP_CENTER});
                    statuses.refresh();
                }
                else
                    toast.error("Ошибка при загрузке списка", {position: toast.POSITION.TOP_CENTER})

            })

        }

        reader.readAsBinaryString(e.target.files[0])
    }
    return (
        <div className="tabs " >
            <ul className="tabsmenu">
                <li onClick={() => setActiveTab(0)} className={activeTab === 0 ?`active`:""}>Добавление студента</li>
                <li onClick={() => setActiveTab(1)} className={activeTab === 1 ? "active":""}>Загрузка базы</li>
                <li onClick={() => setActiveTab(2)} className={activeTab === 2? "active":""}>Загрузка достижений</li>
            </ul>
            <div className="tab-content">
                {activeTab === 0 &&
                    <div className="mail-student active">
                        <h3>Корпоративный email студента</h3>
                        <div className="modal-student">
                            <input onChange={(e) => setModalEmail(e.target.value)} value={modalEmail} type="text" required placeholder="ivanov.ivan@politech.ru" style={{width: "100%"}} className="form_field" id="mailStudent"/>
                        </div>

                    </div>
                }
                {activeTab === 1 &&
                    <div className={"active"}>
                        <h3>Загрузить базу данных студентов</h3>
                        <InputFile
                            onChangeHandler={readFile}
                            chooseFileLabel={"Выберите файл в csv формате или перетащите сюда"}
                            accept={"text/csv"}
                            name={"a"}
                        />
                        <div className="logi-blk">
                            <h3>Логи</h3>
                           <LogsTable statuses={statuses.data}/>
                        </div>

                    </div>
                }
                {activeTab === 2 &&
                    <div className={"otherAchievments active"}>
                        <h3>Загрузить достижения</h3>
                        <div className="modal-add-blk">
                            <div className="adds">
                                <p><span>Компетенции</span> </p>
                                <InputFile
                                    chooseFileLabel={"Выберите файл в csv формате или перетащите сюда"}
                                    accept={"text/csv"}
                                    name={"a"}
                                />
                            </div>
                            <div className="adds">
                                <p><span>Области деятельности</span></p>
                                <InputFile
                                    chooseFileLabel={"Выберите файл в csv формате или перетащите сюда"}
                                    accept={"text/csv"}
                                    name={"a"}
                                />
                            </div>
                            <div className="adds">
                                <p><span>ДПО</span></p>
                                <InputFile
                                    chooseFileLabel={"Выберите файл в csv формате или перетащите сюда"}
                                    accept={"text/csv"}
                                    name={"a"}
                                />
                            </div>
                            <div className="adds">
                                <p><span>РСВ</span></p>
                                <InputFile
                                    chooseFileLabel={"Выберите файл в csv формате или перетащите сюда"}
                                    accept={"text/csv"}
                                    name={"a"}
                                />
                            </div>
                        </div>

                    </div>
                }

            </div>
            <div className="modal-footer">
                <button    onClick={() => setActiveModal(false)} className="btn btn-grey btn-dismiss">Отменить</button>
                {activeTab === 0 &&
                    <NavLink to={"/admin/student-create"} state={{email: modalEmail}}>
                        <button type="button" className="btn btn-blue modal-link"
                                data-modal="#avatarModal">Добавить
                        </button>
                    </NavLink>
                }
                {/*<button className="btn btn-blue">Добавить</button>*/}
            </div>
        </div>

)

}
export default CreateModal;