import React, {useEffect, useState} from 'react';
import ButtonWithLoading from "@components/ButtonWithLoading";
import {useVariables} from "@hooks/useVariables";
import {ADMIN_STUDENTS} from "@api/endpoints";
import {toast} from "react-toastify";


const Polycoins = ({data,updateData}) => {
    const [coins, setCoins] = useState(0);
    const requestPut = useVariables().constRequestOptionsPUT
    useEffect(() => {
        if (data)
            setCoins(data.balance);
    }, [data])
    const saveCoins = async () => {
        let cfg = requestPut([
            {
                "studentWalletId": data.studentWalletId,
                "walletScore": coins
            }
        ])
        let res = await fetch(ADMIN_STUDENTS.UPDATE_MARKS.WALLET, cfg);
        res = await res.json();
        if (res.status !== "Success")
            toast.error(`Произошла ошибка при сохранении`, {
                position: toast.POSITION.TOP_CENTER,
            });
        else {
            toast.success(`Баланс изменен`, {
                position: toast.POSITION.TOP_CENTER,
            });
            updateData();
        }
        return true;
    }
    return (
        <div className={"active"}>
            <h3>Количество поликоинов</h3>
            <div className="polikon-blk">
                {/* <input type="range" value="1500" max="10000" min="0" id="slider"
                       style={{"background": "linear-gradient(to right, rgb(51, 51, 51) 0%, rgb(51, 51, 51) 15%, rgb(233, 236, 239) 15%, rgb(233, 236, 239) 100%)"}}/>*/}
                <input type="number" id="sliderValue" min="0" max="10000" value={coins} onChange={(e) => {
                    if (!e.target.value) {
                        setCoins(0)
                        return
                    }
                    setCoins(e.target.value)
                }}/>
            </div>

            <div className="table-btn student-btn">
                <ButtonWithLoading
                    type={"button"}
                    className={"btn btn-blue modal-link"}
                    title={"Сохранить"}
                    onClick={saveCoins}
                />

            </div>
        </div>

    )

}
export default Polycoins;