import React, {useState} from 'react';


const PasswordInput = ({name="password"}) => {
    const [hide,setHide] = useState(true);
    return (
        <div className="pass-input">
            <input  name={name} required type={hide ? "password" : "text"} id="Password"/>
            <i onClick={() => setHide(!hide)} className={`${hide ? "i-eye-slash" : "i-eye"} hidePass`}></i>
        </div>
    )

}
export default PasswordInput;