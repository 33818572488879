import React, { useState } from "react";

import { useVariables } from "@hooks/useVariables";
import { usePagination } from "@hooks/usePagination";

import OutcomingRequest from "./OutcomingRequest";

import { GET_OUTGOINGS } from "@api/endpoints";

const OutcomingRequests = ({
  isStudent,
  isCompany,
  redirectToProfile,
  setTeamChanges,
}) => {
  const [outgoingsChanges, setOutgoingsChanges] = useState(null);
  const requestOptionsOutgoings =
    useVariables(outgoingsChanges).requestOptionsGET;
  const outgoings = usePagination(GET_OUTGOINGS, requestOptionsOutgoings).data;

  return (
    <div className="outgoings">
      {outgoings.length > 0 ? (
        outgoings.map((outgoing) => (
          <OutcomingRequest
            key={outgoing.id}
            outgoing={outgoing}
            redirectToProfile={redirectToProfile}
            isStudent={isStudent}
            isCompany={isCompany}
            setTeamChanges={setTeamChanges}
            setOutgoingsChanges={setOutgoingsChanges}
          />
        ))
      ) : (
        <div className="empty">Здесь пока пусто</div>
      )}
    </div>
  );
};

export default React.memo(OutcomingRequests);
