import "@assets/styles/pages/shop.scss";
import React, {useEffect, useMemo, useState} from "react";
import "@assets/newpages/style.scss"
import {useDispatch, useSelector} from "react-redux";
import CustomTabs from "@components/CustomTabs";
import Loader from "@components/Loader";
import {useSavedUser} from "@hooks/useAuth";
import {usePagination} from "@hooks/usePagination";
import {GET_EDU_DIRECTIONS, GET_OFFERS_BY_USER_ID, GET_OFFERS_LIST, GET_OFFERS_SPECIALIZATIONS} from "@api/endpoints";
import {offersLanguages} from "@utils/languageList";
import {useVariables} from "@hooks/useVariables";
import Table from "@pages/AdminCareersPage/components/Table";
import TableChildren from "@pages/AdminCareersPage/components/TableChildren"
import ModalCustom from "@components/ModalCustom";
import {removeOffer} from "@store/asyncActions/offers";
import Collapse from "@pages/AdminCareersPage/components/Collapse";

const AdminCareersPage = () => {
  const offers = useSelector(state => state.offers);
  const {savedUser} = useSavedUser();
  const [activeType, setActiveType] = useState(offers.offerTypes[0]);
  //const [offersList, setOffersList] = useState([]);
  const [deleteModalActive, setDeleteModalActive] = useState(false);
  // operations modal content
  const [deleteContent, setDeleteContent] = useState({});
  const initFilters = {name:"",companyName:""}
  const [filters,setFilters] = useState(initFilters)
  const [offerName,setOfferName] = useState("");
  const [companyName,setCompanyName] = useState("");
  const requestOptionsOutgoings =
      useVariables().requestOptionsGET;
  const offers_ = usePagination(GET_OFFERS_LIST, requestOptionsOutgoings,"",null,filters,true);
  const offersList = offers_.data;

  /*const offerFiltered = useMemo(() => {
    return offersList.filter(offer => {
      let pass = true;
      if(filters.search && offer.name.toLowerCase().includes(filters.search.toLowerCase())){
        pass = true;
      }else if(filters.search)
        pass = false;
      if(pass)
        return offer
    })
  },[filters,offersList])*/
  const completeFilters = () =>{
    setFilters({name:offerName,companyName: companyName})
  }
  const resetFilters = () =>{
    setOfferName("")
    setCompanyName("")
    setFilters(initFilters)
  }

  const deleteCatalogItem = () =>{
    setDeleteModalActive(false);
    removeOffer(savedUser,deleteContent?.id).then(() => {
      offers_.refresh();

    })

  }
  const removeItem = (offer) =>{
    setDeleteContent({name: offer.name, id: offer.offerId})
    setDeleteModalActive(true)
  }
  return (
      <div className={"newStyle"}>
          <Collapse
            header={"Поиск"}
          >
            <div style={{width:"100%"}}>
              <div className="flex gap-15">
                <div className="search-blk create-blk">
                  <label htmlFor="Ofer">Предложение</label>
                  <input value={offerName} onChange={(e)=>setOfferName(e.target.value)} type="text" placeholder="Название предложения" id="Ofer"/>
                </div>
                <div className="search-blk create-blk">
                  <label htmlFor="Company">Организация</label>
                  <input value={companyName} onChange={(e)=>setCompanyName(e.target.value)} type="text" placeholder="Название компании" id="Company"/>
                </div>
              </div>
              <div className="search-btn">
                <button onClick={resetFilters} type="reset" className="btn btn-reset">Сбросить</button>
                <button onClick={completeFilters} type="submit" className="btn btn-blue">Применить</button>
              </div>
            </div>
          </Collapse>



        <Table id={"offers-table"}>
          {offers_ && offers_.data && offers_.data.map((offer,index)=>(
              <TableChildren onRemove={()=>removeItem(offer)} item={offer} key={index}/>
          ))}
        </Table>
        <ModalCustom
            title="Удаление элемента"
            className={"autoWidth"}
            active={deleteModalActive}
            setActive={setDeleteModalActive}
        >
          {deleteContent && (
              <div className="delete_modal">
                <p>Вы уверены, что хотите удалить "{deleteContent.name}"?</p>
                <div className="button_block">
                  <button
                      className="reset_button"
                      onClick={() => setDeleteModalActive(false)}
                  >
                    Отмена
                  </button>
                  <button
                      className="submit_button"
                      onClick={() => deleteCatalogItem()}
                  >
                    Да
                  </button>
                </div>
              </div>
          )}
        </ModalCustom>
      </div>
  );
};

export default AdminCareersPage;
