


const UPDATE_COMPANY_PROFILE = "UPDATE_COMPANY_PROFILE";
const UPDATE_COMPANY_PROFESSIONS = "UPDATE_COMPANY_PROFESSIONS";
const UPDATE_COMPANY_CONTACTS = "UPDATE_COMPANY_CONTACTS";

export const companyReducer = (state = {}, action) => {
    switch (action.type) {

        case UPDATE_COMPANY_PROFILE: {
            return {
                ...state,
                ...action.payload,
            };
        }
        case UPDATE_COMPANY_PROFESSIONS: {
            return {
                ...state,
                company:{
                    ...state.company,
                    ...action.payload,
                }
            };
        }
        case UPDATE_COMPANY_CONTACTS:{
            let contacts = state.company.contactPersonList;
            let index = contacts.findIndex(contact => contact.contactPersonId === action.payload.contactPersonId);
            contacts[index] = {...action.payload.data};
            return  {
                ...state,
                company:{
                    ...state.company,
                    contactPersonList:contacts
                }
            }
        }
        default:
            return state;
    }
};

export const updateCompanyProfessionsActions = payload => ({
    type: UPDATE_COMPANY_PROFESSIONS,
    payload,
})


export const updateCompanyProfileAction = (payload) => ({
    type: UPDATE_COMPANY_PROFILE,
    payload,
});

export const updateCompanyContactsAction = (payload) => ({
    type: UPDATE_COMPANY_CONTACTS,
    payload,
});
