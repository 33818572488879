import "@assets/styles/pages/shop.scss";

import React, {useEffect, useRef, useState} from "react";
import "@assets/newpages/style.scss"
import {useSelector} from "react-redux";
import {toast} from "react-toastify";
import {NavLink, useNavigate, useParams} from "react-router-dom";

import {useShop} from "@hooks/useShop";
import {useVariables} from "@hooks/useVariables";

import SearchLine from "@components/SearchLine";
import ModalCustom from "@components/ModalCustom";

import {DELETE_PRODUCT, GET_COMPETENCIES} from "@api/endpoints";
import {useProfileCompany} from "@hooks/useProfileCompany";
import {getCityInfo, loadOfferById} from "@store/asyncActions/offers";
import {useSavedUser} from "@hooks/useAuth";
import {offersLanguages, responseLanguages} from "@utils/languageList";
import {changeOfferResponseStatus, createOfferResponse} from "@store/asyncActions/offersResponses";
import BubbleButton from "@components/BubbleButton";
import {PRODUCT_STATUS} from "@constants/products";
import {displayDateDDMMYYYY} from "@utils/dateFormat";

const ViewCareerPage = () => {
    const user = useSelector((state) => state.user.user);
    const [alreadyResponse, setAlreadyResponse] = useState(null);
    const params = useParams();
    const {careerId} = params;
    const {savedUser,isCompany} = useSavedUser();
    const [offer, setOffer] = useState();
    const offRef = useRef();
    offRef.current = offer;
    const [city, setCity] = useState();
    const [competency, setCompetencies] = useState([]);

    const requestOptionsOutgoings =
        useVariables().requestOptionsGET;

    useEffect(() => {
        fetch(GET_COMPETENCIES(), requestOptionsOutgoings)
            .then((response) => response.json())
            .then((json) => {
                if (!json.error)
                    setCompetencies(json)
            })
            .catch(() => console.log("Произошла ошибка"))

        if (params.careerId >= 0) {
            loadOfferById(savedUser, params.careerId).then((json) => {
                setOffer(json)
                if(json.jobResponse)
                    setAlreadyResponse(json.jobResponse)
                getCityInfo(savedUser, json.cityId).then((json) => {
                    setCity(json)
                })
            }).catch((e) => {
                console.log(e)
                toast.error("Ошибка при загрузке данных")
            })
        }
    }, [params.careerId])
    const navigate = useNavigate();
    const getDate = () => {
        if (!offer?.startDate && !offer?.endDate)
            return "Без даты";
        else if (offer?.startDate && !offer?.endDate)
            return "Начало с " + displayDateDDMMYYYY(offer?.startDate);
        else
            return "До " + displayDateDDMMYYYY(offer?.endDate);
    }

    const getSalary = (salMin,salMax)  =>  {
        if(salMin  && salMax)
            return `${offer?.minSalary} - ${offer?.maxSalary}`;
        else if(salMin)
            return `От ${offer?.minSalary}`;
        else if(salMax)
            return `До  ${offer?.maxSalary}`;
        else return "Без зарплаты";
    }

    const response = () =>{
        createOfferResponse(savedUser,params.careerId).then((json)  => {
            if(json.status === "Error"){
                setAlreadyResponse(false)
                toast.error(json.message, {
                    position: toast.POSITION.TOP_CENTER,
                });
            }else{
                let off = {...offRef.current};
                off.jobResponse = json;
                setOffer(off)
                setAlreadyResponse(json)
                toast.success("Вы откликнулись на предложение", {
                    position: toast.POSITION.TOP_CENTER,
                });
            }
        })
    }
    const cancelResponse = () =>{
        changeOfferResponseStatus(user, offer.jobResponse?.jobResponseId,"CANCELED_BY_STUDENT").then(r => {
            if (r.status && r.status === "Error") {
                toast.error("Ошибка." + r.message, {
                    position: toast.POSITION.TOP_CENTER,
                });
            } else {
                let off = {...offRef.current};
                off.jobResponse = r;
                setOffer(off)
                setAlreadyResponse(r)
                toast.success("Вы отозвали отклик", {
                    position: toast.POSITION.TOP_CENTER,
                });
            }

        })
    }
    return (
        <div className="newStyle">
            <NavLink onClick={() => navigate(-1)} end>
                <a className="btn-back">Все предложения</a>
            </NavLink>
            <div className="vacancy-student">
                <div className="row-block">
                    <div className="f-1 col-block">
                        <div className="vacancy-info blks">
                            <div className="vacancy-title">
                                <p>{offer?.companyName}</p>
                                <span>{offersLanguages.type[offer?.offerType]}</span>
                            </div>
                            <h1 className="vacancy-name-view">{offer?.name}</h1>
                            <div className="details">
                                <ul className="unstyled">
                                    <li className="time">{offersLanguages.timeJobTypes[offer?.timeJobType]}</li>
                                    <li className="city">{city && city.region}</li>
                                    <li className="place">{offersLanguages.formatJobTypes[offer?.formatJobType]}</li>
                                </ul>
                            </div>
                            <div className="btn-block">
                                <NavLink style={{width:  "100%"}} className="btn btn-grey btn-arrow" to={`/profile/profile/${offer?.authorId}`}>
                                    Профиль организации
                                </NavLink>
                                {alreadyResponse && offer && offer.jobResponse && offer.jobResponse.jobResponseStatus === "CONSIDERATION" && !isCompany &&
                                    <button style={{width: "100%"}}
                                            onClick={cancelResponse} className={`btn btn-blue btn-red`}>Отменить отклик</button>
                                }
                                {!alreadyResponse && !isCompany && (
                                    <button style={{width: "100%"}}
                                            disabled={alreadyResponse}
                                            onClick={response} className={`btn btn-blue ${alreadyResponse ? "btn_disabled" : ""}`}>Откликнуться</button>
                                )}
                            </div>
                            {alreadyResponse &&
                                <div className="response-block">
                                    <span>Вы откликнулись:</span>
                                    <p className={`response-status response-status_${alreadyResponse.jobResponseStatus}`}>
                                        {responseLanguages.status[alreadyResponse.jobResponseStatus]}
                                    </p>
                                </div>
                            }
                        </div>
                        <div className="competencies blks">
                            <h2>Компетенции</h2>
                            <ul className="unstyled tag">
                                {offer && offer.competenceIds && competency && competency.filter(x => offer.competenceIds.includes(x.competenceId)).map((competency) => (
                                    <li key={competency.competenceId}>
                                        <a>{competency.competenceName}</a>
                                    </li>
                                ))}
                            </ul>
                        </div>
                    </div>

                    <div className="f-1 col-block">
                        {offer && offer.offerType === "VACANCY" ?
                            <div className="salary blks">
                                <h2>Заработная плата</h2>
                                {offer?.salaryByAgreement ? "Заработная плата по результатам собеседования"
                                    :
                                    <p>{getSalary(offer?.minSalary,offer?.maxSalary)}</p>
                                }
                            </div> :
                            <div className="salary blks">
                                <h2>Дата проведения</h2>
                                <p>
                                    {getDate()}
                                </p>
                            </div>
                        }
                        <div className="vacancy-content blks">
                            <h2>О предложении</h2>
                            <p>
                                {offer?.description}
                            </p>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}


export default ViewCareerPage;
