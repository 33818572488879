import React, {useState} from 'react';
import CustomSelect from "@components/CustomSelect";
import {getDateInInput} from "@utils/other";
import {useVariables} from "@hooks/useVariables";
import {ADMIN_STUDENTS} from "@api/endpoints";
import {useParams} from "react-router-dom";
import {toast} from "react-toastify";

const CreateModal = ({closeModal,newDpo,dpoList,edit=false}) => {
    const params = useParams();

    const [name,setName] = useState();
    const [date,setDate] = useState();
    const requestPost = useVariables().constRequestOptionsPOST;
    const [selectedId,setSelected] = useState();
    const [errors,setErrors] = useState();
    const getError = (field,text=false) =>{
        return errors && errors.hasOwnProperty(field)  &&  errors[field] ?  (text ?
            <label htmlFor="" className="error">{errors[field]}</label>
            :"form-error") : ""
    }
    const focusInput  =  field  =>  {
        let errs = {...errors}
        if(errs[field])
            delete errs[field];
        setErrors(errs);
    }
    const save = () =>{
        if(!selectedId){
            toast.error(`Выберите дпо`, {
                position: toast.POSITION.TOP_CENTER,
            });
            return;
        }
        if(!date){
            toast.error(`Введите дату`, {
                position: toast.POSITION.TOP_CENTER,
            });
            return;
        }
        let cfg = requestPost({
            "dpoId": selectedId,
            "hours": 122,
            "year": new Date(date).getFullYear(),
        })
        fetch(ADMIN_STUDENTS.UPDATE_MARKS.DPO(params.studentId),cfg).then(res => res.json()).then(res => {
            if (res.status === "Error") {
                toast.error(res.message[0], {
                    position: toast.POSITION.TOP_CENTER,
                });

            } else {
                toast.success(`ДПО добавлено`, {
                    position: toast.POSITION.TOP_CENTER,
                });
                if(newDpo)
                    newDpo(res)
            }
            closeModal();
        })/*.catch(err => {
            toast.error(`Произошла ошибка при сохранении #2`, {
                position: toast.POSITION.TOP_CENTER,
            });
        })*/
    };
    return (
        <div className="newStyle">
            <div className={"flex flex-col gap-15"}>
                <div className={`search-blk create-blk ${getError("secondName")}`}>
                    <label htmlFor="Name2">Название</label>
                    <CustomSelect
                        options={dpoList && dpoList.map(dpo => ({label:dpo.name,value:dpo.dpoId}))}
                        placeholder={"Название"}
                        onChange={(value) => {
                            setSelected(value);
                        }}
                    />

                    {getError("name",true)}
                </div>
                <div className={`search-blk create-blk ${getError("date")}`}>
                    <label htmlFor="Name2">Дата окончания</label>
                    <input
                        onFocus={()=>focusInput("date")}
                        value={date}
                        onChange={(e)=> {
                            e.target.blur();
                            setDate(e.target.value)
                        }}
                        className="form_field"
                        type="datetime-local"
                        placeholder="Введите дату"
                    />
                    {getError("date",true)}
                </div>

                <div className="table-btn student-btn btn-all">
                    <button onClick={closeModal} className="btn btn-grey">Отменить</button>
                    <button onClick={save} type="submit" className="btn btn-blue">
                        {edit ? "Сохранить" : "Добавить"}
                    </button>
                </div>

            </div>
        </div>
    )
}
export default CreateModal;