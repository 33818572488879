import React from'react';


const Collapse = ({header,children}) => {
    const [opened, setOpened] = React.useState(true);
    return (
        <div style={{marginTop:0,cursor:"default"}} className={`search-block-offer collapse collapse-${opened? "open" : "close"}`}>
            <div className="flex">
                <h1>{header}</h1>
                <span style={{margin:0}} onClick={() => setOpened(prev =>!prev)}
                      className={`collapse collaspe-choise-${opened ? "open" : "close"}`}>Свернуть</span>
            </div>
            {children}
        </div>
    )

}
export default  React.memo(Collapse);