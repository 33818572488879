import React, { useCallback, useMemo } from "react";
import { useSelector } from "react-redux";
import styled from "styled-components";

import Avatar from "./Avatar";

const ChatButton = ({ el, selectedChat, selectChat }) => {
  const user = useSelector((state) => state.user.user);
  const { chatId, members, lastMessageText, date, newMessage } = el;

  const isSelected = selectedChat?.chatId === el.chatId;

  const memberNames = useMemo(() => {
    if(members){
      const chatMembers = members.filter((el) => el.userId !== user.id);
      const memberNames = chatMembers.map((el) => el.name);

      return memberNames.join(", ");
    }

  }, [members]);

  const messageReceivingDate = useMemo(() => {
    if(!date)
      return null;
    const msDate = new Date(date);
    const now = new Date();
    const today = `${now.getDate()}.${now.getMonth()}.${now.getFullYear()}`;
    const lastMessageDate = `${msDate.getDate()}.${msDate.getMonth()}.${msDate.getFullYear()}`;
    const lastMessageTime = `${msDate.getHours()}:${msDate.getMinutes()}`;

    return today === lastMessageDate ? lastMessageTime : lastMessageDate;
  }, [date]);

  const handleSelectClick = useCallback(() => {
    if (!selectedChat || (chatId !== selectedChat.chatId)) {
      selectChat(chatId);
    }
  }, [chatId, selectedChat, selectChat]);

  const chatMembers = el && el.members ? el.members.filter(({ userId }) => userId !== user.id) : [];

  return (
    <li
      className={isSelected ? "message active_chat" : "message"}
      onClick={handleSelectClick}
    >
      <Avatars>
        {chatMembers.map(({ avatarId, userId }, index) => (
          <Avatar
            key={userId}
            first={index === 0}
            koef={chatMembers.length}
            avatarId={avatarId}
          />
        ))}
      </Avatars>
      <div className="message_info">
        <div className="top_block">
          <p className="author">{memberNames}</p>
          {newMessage ? <div className="status"></div> : null}
        </div>
        <p className="message_content">{lastMessageText}</p>
      </div>
      <div className="time">{messageReceivingDate}</div>
    </li>
  );
};

export default React.memo(ChatButton);

const Avatars = styled.div`
  display: flex;
  flex-direction: row;
`;
