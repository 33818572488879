import "../assets/styles/components/search_line.scss";
import React, { useEffect, useRef, useState } from "react";

const SearchLine = ({
  placeholder,
  searchRef = null,
  value,
  onChangeValue = null,
  ...props
}) => {
  const [inputValue, setInputValue] = useState("");

  useEffect(() => {
    if (value !== inputValue) {
      setInputValue(value ?? "");
    }
  }, [value]);

  const timeout = useRef();
  const onChangeHandler = (e) => {
    const newValue = e.target.value;
    setInputValue(newValue);

    clearTimeout(timeout.current);

    timeout.current = setTimeout(() => {
      timeout.current = null;
      typeof onChangeValue === "function" && onChangeValue(newValue);
    }, 750);
  };

  return (
    <div className="search_line">
      <span className="search_icon i-search" />
      <input
        ref={(ref_) =>
          searchRef
            ? (searchRef.current = ref_)
            : console.log("ref is not defined ", searchRef)
        }
        className="form_field"
        type="text"
        placeholder={placeholder}
        value={inputValue}
        onChange={onChangeHandler}
        {...props}
      />
    </div>
  );
};

export default SearchLine;
