import { PRODUCT_STATUS } from "@constants/products";

export const productStatusMap = {
  [PRODUCT_STATUS.needReview]: "На рассмотрении",
  [PRODUCT_STATUS.publication]: "Опубликован",
  [PRODUCT_STATUS.archive]: "Архив",
  [PRODUCT_STATUS.draft]: "Черновик",
};

const getProductStatus = (product) => {
  const statusName = productStatusMap[product?.productStatus] || "-";

  return { name: statusName };
};

export default getProductStatus;
