import React from "react";

import { useTeamInteraction } from "@hooks/useTeamInteraction";
import { useDownloadFile } from "@hooks/useDownloadFile";
import useMessenger from "@hooks/useMessenger";

import { DOWNLOAD_FILE } from "@api/endpoints";

const TeamMember = ({
  user,
  isStudent,
  isCompany,
  redirectToProfile,
  setTeamChanges,
  setFeedbackModalContent,
  setFeedbackModal,
}) => {
  const kickFromCommand = useTeamInteraction().kickFromCommand;
  const leaveCommand = useTeamInteraction().leaveCommand;

  const openFeedback = (student) => {
    setFeedbackModal(true);
    setFeedbackModalContent(student);
  };

  const img = useDownloadFile(DOWNLOAD_FILE, user.avatarId).file;

  const { createOrGetChat } = useMessenger();
  const handleCommunicatePress = (otherUserId) => {
    console.log("### handleCommunicatePress", otherUserId);
    createOrGetChat(otherUserId, true);
  };

  return (
    <div className="student shadow_block" key={user.id}>
      <div className="student_info">
        <div className="user_picture">
          <img src={img} alt="student" />
        </div>
        <div className="name">
          {isCompany && (
            <>
              <p>{user.secondName}</p>
              <p>{user.firstName}</p>
              <p>{user.middleName}</p>
              <p className="additional">{user.course} курс</p>
            </>
          )}
          {isStudent && (
            <>
              <p>{user.name}</p>
              <p className="additional">{user.desc}</p>
            </>
          )}
        </div>
        <div className="menu_actions">
          <span className="i-hidden_menu menu_button">
            <div className="hidden_menu">
              <ul className="hidden_menu_content">
                <li onClick={() => redirectToProfile(user.id)}>
                  Перейти в цифровой профиль
                </li>
                <li onClick={() => handleCommunicatePress(user.id)}>
                  Связаться
                </li>
                <li
                  onClick={() => {
                    if (isCompany) {
                      kickFromCommand(user.id);
                    } else {
                      leaveCommand(user.id);
                    }
                    setTeamChanges(user.id);
                  }}
                >
                  {isCompany ? "Убрать из команды" : "Покинуть команду"}
                </li>
              </ul>
            </div>
          </span>
        </div>
      </div>
      {isCompany && (
        <>
          <button
            className={
              user.feedbackExist ? "submit_button active" : "submit_button"
            }
            onClick={() => openFeedback(user)}
          >
            {user.feedbackExist
              ? "Редактировать обратную связь"
              : "Оставить обратную связь"}
          </button>
        </>
      )}
    </div>
  );
};

export default React.memo(TeamMember);
