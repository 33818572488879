import "../assets/styles/components/carousel.scss";
import { useState, Children, useEffect, useRef } from "react";

function Carousel({
  className,
  children,
  showNextButton = true,
  showPrevButton = false,
  itemsCountOnSlide = 1,
  autoPlay,
}) {
  const [offset, setOffset] = useState(0);
  const childrenCount = Children.count(children);
  const widthChange =
    itemsCountOnSlide > childrenCount
      ? 100 / childrenCount
      : 100 / itemsCountOnSlide;
  const dotsCount =
    itemsCountOnSlide > childrenCount
      ? 1
      : childrenCount - itemsCountOnSlide + 1;
  const showNavigateElements = childrenCount > itemsCountOnSlide;

  const dots = [];
  for (let i = 0; i < dotsCount; i++) {
    dots.push("");
  }

  const [currentTab, setCurrentTab] = useState(0);

  const prevSlide = () => {
    setCurrentTab((prevTab) => (prevTab !== 0 ? prevTab - 1 : 0));
  };

  const nextSlide = () => {
    setCurrentTab((prevTab) => (prevTab !== dotsCount - 1 ? prevTab + 1 : 0));
  };

  const autoplayIntervalF = useRef();
  useEffect(() => {
    if (autoPlay) {
      autoplayIntervalF.current = setInterval(() => {
        nextSlide();
      }, 3000);
    }

    return () => {
      clearInterval(autoplayIntervalF.current);
    };
  }, [autoPlay]);

  useEffect(() => {
    setOffset(currentTab * widthChange);
  }, [currentTab]);

  return (
    <div className="carousel">
      <div className={className ? `${className} window` : "window"}>
        <div
          className="items_container"
          style={{ transform: `translateX(-${offset}%)` }}
        >
          {Children.map(children, (child, index) => (
            <div
              className="child_item"
              style={{
                maxWidth: `${widthChange}%`,
                minWidth: `${widthChange}%`,
              }}
            >
              {child}
            </div>
          ))}
        </div>
      </div>
      <div
        className={showNavigateElements ? "bottom_block" : "bottom_block hide"}
      >
        {showPrevButton && (
          <button className="move_button" onClick={prevSlide}>
            <span className="i-left_xs"></span>Назад
          </button>
        )}
        <div className="dots">
          {dots.map((_, index) => (
            <span
              key={index}
              tabIndex={index}
              className={offset / widthChange === index ? "active dot" : "dot"}
              onClick={(event) => setCurrentTab(event.target.tabIndex)}
            ></span>
          ))}
        </div>
        {showNextButton && (
          <button className="move_button" onClick={nextSlide}>
            Далее<span className="i-right_xs"></span>
          </button>
        )}
      </div>
    </div>
  );
}

export default Carousel;
