import { useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";

import { usePagination } from "./usePagination";
import { useVariables } from "./useVariables";

import {GET_COMPANIES, GET_COMPANIES_V2} from "../api/endpoints";

export const useCompanyList = () => {
  const navigate = useNavigate();
  const user = useSelector((state) => state.user.user);
  const requestOptions = useVariables().requestOptionsGET;
  const companies = usePagination(
      GET_COMPANIES_V2,
    requestOptions,
    "companyId,desc",
  ).data;

  const redirectCompanyPage = (companyId) => {
    if (user.id === companyId) {
      navigate("/profile");
    } else {
      navigate(`../profile/${companyId}`);
    }
  };

  return {
    companies,
    redirectCompanyPage,
  };
};
