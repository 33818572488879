import React, { useEffect, useState } from "react";
import styled from "styled-components";

import { useVariables } from "@hooks/useVariables";
import getProductStatus, { productStatusMap } from "@utils/getProductStatus";

import ProductImage from "./ProductImage";

import {
  GET_ALL_PRODUCTS,
  GET_PRODUCT_STASUSES,
  UPDATE_COMPANY_STATUS,
  UPDATE_PRODUCT_STATUS,
} from "@api/endpoints";

const ProductsList = () => {
  const requestOptionsGET = useVariables().requestOptionsGET;

  const [productStatuses, setProductStatuses] = useState([]);
  useEffect(() => {
    fetch(GET_PRODUCT_STASUSES, requestOptionsGET)
      .then((response) => response.json())
      .then((response) => {
        console.log("### GET_PRODUCT_STASUSES.response", response);
        setProductStatuses(response);
      });
  }, []);

  const [loading, setLoading] = useState(false);
  const [products, setProducts] = useState([]);
  useEffect(() => {
    setLoading(true);
    fetch(GET_ALL_PRODUCTS, requestOptionsGET)
      .then((response) => {
        console.log("### response", response);
        return response.json();
      })
      .then((json) => {
        console.log("### json", json);
        setProducts(json.content);
      })

      .catch((error) => {
        console.log("### error", error);
      })
      .finally(() => setLoading(false));
  }, []);

  const [selectStatusForProduct, setSelectStatusForProduct] = useState(null);

  const handleProductStatusEdit = (product) => {
    setSelectStatusForProduct(product);
  };

  const constRequestOptionsPOST = useVariables().constRequestOptionsPOST;
  const handleStatusClick = (product, status) => {
    console.log("### handleStatusClick", product, status);

    setSelectStatusForProduct(null);

    fetch(
      UPDATE_PRODUCT_STATUS(product.productId),
      constRequestOptionsPOST({
        status,
      }),
    )
      .then((response) => response.json())
      .then((jsonData) => {
        console.log("### jsonData", jsonData);

        const indexOfProduct = products.findIndex(
          ({ productId }) => productId === product.productId,
        );
        if (typeof indexOfProduct !== "undefined") {
          products[indexOfProduct].productStatus = status;
          setProducts([...products]);
        }
      })
      .catch((error) => console.log("Не удалось обновить статус"));
  };
  return (
    <tbody>
      {products?.length > 0 ? (
        products.map((product) => (
          <tr key={product.productId} className="line">
            <td>
              <ProductImage product={product} />
            </td>
            <td>
              <DisplayName>{product.displayName}</DisplayName>
            </td>
            <td>
              <Description>{product.description}</Description>
            </td>
            <td style={{ position: "relative" }}>
              <StatusButton
                className="status"
                onClick={() => handleProductStatusEdit(product)}
              >
                {getProductStatus(product)?.name}
              </StatusButton>
              {selectStatusForProduct?.productId === product.productId ? (
                <StatusModal>
                  {productStatuses.map((status) => (
                    <Status
                      $active={status === product.productStatus}
                      onClick={() => handleStatusClick(product, status)}
                    >
                      {productStatusMap[status]}
                    </Status>
                  ))}
                </StatusModal>
              ) : null}
            </td>
            <td>{product.company.name}</td>
          </tr>
        ))
      ) : (
        <tr className="line">
          <td colSpan="9" className="empty_list">
            {loading ? "Загрузка..." : "Список пуст..."}
          </td>
        </tr>
      )}
    </tbody>
  );
};

export default React.memo(ProductsList);

const DisplayName = styled.div`
  min-width: 150px;
`;

const Description = styled.div`
  min-width: 200px;
`;

const StatusButton = styled.button`
  text-align: left;
  min-width: 150px;
`;

const StatusModal = styled.div`
  position: absolute;
  top: 5px;
  right: 5px;
  width: 140px;
  height: 100px;

  box-shadow: 0 0 5px black;

  background-color: white;

  border-radius: 5px;

  z-index: 1;

  display: flex;
  flex-direction: column;
  justify-content: space-around;

  padding: 0 5px;
`;

const Status = styled.button`
  background-color: ${({ $active }) => ($active ? "#CCC" : "#FFF")};
  text-align: start;
  padding: 5px;
`;
