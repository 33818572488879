import React from'react';
import {offersLanguages} from "@utils/languageList";
import {useSelector} from "react-redux";
import {useLocation} from "react-router-dom";

const OfferType = ({activeType,setActiveType}) => {
    const offersTypes = useSelector(state => state.offers.offerTypes);
    const location = useLocation();
    return (
        <div className="create-blk">
            <span className="title">Тип предложения</span>
            <ul className="tag tags unstyled">
                {!location.pathname.includes("create-career")&&
                    <li className={activeType===""?"active tabs":"tabs"}
                        onClick={()=>setActiveType("")}>Все</li>
                }

                {offersTypes.map((item,index)=>(
                    <li key={index} className={activeType===item?"active tabs":"tabs"}
                        onClick={()=>setActiveType(item)}>{offersLanguages.type[item]}</li>
                ))}
            </ul>
        </div>
  );


}

export default OfferType;