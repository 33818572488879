import React, {useState} from 'react';
import CustomSelect from "@components/CustomSelect";
import {getDateInInput} from "@utils/other";

const CreateModal = ({closeModal,edit=false}) => {
    const [name,setName] = useState();
    const [count,setCount] = useState();
    const [errors,setErrors] = useState();
    const getError = (field,text=false) =>{
        return errors && errors.hasOwnProperty(field)  &&  errors[field] ?  (text ?
            <label htmlFor="" className="error">{errors[field]}</label>
            :"form-error") : ""
    }
    const focusInput  =  field  =>  {
        let errs = {...errors}
        if(errs[field])
            delete errs[field];
        setErrors(errs);
    }
    const save = () =>{
        if(!name){
            setErrors({...errors,name:"Введите название"});
            return;
        }
        if(isNaN(parseInt(count))){
            setErrors({...errors,count:"Введите количество"});
            return;
        }
    };
    return (
        <div className="newStyle">
            <div className={"flex flex-col gap-15"}>
                <div className={`search-blk create-blk ${getError("name")}`}>
                    <label htmlFor="Name2">Название достижения</label>
                    <input
                        onFocus={()=>focusInput("name")}
                        value={name}
                        onChange={(e)=>setName(e.target.value)}
                        className="form_field"
                        type="text  "
                        placeholder="Введите название"
                    />
                    {getError("name",true)}
                </div>
                <div className={`search-blk create-blk ${getError("count")}`}>
                    <label htmlFor="Name2">Количество</label>
                    <input
                        onFocus={()=>focusInput("count")}
                        value={count}
                        onChange={(e)=>setCount(e.target.value)}
                        className="form_field"
                        type="text"
                        placeholder="Введите количество"
                    />
                    {getError("count",true)}
                </div>

                <div className="table-btn student-btn btn-all">
                    <button onClick={closeModal} className="btn btn-grey">Отменить</button>
                    <button onClick={save} type="submit" className="btn btn-blue">
                        {edit ? "Сохранить" : "Добавить"}
                    </button>
                </div>

            </div>
        </div>
    )
}
export default CreateModal;