const initialState = {
    institutes: [],
};

const LOAD_INSTITUTES = "LOAD_INSTITUTES";
const ADD_INSTITUTE = "ADD_INSTITUTE";
const REMOVE_INSTITUTE = "REMOVE_INSTITUTE";
const EDIT_INSTITUTE = "EDIT_INSTITUTE";

export const instituteReducer = (state = initialState, action) => {
    switch (action.type) {
        case LOAD_INSTITUTES:
            return {...state, institutes: [...state.institutes, ...action.payload]}
        case ADD_INSTITUTE:
            return {...state, institutes: [...state.institutes, action.payload]}
        case REMOVE_INSTITUTE:
            return {...state, institutes: state.institutes.filter(institute => institute.id !== action.payload)}
        case EDIT_INSTITUTE:
            return {...state, institutes: state.institutes}
        default:
            return state
    }
}

export const loadInstitutesAction = (payload) => ({type: LOAD_INSTITUTES, payload});
export const addInstituteAction = (payload) => ({type: ADD_INSTITUTE, payload});
export const removeInstituteAction = (payload) => ({type: REMOVE_INSTITUTE, payload});
export const editInstituteAction = (payload) => ({type: EDIT_INSTITUTE, payload});
