import React from "react";

import FilterSelect from "./FilterSelect";

import { usePagination } from "@hooks/usePagination";
import { useVariables } from "@hooks/useVariables";

import { GET_PROFESSIONS } from "@api/endpoints";

const SCROLL_ELEMENT_ID = "professions_list";

const ProfessionsFilterSelect = ({ filters, onFilterSelect }) => {
  const requestOptionsPOSTWithoutParams = useVariables().requestOptionsPOST;
  const { data: professions, loading } = usePagination(
    GET_PROFESSIONS,
    requestOptionsPOSTWithoutParams,
    "name",
    SCROLL_ELEMENT_ID,
  );

  return (
    <FilterSelect
      values={professions}
      loading={loading}
      placeholder="Профессии"
      filterName="professionIds"
      filters={filters}
      scrollId={SCROLL_ELEMENT_ID}
      onFilterSelect={onFilterSelect}
    />
  );
};

export default React.memo(ProfessionsFilterSelect);
