import React from "react";

import { useDownloadFile } from "@hooks/useDownloadFile";
import { DOWNLOAD_FILE } from "@api/endpoints";
import CompanyActivities from "@pages/ProfilePage/components/ProfileCompany/components/CompanyActivities";

const CompanyListItem = ({ company, onClick }) => {
  const img = useDownloadFile(DOWNLOAD_FILE, company.avatarId).file;

  return (
      <div  className="col-block">
          <div className="list-company-blk blks">
              <img onClick={() => onClick(company.id)} src={img} className={"avatar"} alt=""/>
                  <div className="list-company-content">
                      <span onClick={() => onClick(company.id)} src={img}>{company.activity}</span>
                      <p onClick={() => onClick(company.id)} src={img}>{company.longName}</p>
                      {company.activities && company.activities.length > 0 &&
                         <CompanyActivities activities={company.activities}/>
                      }

                  </div>
          </div>

      </div>

  );
};

export default React.memo(CompanyListItem);
