import React from 'react';


const FiltersSpacializations = ({filters, specs, checkIfActive,removeFilter, selectFilter}) => {
    const getSelectedSpecs = () => {
        if (filters && filters["specializationId"]) {
            return specs.filter(city => filters["specializationId"].includes(city.specializationId));
        }
        return []
    }
    const getNonSelectedSpec = () => {
        if (filters && filters["specializationId"])
            return specs.filter(city => !filters["specializationId"].includes(city.specializationId));
        else return specs;
    }
    return (
        <>
            <ul className="tag active tag-choice unstyled">
                {getSelectedSpecs().map((item) => (
                    <li
                        className={`${checkIfActive("specializationId",specs.specializationId)} tab`}>
                        {item.specializationName}
                        <button className="btn-close"
                                data-type={"specializationId"}
                                data-arr={"true"}
                                data-value={item.specializationId}
                                onClick={selectFilter}
                        ></button>
                    </li>
                ))}
            </ul>

            <ul className="tabs tag tag-active unstyled">
                {specs && getNonSelectedSpec().map((item) => (
                    <li
                    data-type={"specializationId"}
                    data-arr={"true"}
                    data-value={item.specializationId}
                    onClick={selectFilter}
                    className={`${checkIfActive("specializationId",item.specializationId)} tab`}>
                        {item.specializationName}
                    </li>
                    ))}
            </ul>
        </>
    )
}

export default React.memo(FiltersSpacializations);