const initialState = {
    qualifications: []
};

const LOAD_QUALIFICATIONS = "LOAD_QUALIFICATIONS";
const ADD_QUALIFICATION = "ADD_QUALIFICATION";
const REMOVE_QUALIFICATION = "REMOVE_QUALIFICATION";
const EDIT_QUALIFICATION = "EDIT_QUALIFICATION";

export const qualificationReducer = (state = initialState, action) => {
    switch (action.type) {
        case LOAD_QUALIFICATIONS:
            return {...state, qualifications: action.payload}
        case ADD_QUALIFICATION:
            return {...state, qualifications: [...state.qualifications, action.payload]}
        case REMOVE_QUALIFICATION:
            return {...state, qualifications: state.qualifications.filter(qualification => qualification.id !== action.payload)}
        case EDIT_QUALIFICATION:
            // todo
            return {...state, qualifications: state.qualifications}
        default:
            return state
    }
}

export const loadQualificationsAction = (payload) => ({type: LOAD_QUALIFICATIONS, payload});
export const addQualificationAction = (payload) => ({type: ADD_QUALIFICATION, payload});
export const removeQualificationAction = (payload) => ({type: REMOVE_QUALIFICATION, payload});
export const editQualificationAction = (payload) => ({type: EDIT_QUALIFICATION, payload});
