const initialState = {
  professions: [],
};

const LOAD_PROFESSIONS = "LOAD_PROFESSIONS";
const ADD_PROFESSION = "ADD_PROFESSION";
const REMOVE_PROFESSION = "REMOVE_PROFESSION";
const UPDATE_PROFESSION = "UPDATE_PROFESSION";

export const professionReducer = (state = initialState, action) => {
  switch (action.type) {
    case LOAD_PROFESSIONS:
      return { ...state, professions: action.payload };
    case ADD_PROFESSION:
      return { ...state, professions: [...state.professions, action.payload] };
    case REMOVE_PROFESSION:
      return {
        ...state,
        professions: state.professions.filter(
          (profession) => profession.id !== action.payload,
        ),
      };
    case UPDATE_PROFESSION:
      return { ...state, professions: action.payload };
    default:
      return state;
  }
};

export const loadProfessionsAction = (payload) => ({
  type: LOAD_PROFESSIONS,
  payload,
});
export const addProfessionAction = (payload) => ({
  type: ADD_PROFESSION,
  payload,
});
export const removeProfessionAction = (payload) => ({
  type: REMOVE_PROFESSION,
  payload,
});
export const updateProfessionAction = (payload) => ({
  type: UPDATE_PROFESSION,
  payload,
});
