import "../assets/styles/components/search.scss";
import React, { useState } from "react";

import CustomSelect from "./CustomSelect";
import Collapse from "@pages/AdminCareersPage/components/Collapse";

function Search({ fields, onFiltersChange }) {
  const [collapseSearch, setCollapseSearch] = useState(false);

  function filterHandler(event) {
    event.preventDefault();
    const form = new FormData(event.target);
    const searchParam = {};
    for (const [key, value] of form) {
      if (value.trim()) {
        try {
          searchParam[key] = JSON.parse(value);
        } catch (ex) {
          searchParam[key] = value.trim().toLowerCase();
        }
      }
    }
    onFiltersChange(searchParam);

    // const filter = list.filter((company) => {
    //   // companyName, companyLongName, inn, bik, ogrn
    //   // isAll
    //
    //   // for (const key in searchParam) {
    //   //     //TODO work with nested objects
    //   //     if (!company[key].toLowerCase().includes(searchParam[key].toLowerCase())) {
    //   //         return false;
    //   //     }
    //   // }
    //   return true;
    // });
    // console.log(searchParam);
    // setFilteredList(filter);
  }

  function resetFilter() {
    // setFilteredList(list);
    onFiltersChange({name:"",inn:""});
  }

  function collapseSearchHandler() {
    setCollapseSearch((prevState) => !collapseSearch);
  }

  return (
      <Collapse
          header={"Поиск"}
      >
        <div style={{width:  "100%"}}>

        <form onSubmit={filterHandler} method="">
          <div className="flex gap-15">
            <div className="search-blk create-blk">
              <label htmlFor="Name">Название организации</label>
              <input type="text" placeholder="ivanov.ii" name="name" id="Name"/>
            </div>
            <div className="search-blk create-blk">
              <label htmlFor="Inn">ИНН</label>
              <input type="number" style={{width: "100%",textAlign:'left'}} placeholder="7743013902" id="Inn" name={"inn"}/>
            </div>
          </div>
          <div className="search-btn">
            <button onClick={resetFilter} type="reset" className="btn btn-reset">Сбросить</button>
            <button type="submit" className="btn btn-blue">Применить</button>
          </div>
        </form>
        </div>
      </Collapse>
  );
}

export default Search;
