import React, { useState } from "react";

import { useVariables } from "@hooks/useVariables";
import { usePagination } from "@hooks/usePagination";

import IncomingRequest from "./IncomingRequest";

import { GET_INVITES, GET_KNOCKS } from "@api/endpoints";

const IncomingRequests = ({
  isStudent,
  isCompany,
  redirectToProfile,
  setTeamChanges,
}) => {
  const applicationUrl = isCompany ? GET_KNOCKS : isStudent ? GET_INVITES : "";
  const [applicationsChanges, setApplicationsChanges] = useState(null);
  const requestOptionsApplications =
    useVariables(applicationsChanges).requestOptionsGET;

  const applications = usePagination(
    applicationUrl,
    requestOptionsApplications,
  ).data;

  return (
    <div className="applications">
      {applications.length ? (
        applications.map((application) => (
          <IncomingRequest
            key={application.id}
            application={application}
            isStudent={isStudent}
            isCompany={isCompany}
            redirectToProfile={redirectToProfile}
            setApplicationsChanges={setApplicationsChanges}
            setTeamChanges={setTeamChanges}
          />
        ))
      ) : (
        <div className="empty">Здесь пока пусто</div>
      )}
    </div>
  );
};

export default React.memo(IncomingRequests);
