import React from "react";
import styled from "styled-components";

import { useCreateCompanyModalStore } from "@pages/CompanyFromAdminPage/components/CompanyCreateEditModal";
import {NavLink} from "react-router-dom";

const CompaniesListHeader = () => {
  const { show } = useCreateCompanyModalStore();

  const handleAddClick = () => {
    show();
  };

  return (
      <thead>
      <tr>
        <th className="add">
          <NavLink  className="btn btn-blue modal-link" to={"/admin/org-create"}>

          </NavLink>
        </th>
        <th>Название организации</th>
        <th>Контактное лицо <span>(ФИО, телефон, email)</span></th>
        <th>ИНН</th>
        <th>Подписка</th>
        <th>Статус</th>
      </tr>
      </thead>
  );
};

export default React.memo(CompaniesListHeader);

const ContactPerson = styled.div`
  width: 140px;
  display: flex;
  justify-content: center;
  font-weight: bold;
`;

const PersonFields = styled(ContactPerson)`
  justify-content: space-between;
  margin-top: 5px;
`;
