import React from "react";
import {Link, NavLink} from "react-router-dom";

import {useDownloadFile} from "@hooks/useDownloadFile";

import vacancy_default from "@assets/images/vacancy_default.svg";
import internship_default from "@assets/images/internship_default.svg";

import {DOWNLOAD_FILE} from "@api/endpoints";
import {offersLanguages} from "@utils/languageList";

const VacancyListItem = ({
                             edit = true, vacancy, onSelect = () => {
    }
                         }) => {
    const defaultImg =
        vacancy.type === "VACANCY" ? vacancy_default : internship_default;
    const img = useDownloadFile(
        DOWNLOAD_FILE,
        vacancy.vacancyId,
        defaultImg,
    ).file;

    const getVacancyStatus = (vacancy) => {
        switch (vacancy.type) {
            case "VACANCY":
                return {
                    name: "Вакансия",
                };
            case "INTERNSHIP":
                return {
                    name: "Стажировка",
                };
            default:
                return {
                    name: "-",
                };
        }
    };

    const handleClick = () => {
        onSelect({img, name: vacancy.name, description: vacancy.description});
    };

    return (
        <li className="list_item">
            <NavLink className={"list_item"}
                     state={{previousLocationPathname: "/profile"}}
                     to={edit ?
                         (vacancy.offerStatus === "ACTUAL" ?
                         `/profile/edit-career/${vacancy.offerId}#1` :
                         `/profile/edit-career/${vacancy.offerId}`)
                         : `/profile/view-career/${vacancy.offerId}`}>
                <div className="list_info newStyle">
                    <div className="vacancy-info ">
                        <div className="vacancy-name">
                            <div className="create-blk">
                                <span className="name vacancy-name-list">
                                        {vacancy.name}
                                </span>
                                <div className="create-blk">
                                    <div className="create-status">

                                    <span
                                        className={`status ${vacancy.offerStatus === "ACTUAL" ? "type-green" : ""}`}>
                                        {offersLanguages.status[vacancy.offerStatus]}
                                    </span>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className={"td-tag"}>
                            <span className=" ">{offersLanguages.type[vacancy?.offerType]}</span>

                        </div>
                    </div>
                    <p className="description">{vacancy.description}</p>
                </div>
            </NavLink>
        </li>
    );
};

export default React.memo(VacancyListItem);
