const initialState = {
    eduPrograms: []
};

const LOAD_PROGRAMS = "LOAD_PROGRAMS";
const ADD_PROGRAM = "ADD_PROGRAM";
const REMOVE_PROGRAM = "REMOVE_PROGRAM";
const EDIT_PROGRAM = "EDIT_PROGRAM";

export const eduProgramReducer = (state = initialState, action) => {
    switch (action.type) {
        case LOAD_PROGRAMS:
            return {...state, eduPrograms: action.payload}
        case ADD_PROGRAM:
            return {...state, eduPrograms: [...state.eduPrograms, action.payload]}
        case REMOVE_PROGRAM:
            return {...state, eduPrograms: state.eduPrograms.filter(program => program.id !== action.payload)}
        case EDIT_PROGRAM:
            // todo
            return {...state, eduPrograms: state.eduPrograms}
        default:
            return state
    }
}

export const loadEduProgramsAction = (payload) => ({type: LOAD_PROGRAMS, payload});
export const addEduProgramAction = (payload) => ({type: ADD_PROGRAM, payload});
export const removeEduProgramAction = (payload) => ({type: REMOVE_PROGRAM, payload});
export const editEduProgramAction = (payload) => ({type: EDIT_PROGRAM, payload});
