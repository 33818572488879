import "@assets/styles/pages/shop.scss";

import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { toast } from "react-toastify";
import { useNavigate } from "react-router-dom";

import { useShop } from "@hooks/useShop";
import { useVariables } from "@hooks/useVariables";

import SearchLine from "@components/SearchLine";
import ModalCustom from "@components/ModalCustom";

import AddProduct from "./components/AddProduct";
import ProductListItem from "./components/ProductListItem";
import ProductItem from "./components/ProductItem";
import FiltersBlock from "./components/FiltersBlock";

import { DELETE_PRODUCT } from "@api/endpoints";

const ShopPage = () => {
  const [productItemModal, setProductItemModal] = useState(false);
  const [productItemModalContent, setProductItemModalContent] = useState({});
  const [addProductModal, setAddProductModal] = useState(false);
  const [collapseFilter, setCollapseFilter] = useState(true);
  const [selectedFilter, setSelectedFilter] = useState();
  console.log("### selectedFilter", selectedFilter);

  const user = useSelector((state) => state.user.user);
  const isCompany = user.roles.includes("COMPANY");

  const [searchValue, setSearchValue] = useState();

  const { products, refresh } = useShop({
    productStatus: selectedFilter,
    name: searchValue,
  });

  const navigate = useNavigate();
  const openProductItemModal = (product, img) => {
    // setProductItemModal(true);
    // setProductItemModalContent({ ...product, img });
    navigate(`/profile/shop/${product.productId}`);
  };

  const handleAddProductSuccess = () => {
    refresh();
    toast.success("Ваш товар успешно отправлен на модерацию", {
      position: toast.POSITION.TOP_CENTER,
    });
  };

  const requestOptionsDELETE = useVariables().requestOptionsDELETE;
  const handleProductDelete = (productId) => {
    console.log("### handleProductDelete.productId", productId);

    fetch(DELETE_PRODUCT(productId), requestOptionsDELETE)
      .then((response) => {
        console.log("### response", response);
        if (response.status !== 200) {
          throw response;
        }
        return response.json();
      })
      .then((response) => {
        console.log("### response", response);
        refresh();
        toast.success("Товар удалён", {
          position: toast.POSITION.TOP_CENTER,
        });

        return response;
      })
      .catch((error) => {
        console.log("### error", error?.message);
        toast.error("Ошибка удаления товара", {
          position: toast.POSITION.TOP_CENTER,
        });
      });
  };

  return (
    <div className="shop filtered_page page">
      <h1>Магазин</h1>
      <div className="shop_block filtered_page_block">
        <div className="shop_content filtered_page_content">
          <SearchLine
            placeholder="Поиск товара"
            value={searchValue}
            onChangeValue={setSearchValue}
          />
          <div className="buttons_block">
            {isCompany && (
              <button
                className="i-add-circle bubble dark_gray_button secondary_button"
                onClick={() => setAddProductModal(true)}
              >
                Добавить товар
              </button>
            )}
            <button
              className="i-filter filter_button"
              onClick={() => setCollapseFilter(false)}
            />
          </div>
          <div className={isCompany ? "shop_list" : "shop_list for_student"}>
            {products.length > 0 ? (
              products.map((product) => (
                <ProductListItem
                  key={product.productId}
                  product={product}
                  openProductItemModal={openProductItemModal}
                  onDelete={handleProductDelete}
                />
              ))
            ) : (
              <div className="empty">Список товаров пока пуст</div>
            )}
          </div>
        </div>
        {/*{isCompany ? (*/}
        <FiltersBlock
          opened={!collapseFilter}
          onClose={() => setCollapseFilter(true)}
          onFilterChange={setSelectedFilter}
        />
        {/*) : null}*/}
      </div>

      <ModalCustom
        title={productItemModalContent.displayName}
        active={productItemModal}
        setActive={setProductItemModal}
      >
        <ProductItem product={productItemModalContent} />
      </ModalCustom>
      {isCompany && (
        <ModalCustom
          title="Добавление товара"
          active={addProductModal}
          setActive={setAddProductModal}
        >
          <AddProduct
            setModalActive={setAddProductModal}
            onAddProduct={handleAddProductSuccess}
          />
        </ModalCustom>
      )}
    </div>
  );
};

export default ShopPage;
