import React from'react';
import {NavLink} from "react-router-dom";


const Table = ({id,children,createFromOrg=false,orgId}) => {
    return (
        <table id={id} className="table table-profile">
            <thead>
            <tr>
                <th className="add">
                    <NavLink to={createFromOrg ? `/admin/create-career/?orgId=${orgId}`:"/admin/create-career"} end>
                        <span></span>
                    </NavLink>
                </th>
                <th>Название</th>
                <th>Статус</th>
                <th>Просмотры</th>
                <th>Отклики</th>
                <th style={{textAlign:'center'}}>Отклики</th>
            </tr>
            </thead>
            <tbody>
                {children}
            </tbody>
        </table>
    )

}
export default React.memo(Table);